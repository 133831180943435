import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { BrandingInfo } from 'src/app/_models/brandingInfo';
import { HomeService } from 'src/app/_services/home.service';

@Component({
  selector: 'app-footer-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  footer: any;
  branding: any;
  isFooterVisible = true;
  brandInfo: BrandingInfo;
brandInfoSub: Subscription;

  constructor(private home: HomeService,private appConfig: AppConfigService,
    private store: Store<{
      brandInfo: BrandingInfo
    }>) { }




  ngOnInit() {

    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {

      if(brandInfo){
        this.brandInfo = brandInfo;
        this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
          next: (result) => {
            this.footer = result;
          },
          error: (error) => {
            this.isFooterVisible = false;
          }
        });

      }


    });



    this.appConfig.getSettings().subscribe({
      next: (result) => {
        this.branding = result;
      }, error: (err) => {
        this.isFooterVisible = false;
      }
    });
  }

}
