import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "apollo-client/util/Observable";
import { CollapseComponent } from "ng-uikit-pro-standard";
import { storePrismicPDF } from "src/app/_actions/pdf.actions";
import { savePlan } from "src/app/_actions/plan.actions";
import { PrismicPDF } from "src/app/_models/pdf";
import { Plan } from "src/app/_models/plan";
import { RatesResponse } from "src/app/_models/rates";
import { ProductTabsetService } from "src/app/_services/product-tabset.service";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeFr from "@angular/common/locales/fr";
import { BrandingInfo } from "src/app/_models/brandingInfo";

@Component({
  selector: "app-plan-select",
  templateUrl: "./plan-select.component.html",
  styleUrls: ["./plan-select.component.scss"],
})
export class PlanSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() plans: any;
  @Input() _deductible: string;
  @Output() chosenPlan = new EventEmitter<any>();
  @Output() changeTerms = new EventEmitter<boolean>();
  slices: any[];
  priceHeader: string;
  coveredParts: [];
  coverageBoxes: any[];
  coverageHeader: string;
  coverageText: string;
  protectionText: string;
  faqTitle: string;
  faq: any[];
  paymentOptions: boolean;
  ratesSubscription: Subscription;
  multipleDeductibles = false;
  deductibles: any[];
  currentDeductible: number;
  disappearingDeductibleValue = false;
  disappearingDeductibleDisclaimer = false;
  noFee = "no-fee";
  preferredLanguage: string;

  @ViewChildren("currentDeductibleMobilePanel") currentDeductibleMobilePanel: QueryList<CollapseComponent>;
  @ViewChildren("currentDeductiblePanel") currentDeductiblePanel: QueryList<CollapseComponent>;
  @ViewChildren("disappearingDeductible") disappearingDeductible: QueryList<CollapseComponent>;
  @ViewChildren("disappearingDeductibleMobile") disappearingDeductibleMobile: QueryList<CollapseComponent>;
  frontLoadTaxes: boolean;
  brand: string;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;
  canSelect = true;

  constructor(
    private el: ElementRef,
    public store: Store<{
      rates: RatesResponse;
      brandInfo: BrandingInfo;
    }>,
    private productTabSetService: ProductTabsetService
  ) {}

  ngOnInit(): void {
    this.deductibles = [];

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;

          if (this.brandInfo.multiDeductible) {
            console.log("this.brandInfo.multiDeductible: ", this.brandInfo);
            this.setDeductible();
          }

          if (this.brandInfo.brand === "Buick" || this.brandInfo.brand === "Chevrolet" || this.brandInfo.brand === "GMC" || this.brandInfo.brand === "Cadillac" || this.brandInfo.brand === "Schomp") {
            this.multipleDeductibles = true;
            this.brand = this.brandInfo.brand;
            console.log("getProperPricing console.log: ", this.brand);
            this.getProperPricing(this.brandInfo);
          }

          this.frontLoadTaxes = this.brandInfo.frontLoadTaxes;
          if (this.brandInfo.brand === "Buick" || this.brandInfo.brand === "Chevrolet" || this.brandInfo.brand === "GMC" || this.brandInfo.brand === "Cadillac") {
            this.noFee = "";
          }

          this.ratesSubscription = this.store
            .select((store) => store.rates)
            .subscribe((rates) => {
              if (rates.data.paymentOptions && this.brandInfo.isPaymentOptions) {
                this.paymentOptions = true;
              } else {
                this.paymentOptions = false;
              }
            });

          const program = JSON.parse(sessionStorage.getItem("presetProgram"));
          if (program && program.deductible) {
            this.deductibles.forEach((deductible) => {
              if (deductible === Number(program.deductible)) {
                this.chooseDeductible(program.deductible, brandInfo);
              }
            });
          }

          this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language;
          registerLocaleData(localeFr, "fr");
          registerLocaleData(localeEn, "en-ca");
          registerLocaleData(localeEn, "en-us");

          if (this.preferredLanguage.indexOf("fr") > -1) {
            this.noFee = "pas de frais";
          }

          console.log("Current Plans - Deductibles", this.plans);
        }
      });

    // this.currentDeductible = this.deductibles[this.deductibles.length - 1];

    // this.setPlan(0);
  }

  ngAfterViewInit() {
    this.resizeTabs();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resizeTabs();
  }

  setDeductible() {
    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active === true) {
        for (let j = 0; j < this.plans[i].planTerms[0].deductible.length; j++) {
          this.deductibles.push(this.plans[i].planTerms[0].deductible[j].amount);
        }
        this.deductibles = this.deductibles.sort((a, b) => {
          return a - b;
        });
        this.deductibles = [...new Set(this.deductibles)];
        this.currentDeductible = this.deductibles[0];
        return;
      }
    }
  }

  resizeTabs() {
    let planDivs = this.el.nativeElement.querySelectorAll(".plan-select");
    let height = 0;
    planDivs.forEach((element) => {
      height = element.firstChild.clientHeight > height ? element.firstChild.clientHeight : height;
    });
    planDivs.forEach((element) => {
      if (element.classList.contains("active")) {
        element.style.height = height + 25 + "px";
      } else {
        element.style.height = height + 20 + "px";
      }
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    //console.log('Are you seeing me: ', simpleChanges.plans.isFirstChange)
    if (simpleChanges.plans && simpleChanges.plans.isFirstChange) {
      const program = JSON.parse(sessionStorage.getItem("presetProgram"));
      let hasPresetValue = null;
      if (program && program.deductible && this.deductibles) {
        this.deductibles.forEach((deductible) => {
          if (deductible === Number(program.deductible)) {
            hasPresetValue = program.deductible;
          }
        });
      }
      if (hasPresetValue) {
        this.currentDeductible = hasPresetValue;
      } else {
        this.currentDeductible = Number(this._deductible);
      }
    } else {
      this.currentDeductible = Number(this._deductible);
    }

    // if (this.currentDeductible === 100) {
    //   this.disappearingDeductibleDisclaimer = true;
    // } else {
    //   this.disappearingDeductibleDisclaimer = false;
    // }

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;
          this.currentDeductible = Number(this._deductible);

          this.chooseDeductible(this.currentDeductible, brandInfo);
        }
      });
  }

  ngOnDestroy() {
    if (this.ratesSubscription) {
      this.ratesSubscription.unsubscribe();
    }
  }

  toggleDisappearing() {
    console.log("toggleDisappearing: ", this.brandInfo);
    this.disappearingDeductibleValue = !this.disappearingDeductibleValue;
    this.getProperPricing(this.brandInfo);
  }

  getProperPricing(brandInfo) {
    if (brandInfo.multiDeductible) {
      let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].active === true) {
          console.log("Current Active Plan", this.plans[i]);
          console.log("Chosen deductible", this.currentDeductible);
          this.plans[i].plan.deductible = this.currentDeductible;
          const info = this.plans[i].planTerms[this.plans[i].term.termIndex].deductible.filter((item) => {
            return item.amount === this.currentDeductible && item.disappearing === this.disappearingDeductibleValue;
          });

          if (info.length > 0) {
            this.plans[i].term.disappearingDeductibleSelected = info[0].disappearing;
            this.plans[i].term.contractDealerCost = info[0].price;
            this.plans[i].term.productPlanSkuId = info[0].sku;

            let surcharges = 0;
            if (this.plans[i].term.commercialUseSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge("Light Duty");
            }

            if (this.plans[i].term.liftKitSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge("Lift Kit");
            }

            if (this.plans[i].term.greyMarketSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge("Grey Market");
            }

            if (this.plans[i].term.snowPlowSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge("Plow");
            }

            if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
              this.plans[i].term.totalPrice = info[0].price;
            } else {
              this.plans[i].term.totalPrice = Number(info[0].price) + surcharges;
            }

            if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
              this.plans[i].term.totalPrice = info[0].price;
            } else {
              this.plans[i].term.totalPrice = Number(info[0].price) + surcharges;
            }

            this.plans[i].term.price = (Number(this.plans[i].term.totalPrice) * 0.1).toFixed(2).toString();

            if (brandInfo.frontLoadTaxes) {
              this.plans[i].term.payment = (Number(this.plans[i].term.totalPrice) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString();
            } else {
              this.plans[i].term.payment = (Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString();
            }

            setTimeout(() => {
              this.chosenPlan.emit(this.plans[i]);
              this.canSelect = true;
            });

            return;
          }
        }
      }
    } else {
      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].active === true) {
          // this.plans[i].plan.deductible = this.currentDeductible
          const info = this.plans[i].planTerms[this.plans[i].term.termIndex]

          this.plans[i].term.contractDealerCost = info.price;
          this.plans[i].term.productPlanSkuId = info.productPlanSkuId;

          let surcharges = 0;
          if (this.plans[i].term.commercialUseSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge("Light Duty");
          }

          if (this.plans[i].term.liftKitSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge("Lift Kit");
          }

          if (this.plans[i].term.greyMarketSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge("Grey Market");
          }

          if (this.plans[i].term.snowPlowSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge("Plow");
          }

          if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
            this.plans[i].term.totalPrice = info.price;
          } else {
            this.plans[i].term.totalPrice = Number(info.price) + surcharges;
          }

          if (this.plans[i].term.liftKitSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge("Lift Kit");
          }

          this.plans[i].term.price = (Number(this.plans[i].term.totalPrice) * 0.1).toFixed(2).toString();

          if (brandInfo.frontLoadTaxes) {
            this.plans[i].term.payment = (Number(this.plans[i].term.totalPrice) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString();
          } else {
            this.plans[i].term.payment = (Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString();
          }
          setTimeout(() => {
            this.chosenPlan.emit(this.plans[i]);
            this.canSelect = true;
          });

          return;
        }
      }
    }
  }

  chooseDeductible(deductible, brandInfo) {
    this.disappearingDeductibleValue = false;
    if (deductible == 100 && this.currentDeductiblePanel) {
      this.currentDeductiblePanel.forEach((panel) => {
        panel.show();
      });
      this.currentDeductibleMobilePanel.forEach((panel) => {
        panel.show();
      });
    } else if (this.currentDeductiblePanel) {
      this.currentDeductiblePanel.forEach((panel) => {
        panel.hide();
      });
      this.currentDeductibleMobilePanel.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductibleDisclaimer = false;
    }
    this.currentDeductible = deductible;

    this.getProperPricing(brandInfo);
  }

  toggleDisclaimer() {
    this.disappearingDeductibleDisclaimer = !this.disappearingDeductibleDisclaimer;
    if (this.disappearingDeductibleDisclaimer) {
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.show();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.show();
      });
    } else {
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.hide();
      });
    }
  }

  setPlan(which) {
    const thisWhich = which;
    if (this.canSelect == true) {
      this.canSelect = false;
      this.plans.forEach((plan, index) => {
        if (index === thisWhich) {
          plan.active = true;
        } else {
          plan.active = false;
        }
        setTimeout(() => {
          this.resizeTabs();
        });
      });

      let prismicPDF: PrismicPDF = {
        pdf: this.plans[which].samplePDF.url,
      };
      setTimeout(() => {
        this.resizeTabs();
      });

      this.store.dispatch(storePrismicPDF(prismicPDF));

      this.chosenPlan.emit(this.plans[which]);
      this.getProperPricing(this.brandInfo);

      // this.changeTerms.emit(true)
    }
  }
}
