import { BrowserWarningComponent } from './browser-warning/browser-warning.component';
import { LegalNoticeComponent } from './legal/legal-notice/legal-notice.component';
import { AccessibilityComponent } from './legal/accessibility/accessibility.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { SearchComponent } from './search/search.component';
import { CartGuard } from './_guards/cart.guard';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ConfirmationGuard } from './_guards/confirmation.guard';
import { MyAccountVerifyComponent } from './my-account/my-account-verify/my-account-verify.component';
import { PreferenceComponent } from './preference/preference.component';
import { ContentBlockComponent } from './slices/content-block/content-block.component';
import { SlicePreviewComponent } from './slices/slice-preview/slice-preview.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ProductHomeComponent } from './product-home/product-home.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  preloadingStrategy: QuicklinkStrategy
};

const routes: Routes = [
  {
    path: '',
    // canActivate: [LeadGuard],

    children: [
      // {
      //   path: '',
      //   redirectTo: `/${brandInfo.homePage}`,
      //   pathMatch: 'full',
      // },
      {
        path: '',
        component: ProductHomeComponent,
        data: { title: "", animation: "fade", pageType: "" },
      },
      {
        path: 'login/callback',
        component: OktaCallbackComponent
      },
      {
        path: 'page',
        loadChildren: () => import('./product-home/product-home.module').then(m => m.ProductHomeModule)
      },
      {
        path: 'content-block/:uid',
        component: SlicePreviewComponent,
      },
      {
        path: 'slot/countdown',
        component: CountdownComponent,
      },
      {
        path: 'how-vsp-works',
        loadChildren: () => import('./how/how.module').then(m => m.HowModule)
      },
      {
        path: 'verify-vehicle',
        loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule)
      },
      {
        path: 'my-account',
        loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule),
        //canActivate: [OktaAuthGuard]
      },
      {
        path: 'help-center',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'loading',
        loadChildren: () => import('./loading/loading.module').then(m => m.LoadingModule)
      },
      {
        path: 'customize-plan',
        loadChildren: () => import('./customize/customize.module').then(m => m.CustomizeModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule)
      },
      {
        path: 'search',
        component: SearchComponent,
        data: { title: 'Search', animation: 'fade' },
      },
      {
        path: 'preference-center',
        loadChildren: () => import('./preference/preference.module').then(m => m.PreferenceModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
      },
      {
        path: 'confirmation',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
      },
      {
        path: 'ui-sandbox',
        loadChildren: () => import('./ui-sandbox/ui-sandbox.module').then(m => m.UiSandboxModule)
      },
      {
        path: 'cms-sandbox',
        loadChildren: () => import('./cms-sandbox/cms-sandbox.module').then(m => m.CmsSandboxModule)
      },
      {
        path: 'srvc-sandbox',
        loadChildren: () => import('./srvc-sandbox/srvc-sandbox.module').then(m => m.SrvcSandboxModule)
      },
      {
        path: 'preview',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
      },
      // {
      //   path: 'emailPreferences',
      //   loadChildren: './email-preferences/email-preferences.module#EmailPreferencesModule'
      // },

    ],
  },
  {
    path: 'verify-account',
    component: MyAccountVerifyComponent,
    data: { title: 'Verify Account', animation: 'fade' },
  },
  {
    path: 'browser-warning',
    loadChildren: () => import('./browser-warning/browser-warning.module').then(m => m.BrowserWarningModule)
  },
  {
    path: ':parenturl/:pageurl',
    component: ProductHomeComponent,
    data: { title: "", animation: "fade", pageType: "" },
  },
  {
    path: ':pageurl',
    component: ProductHomeComponent,
    data: { title: "", animation: "fade", pageType: "" },
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [QuicklinkModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [],
})
export class  AppRoutingModule {}
