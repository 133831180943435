import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ModalComponent } from "src/app/modal/modal.component";
import {
  showInlineLoader,
  hideInlineLoader,
} from "src/app/_actions/inline-loader.actions";
import { BrandingInfo } from "src/app/_models/brandingInfo";
import { HomeService } from "src/app/_services/home.service";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-slice-preview",
  templateUrl: "./slice-preview.component.html",
  styleUrls: ["./slice-preview.component.scss"],
})
export class SlicePreviewComponent implements OnInit {
  uid: string;
  slice: any;
  brand: string;
  vehicleObject: any = {
    year: "2019",
    model: {name: "E-tron"},
    make: {name: "Audi"},
    trim: null,
    vin: "WA111111111111111",
  };


  modalRef: MDBModalRef;

  constructor(
    private home: HomeService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private store: Store<{
      brandInfo: BrandingInfo
    }>
  ) {}



  ngOnInit() {

    this.uid = this.route.snapshot.paramMap.get("uid");


    this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brand = brandInfo.brand;
    });

    this.store.dispatch(showInlineLoader());


    if (this.route.snapshot.queryParams.preview) {
      console.log("%c ***** Entering Preview Mode ******", "color: #1ECBE1");
      this.home.loadPreviewMode();
    }

    console.log("this.uid: ", this.uid);
    this.home.getContentBlockByUid(this.uid).then((contentBlock) => {
      this.slice = {
        type: 'content_block',
        primary: {
          margin_top: null,
          margin_bottom: null,
          class_name: "",
        },
        fields: [contentBlock.data]
      };

      console.log("SlicePreviewComponent this.slice", this.slice);

      this.store.dispatch(hideInlineLoader());
    });
  }

  openModal(data) {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      scroll: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered",
      containerClass: "",
      animated: true,
      data: {
        heading: "Let's Meet Your Car",
        content: {
          heading: "Content heading",
          description: "Content description",
        },
        vehicle: this.vehicleObject,
        leadData: null,
      },
    };

    this.modalRef = this.modalService.show(ModalComponent, modalOptions);

    const modalRefSubscription = this.modalRef.content.action.subscribe(
      (result: any) => {
        // console.log(result);
        modalRefSubscription.unsubscribe();
      }
    );
  }
}
