
import { Actions, createEffect, ofType, Effect } from "@ngrx/effects";
import { EMPTY, Subscription } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { ProspectService } from "../_services/prospect-service.service";
import * as ActionConfigurationManager from "../_actions/config.actions";
import { Store } from "@ngrx/store";
import { RatesResponse } from "../_models/rates";
import { Injectable } from "@angular/core";
import { LoggingService } from "../_services/logging.service";
import { Cart } from "../_models/cart";
import { cartTaxTokenRefreshSuccess, checkoutAVSTokenRefreshSuccess, purchaseContractTokenRefreshSuccess } from "../_actions/token-refresh.actions";
import { Lead } from "../_models/lead";
import { Plan } from "../_models/plan";
import { submitTax } from "../_actions/tax.actions";
import { TaxRequest } from "../_models/tax";
import { AVSRequest } from "../_models/avs";
import { submitAVS } from "../_actions/avs.actions";
import { prepCartForOrder } from "../_actions/cart.actions";
import { CartOrderData } from "../_models/order";
import { Opportunity } from "../_models/account";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable()
export class TokenRefreshEffects {
  taxRequest: TaxRequest = {
    opportunityId: '',
    city: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    postalCode: '',
    state: '',
    street1: '',
    vin: '',
    brandName: '',
    downPayment: 0,
    installments: 0,
    items: []
  }

  public refreshTokenCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.REFRESH_TOKEN_CART_TAX),
      switchMap((action: any) =>
        this.leadService.get_opportunity_token_refresh(action).pipe(
          map((lead) => {
            this.submitTax()
            return cartTaxTokenRefreshSuccess();
          }),
          catchError(() => {
            return EMPTY;
          })
        )
      )
    )
  );

  public refreshTokenAVS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.REFRESH_TOKEN_AVS_TAX),
      switchMap((action: any) =>
        this.leadService.get_opportunity_token_refresh(action).pipe(
          map((lead) => {
            let request: AVSRequest = {
              street1: action.street1,
              city: action.city,
              state: action.state,
              country: action.country,
              vin: action.vin,
              email: action.email,
              zip: action.postalCode,
              brandName: this.brandInfo.brand
            }
            this.store.dispatch(submitAVS(request))
            return checkoutAVSTokenRefreshSuccess();
          }),
          catchError(() => {
            return EMPTY;
          })
        )
      )
    )
  );

  public refreshTokenPurchaseContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.REFRESH_TOKEN_PURCHASE_CONTRACT),
      switchMap((action: any) =>
        this.leadService.get_opportunity_token_refresh(action).pipe(
          map((lead) => {
            let request: CartOrderData = {
              cartData: action.cartData,
              purchaseContractRequest: action.purchaseContractRequest
            }
            this.store.dispatch(prepCartForOrder(request))
            return purchaseContractTokenRefreshSuccess();
          }),
          catchError(() => {
            return EMPTY;
          })
        )
      )
    )
  );

  submitTax() {
    let rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    let lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _cart: Cart = JSON.parse(sessionStorage.getItem('cart'))
    let opp: Opportunity = JSON.parse(sessionStorage.getItem('opp'))

    let taxRequest: TaxRequest = {
      opportunityId: opp.opportunityId,
      city: lead.customer.city,
      country: this.brandInfo.country,
      email: lead.customer.emailId,
      firstName: lead ? lead.customer.firstName : 'GuestFirst',
      lastName: lead ? lead.customer.firstName : 'GuestLast',
      phoneNumber: lead.customer.phoneNumber1 || lead.customer.phoneNumber2,
      postalCode: lead.customer.zipCode,
      state: lead.customer.state,
      street1: lead.customer.address1 || lead.customer.address2,
      vin: lead.vehicle.vin,
      brandName: this.brandInfo.brand,
      downPayment: _cart.downPayment,
      installments: _cart.numberOfMonthlyPayments,
      items: []
    }

    for (let i = 0; i < _cart.plans.length; i++) {
      let item = {
        productName: _cart.plans[i].ratesProductName,
        productSKU: _cart.plans[i].productSkuId,
        quantity: 1,
        unitPrice: _cart.plans[i].planPrice,
        productSfId: _cart.plans[i].productSkuId,
        productCode: _cart.plans[i].productCode,
        contractNumber: _cart.plans[i].ratesProductName
      };

      taxRequest.items.push(item)

    }

    console.log('Tax Request at Shopping Cart Token Refresh:', taxRequest)

    this.store.dispatch(submitTax(taxRequest));
  }

  brandInfo: BrandingInfo;
brandInfoSub: Subscription;

  constructor(
    private actions$: Actions,
    private leadService: ProspectService,
    private loggingService: LoggingService,
    public store: Store<{
      brandInfo: BrandingInfo
    }>
  ) {

    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
    });
   }
}
