import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class BrandInfoService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {}

  brandInfo: BrandingInfo = {
    id: "",
    brand: "",
    name: "",
    urls: [],
    scss: "",
    css: "",
    icons: {
      androidChrome192x192: "",
      androidChrome512x512: "",
      appleTouchIcon: "",
      favicon16x16: "",
      favicon32x32: "",
      favicon: "",
    },
    favicon: "",
    title: "",
    gaid: "",
    uom: "",
    useFakeAPI: false,
    gtmid: {
      dev: "",
      prod: "",
    },
    isoCode: "",
    language: "",
    supportedLanguages: [],
    country: "",
    homePage: "",
    homePageElectric: "",
    preferenceCenterUrl: "",
    isPaymentOptions: false,
    siteSecuritySeal: "",
    isMultiProduct: false,
    okta: false,
    fonts: [],
    showDealerName: false,
    frontLoadTaxes: false,
    multiDeductible: false,
    cookieYesLink: "",
  };

  private brandInfoSubject: BehaviorSubject<BrandingInfo> = new BehaviorSubject<BrandingInfo>(this.brandInfo);

  updateBrandInfo(newBrandInfo: BrandingInfo) {
    console.log("newBrandInfo is: ", newBrandInfo);
    this.storeBrandInfo(newBrandInfo);
    this.brandInfo = newBrandInfo;
    return this.brandInfo;
  }

  // A function within the BrandInfoService that checks to see if the brandInfo object is stored in local storage
  // If it is, then it will return the object
  // If it is not, then it will return null
  loadBrandInfo() {
    if (localStorage.getItem("brandInfo")) {
      return JSON.parse(localStorage.getItem("brandInfo"));
    } else {
      return null;
    }
  }

  // Get Grand Info from store
  getBrandInfo(): Observable<BrandingInfo> {
    return this.store.select((store) => store.brandInfo);
  }

  // A function within the BrandInfoService that will store the brandInfo object in local storage
  storeBrandInfo(brandInfo: BrandingInfo) {
    localStorage.setItem("brandInfo", JSON.stringify(brandInfo));
  }

  // A function that prints  BrandInfo object to console
  printBrandInfo() {
    console.log("brandInfo is: ", this.brandInfo);
  }

  // A function within the BrandInfoService that will remove the brandInfo object from local storage
  removeBrandInfo() {
    localStorage.removeItem("brandInfo");
  }

  // A function within the BrandInfoService that will get brandInfo from API the brandInfo object
  getBrandMetadata(url) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/brands/url`, {
        params: {
          url: url,
        },
      })
      .pipe(map((response) => response.data))
      .subscribe((response) => {
        console.log("brandInfo is: ", response);
        let brandInfo: BrandingInfo = response;

        this.updateBrandInfo(brandInfo);
        this.brandInfoSubject.next(brandInfo);
      });
  }
  getBrandFromAPI(url) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/v1/api/brands/url`, {
        params: {
          url: url,
        },
      })
      .pipe(map((response) => response.data));
  }
}
