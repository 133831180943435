import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MDBModalRef, ModalOptions } from "ng-uikit-pro-standard";
import { Subject, Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Component({
  selector: "app-modal-timeout",
  templateUrl: "./modal-timeout.component.html",
  styleUrls: ["./modal-timeout.component.scss"],
})
export class ModalTimeoutComponent implements OnInit {
  public modalType: string;
  public action: Subject<any> = new Subject();
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    public options: ModalOptions,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit(): void {
    console.log(this.options.data); // here you will get the value

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });
  }

  killSession() {
    this.router.navigate([`/${this.brandInfo.homePage}`]);
    this.action.next("killsession");
  }

  keepSession() {
    this.action.next("keepalive");
  }

  renew() {
    location.reload();
  }
}
