import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, Subscription, throwError } from 'rxjs';
import { Order } from '../_models/order'
import { AccountResponse } from '../_models/account';
import { environment } from '../../environments/environment'
;

import { GatewayService } from './gateway.service';
import { BrandingInfo } from '../_models/brandingInfo';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  /**
   * Order Service Methods
   * Add Order
   * Get Order
   * Update Order
   */

   brandInfo: BrandingInfo;
   brandInfoSub: Subscription;

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private store: Store<{
    brandInfo: BrandingInfo
  }>) {

    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
    });

  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  get_order(orderId): Observable<AccountResponse> {
    console.log(orderId);
    return this.httpClient.get<AccountResponse>(this.gatewayService.baseUrl + 'dtc-order-srvc/orders/account/' + orderId.payload + '?brandName=' + this.brandInfo.brand)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }



  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
