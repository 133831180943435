import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { ModalInterstitialComponent } from "src/app/modal-interstitial-popup/modal-Interstitial-popup.component";
import { BrandingInfo } from "src/app/_models/brandingInfo";
import { Lead } from "src/app/_models/lead";
import { HomeService } from "src/app/_services/home.service";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-footer-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  public lead$: Observable<Lead>;
  modalRef: MDBModalRef;
  footer: any;
  branding: any;
  isFooterVisible = true;
  useLegalFolder = true;
  dealerName = null;
  isGMBrand = false;
  thisLang;
  brandInfo: BrandingInfo;
  constructor(
    private modalService: MDBModalService,
    private home: HomeService,
    private appConfig: AppConfigService,
    private prismicService: PrismicService,
    private store: Store<{
      lead: Lead;
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit() {
    this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;
          this.thisLang = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;

          if (this.brandInfo) {
            if (this.brandInfo.brand === "NCESI") {
              // Nissan Canada different legal link structure
              this.useLegalFolder = false;
            }

            if (this.brandInfo.brand === "GMC" || this.brandInfo.brand === "Chevrolet" || this.brandInfo.brand === "Cadillac" || this.brandInfo.brand === "Buick") {
              this.isGMBrand = true;
            }

            if (this.brandInfo.showDealerName) {
              this.lead$ = this.store.select((store) => store.lead);
              this.lead$.subscribe((lead) => {
                if (lead && lead.dealerName) {
                  this.dealerName = lead.dealerName;
                }
              });
            }

            this.home.getFooter(this.brandInfo.brand, this.thisLang).subscribe({
              next: (result) => {
                this.footer = result;
              },
              error: (error) => {
                this.isFooterVisible = false;
              },
            });

            this.appConfig.getSettings().subscribe({
              next: (result) => {
                console.log("getSettings result", result);
                this.branding = result;
              },
              error: (err) => {
                this.isFooterVisible = false;
              },
            });

            setTimeout(() => {
              if (this.brandInfo.siteSecuritySeal !== "" && this.isFooterVisible) {
                // Make sure the Footer is visible and then Load Security Seal if available
                this.loadVroomSecuritySealTag();
              }
            }, 250);
          }
        }
      });
  }

  loadVroomSecuritySealTag(): void {
    const securitySealScript = document.createElement("script");
    securitySealScript.setAttribute("async", "true");
    securitySealScript.setAttribute("src", this.brandInfo.siteSecuritySeal);
    document.body.appendChild(securitySealScript);
    document.getElementById("siteseal").appendChild(securitySealScript);
    var addTag = setInterval(() => {
      console.log("lookingup godaddy");
      const sitesealimg = document.querySelector("#siteseal img");
      if (sitesealimg) {
        sitesealimg.setAttribute("tabindex", "0");
        console.log("found godaddy");
        clearInterval(addTag);
      }
    }, 250);
    setTimeout(() => {
      console.log("timed out godaddy");
      clearInterval(addTag);
    }, 10000); // clear setInterval after 10 seconds in case godaddy seal fails to load.
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  showInterstitial() {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "",
      animated: true,
      data: {
        timeout: 30000,
        title: "Notice",
        link: "https://www.gmfinancial.com/en-us/privacy-and-security/california-privacy-rights.html",
        content: "You are now being directed to GM Financial. GM Protection is a wholly owned subsidiary of GM Financial.<br><br>If you haven't been redirected in 30 seconds, please click continue.",
      },
    };
    this.modalRef = this.modalService.show(ModalInterstitialComponent, modalOptions);
  }
}
