<div class="modal-content">
    <div class="modal-header mb-n1">
        <app-close-button (callbackFunction)="modalRef.hide()"></app-close-button>
    </div>
    <div class="modal-body p-0" role="alert">
        <div class="row pl-3 pr-3">
            <div class="col-12 text-center">
                <h3>{{ 'CART_CHECKOUT.ARE_YOU_SURE' | translate }}</h3>
                <br />
                <p>{{ 'CART_CHECKOUT.ADDING_ITEM_DELETE' | translate }}<br />
            </div>
        </div>
        <div class="row desktop">
            <div class="col-6 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4"
                style="background:var(--grey-lite);">
                <!-- <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a> -->
                <a (click)="modalRef.hide()" id="cart-remove-btn" tabindex="0" (click)="addNewItem()"
                    class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect>{{ 'CART_CHECKOUT.YES_UPDATE' | translate }}</a>
            </div>
            <div class="col-6 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4"
                style="background:var(--grey-lite);">
                <!-- <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a> -->
                <a (click)="modalRef.hide()" tabindex="0" class="btn primary-btn waves-light float-left" mdbBtn
                    mdbWavesEffect>{{ 'CART_CHECKOUT.NO_BACK' | translate }}</a>
            </div>
        </div>
        <div class="row mobile">
            <div class="col-12 w-100 float-left text-center pt-2 pr-4 pl-4 mt-2"
                style="background:var(--grey-lite);">
                <!-- <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a> -->
                <a (click)="modalRef.hide()" id="cart-remove-btn" tabindex="0" (click)="addNewItem()"
                    class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect>{{ 'CART_CHECKOUT.YES_UPDATE' | translate }}</a>
            </div>
        </div>
        <div class="row mobile">
            <div class="col-12 w-100 float-left pt-2 text-center pb-3 pr-4 pl-4 mt-2"
                style="background:var(--grey-lite);">
                <!-- <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a> -->
                <a (click)="modalRef.hide()" tabindex="0" class="btn primary-btn waves-light float-left" mdbBtn
                    mdbWavesEffect>{{ 'CART_CHECKOUT.NO_BACK' | translate }}</a>
            </div>
        </div>
    </div>
</div>
