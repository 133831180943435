import { Injectable } from "@angular/core";
import { HomeService } from "./home.service";
import { Observable, Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";
import { Store } from "@ngrx/store";

interface SearchResponseItem {
  page: string;
  title: string;
  searchText: string;
}

@Injectable({
  providedIn: "root",
})
export class GlobalSearchService {
  searchPageObject = {};
  typeAheadArray = [];
  searchData = [];
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;
  constructor(
    private home: HomeService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
    });

  }

  // Calls this to read Global Search Data from LocalStorage
  searchDataObservable(searchQuery): Observable<SearchResponseItem[]> {
    let _concatenatedString = "";
    let _numberOfObjects = localStorage.getItem("globalSearchCounter").split(".")[0];
    console.log(_numberOfObjects);
    for (let i = 0; i < Number(_numberOfObjects); i++) {
      _concatenatedString = _concatenatedString + localStorage.getItem("globalSearch" + i);
    }

    // console.log(_concatenatedString);

    const searchData = JSON.parse(_concatenatedString);
    let searchContent: SearchResponseItem[] = [];

    // console.log('SearchData', searchData);
    // console.log('searchquery', searchQuery);

    for (const key in searchData) {
      if (searchData.hasOwnProperty(key)) {
        const element = searchData[key];

        if (searchData[key].searchText.toLowerCase().includes(searchQuery.toLowerCase())) {
          searchContent.push({ page: key, title: searchData[key].title, searchText: searchData[key].searchText });
        }
      }
    }

    return new Observable<SearchResponseItem[]>(function (observer) {
      observer.next(searchContent);
      observer.complete();
    });
  }

  typeAheadObservable(): Observable<any> {
    return new Observable<any>(function (observer) {
      const typeAheadStorage = JSON.parse(localStorage.getItem("typeAheadArray"));

      if (typeAheadStorage) {
        // console.log('next for typeAheadObservable: ', typeAheadStorage);
        observer.next(typeAheadStorage);
        observer.complete();
      }
    });
  }

  // Get All Search Data
  getSearchData() {
    this.searchData = JSON.parse(localStorage.getItem("globalSearch"));
    const typeAheadArray = JSON.parse(localStorage.getItem("typeAheadArray"));

    localStorage.removeItem("globalSearch");
    localStorage.removeItem("typeAheadArray");

    // if (!this.searchData || !typeAheadArray) {
    // console.log('findPageAdvanced...');
    // Search Prismice
    this.home.findPageAdvanced("home", sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language).subscribe((result) => {
      // Get pages from Prismic
      const pages = result.data.allPages.edges;

      // Flatten Data for Each Page
      pages.forEach((content) => {
        // Convert from complex Prismic object to simple object (this.≈)

        const page_name = content.node._meta.uid;
        this.flattenData(content.node, page_name);
      });

      // Get Support Pages
      this.home.getAllSupportPages(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language).subscribe((result2) => {
        const supportPages = result2.allSupport_pages.edges;

        supportPages.forEach((content) => {
          // Convert from complex Prismic object to simple object (this.≈)
          const supportPageName = content.node._meta.uid;
          this.flattenData(content.node, supportPageName);
        });

        // const mergedData = Object.assign(supportPages, pages);

        // Set in Local Stoage
        this.storeToLocalStorage();
      });
    });
    // } else {
    // }
  }

  storeToLocalStorage() {
    // console.log('this.searchPageObject: ', this.searchPageObject);
    localStorage.removeItem("globalSearch");

    let _globalSearch = JSON.stringify(this.searchPageObject);
    let _globalSearch_length = _globalSearch.length;
    let _numberOfObjects = (_globalSearch_length / 250).toString();

    _numberOfObjects = _numberOfObjects.split(".")[0];

    // console.log('Global Search Length', _globalSearch_length);

    // console.log('Number of Objects:', _numberOfObjects);

    let _remainder = _globalSearch_length % 250;
    if (_remainder > 0) {
      let number = Number(_numberOfObjects);
      number = number + 1;
      _numberOfObjects = number.toString();
      // console.log('Number of Objects after remainder added:', _numberOfObjects);
    }

    localStorage.setItem("globalSearchCounter", _numberOfObjects);

    for (let i = 0, index = 0; i < Number(_numberOfObjects); i++) {
      localStorage.setItem("globalSearch" + i, JSON.stringify(this.searchPageObject).substr(index, 250));
      index = index + 250;
    }

    // localStorage.setItem('globalSearch', JSON.stringify(this.searchPageObject));
    localStorage.setItem("typeAheadArray", JSON.stringify(this.typeAheadArray));
  }

  searchObject(obj, page_name) {
    let flattened = {};
    const keysArray = Object.keys(obj);

    for (let i = 0; i < keysArray.length; i++) {
      const key = keysArray[i];
      if (Array.isArray(obj[key])) {
        flattened = this.searchArray(obj[key], page_name);
      } else if (key === "type" && (obj["type"] === "heading1" || obj["type"] === "heading2") && obj.hasOwnProperty("text")) {
        flattened["type"] = obj["type"];
        flattened["text"] = obj["text"];

        if (this.searchPageObject[page_name]) {
          this.searchPageObject[page_name].searchText = this.searchPageObject[page_name].searchText.concat(" ", this.searchPageObject[page_name].searchText, " ", flattened["text"]);

          // Store Type Ahead Text for all Heading 1 and Heading 2
          this.typeAheadArray.push(flattened["text"]);
        } else {
          this.searchPageObject[page_name] = { title: flattened["text"], searchText: flattened["text"] };

          // Store Type Ahead Text for all Heading 1 and Heading 2
          this.typeAheadArray.push(flattened["text"]);
        }

        return flattened;
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        flattened = this.searchObject(obj[key], page_name);
      } else {
        continue;
      }
    }
    return flattened;
  }

  searchArray(array, page_name) {
    let flattened = {};

    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        flattened = this.searchArray(array[i], page_name);
      } else if (typeof array[i] === "object" && array[i].type) {
        flattened = this.searchObject(array[i], page_name);
      } else if (typeof array[i] === "object" && array[i].__typename) {
        flattened = this.searchObject(array[i], page_name);
      } else {
        console.log("do nothing");
      }
    }

    return flattened;
  }

  // Searches for
  searchDeep(page_name, arr) {
    // Flatten Object or Array
    if (Array.isArray(arr)) {
      arr.forEach((element, index) => {
        if (typeof element === "object") {
          this.searchObject(element, page_name);
        }
      });
    } else {
      this.searchObject(arr, page_name);
    }
  }

  flattenData(content_node, page_name) {
    this.searchDeep(page_name, content_node);
  }
}
