import * as ActionConfigurationManager from './config.actions';

export const submitOrderAccountFlow = ActionConfigurationManager.SUBMIT_ORDER_ACCOUNT_FLOW;
export const orderSubmittedAccountFlow = ActionConfigurationManager.ORDER_SUBMITTED;
export const submitOrderGuestFlow = ActionConfigurationManager.SUBMIT_ORDER_GUEST_FLOW
export const submitOrderSuccess = ActionConfigurationManager.SUBMIT_ORDER_SUCCESS
export const submitOrderFailed = ActionConfigurationManager.SUBMIT_ORDER_FAILED
export const showOrderStateInlineLoader = ActionConfigurationManager.SHOW_ORDER_STATE_INLINE_LOADER;
export const hideOrderStateInlineLoader = ActionConfigurationManager.HIDE_ORDER_STATE_INLINE_LOADER
export const submitOrderChangeVehicleFlow = ActionConfigurationManager.SUBMIT_ORDER_CHANGE_VEHICLE_FLOW;
export const paymentError = ActionConfigurationManager.CHECKOUT_ERROR_PAYMENT
export const resetCheckoutState = ActionConfigurationManager.CHECKOUT_ERROR_RESET
export const submitOrderChangeAccountFlow = ActionConfigurationManager.SUBMIT_ORDER_CHANGE_ACCOUNT_FLOW
export const noGuestAllowed = ActionConfigurationManager.NO_GUEST_ALLOWED
export const notGuest = ActionConfigurationManager.NOT_GUEST
