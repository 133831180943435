<app-inline-loader></app-inline-loader>
<app-countdown></app-countdown>
<app-fresh-relevance-header></app-fresh-relevance-header>

<div class="{{pageClass}}">
  <div *ngFor="let slice of slices">
    <div [ngSwitch]="slice?.type">
      <app-slice-hero-section *ngSwitchCase="'hero'" [vehicleObject]="vehicleObject" [slice]="slice" (emitOpenModal)="openModal($event)"></app-slice-hero-section>
      <app-slice-plans *ngSwitchCase="'plans'" [slice]="slice" [vehicleObject]="vehicleObject" (emitOpenModal)="openModal($event)"></app-slice-plans>
      <app-slice-content-block *ngSwitchCase="'content_block'" [slice]="slice" [vehicleObject]="vehicleObject" (emitOpenModal)="openModal($event)"></app-slice-content-block>
      <app-slice-faq *ngSwitchCase="'faq'" [vehicleObject]="vehicleObject" [slice]="slice" (emitOpenModal)="openModal($event)"></app-slice-faq>
      <app-slice-image *ngSwitchCase="'image'" [slice]="slice"></app-slice-image>
      <app-slice-title *ngSwitchCase="'title'" [slice]="slice" [vehicle]="vehicleObject"></app-slice-title>
      <app-slice-text *ngSwitchCase="'text'" [slice]="slice" [vehicle]="vehicleObject"></app-slice-text>
      <!-- <app-slice-plans-hero *ngSwitchCase="'product'" [vehicle]="vehicleObject" (emitOpenModal)="openModal($event)" [plans]="plans" [slice]="slice"> </app-slice-plans-hero> -->
      <app-slice-product-tabset *ngSwitchCase="'products_tabset'" [vehicle]="vehicleObject" [slice]="slice"></app-slice-product-tabset>
      <app-slice-product *ngSwitchCase="'product'" [slice]="slice" [vehicleObject]="vehicleObject" (emitOpenModal)="openModal($event)"></app-slice-product>
      <!-- <app-slice-features *ngSwitchCase="'worry-free-protection'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-features>
      <app-slice-facts *ngSwitchCase="'rev-vsp-info-cards'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-facts>
      <app-slice-how-it-works *ngSwitchCase="'coverage-beyond-warranty'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-how-it-works>
      <app-slice-benefits *ngSwitchCase="'benefits'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-benefits>
      <app-slice-lifestyle *ngSwitchCase="'lifestyle'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-lifestyle>
      <app-slice-all-features-include *ngSwitchCase="'all_features_include'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-all-features-include>
      <app-slice-choose-your-coverage *ngSwitchCase="'choose_your_coverage'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-choose-your-coverage>
      <app-slice-related-products *ngSwitchCase="'related_products'" [slice]="slice" [vehicleObject]="vehicleObject"></app-slice-related-products> -->
    </div>
  </div>
</div>
