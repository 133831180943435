import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { RatesResponse } from "../_models/rates";
import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";
import { environment } from "../../environments/environment";
import { Store } from "@ngrx/store";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable({
  providedIn: "root",
})
export class WrapperService {
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private httpClient: HttpClient,
    private gatewayService: GatewayService,
    private loggingService: LoggingService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //Url Params for request

  get_rates(ratesObject): Observable<RatesResponse> {
    console.log("Rate API hit", ratesObject);

    let productCode = "";

    if (ratesObject.brandName === "NCESI") {
      if (ratesObject.product === "PPM") {
        productCode = "PPM";
      } else {
        productCode = "NASP";
      }
    } else if (ratesObject.brandName === "Vroom") {
      if (ratesObject.product === "EVSC") {
        productCode = "VRED";
      } else {
        productCode = "VRVD";
      }
    } else if (ratesObject.brandName === "Schomp") {
      productCode = "SCVD";
    } else if (ratesObject.brandName === "GMC" || ratesObject.brandName === "Cadillac" || ratesObject.brandName === "Buick" || ratesObject.brandName === "Chevrolet") {
      productCode = "VSC";
    } else {
      productCode = "REVS";
    }

    if (this.brandInfo.useFakeAPI) {
      return this.httpClient
        .get<RatesResponse>(this.gatewayService.baseUrl + "dtc-wrapper-srvc/productsDetails", {
          params: {
            brandName: ratesObject.brandName,
            VIN: ratesObject.vin,
            saleDate: ratesObject.saleDate,
            odometer: ratesObject.odometer.toString().replace(",", ""),
            inServiceDate: ratesObject.inServiceDate,
            financeType: ratesObject.financeType,
            isAfterSale: ratesObject.isAfterSale,
            vehiclePurchaseDate: ratesObject.vehiclePurchaseDate,
            vehicleCondition: ratesObject.vehicleCondition,
            customerState: ratesObject.customerState,
            financeAmount: ratesObject.financeAmount,
            vehicleCost: ratesObject.vehicleCost,
            vehicleMSRP: ratesObject.vehicleMSRP,
            lienholderName: ratesObject.lienholderName,
            vehicleSfId: ratesObject.vehicleSfId,
            productCode: productCode,
            language: this.brandInfo.brand === 'NCESI' ? sessionStorage.getItem('preferredLanguage') ? sessionStorage.getItem('preferredLanguage') : 'en-ca' : null
          },
        })
        .pipe(
          retry(1),
          catchError((httpResponse) => {
            return this.loggingService.handleErrorWithD2CCapture(httpResponse, "medium", "get_rates");
          })
        );
    } else {
      return this.httpClient
        .post<RatesResponse>(`${environment.apiUrl}/v1/api/rates/getRates`, {
          vin: ratesObject.vin,
          saledate: ratesObject.saleDate,
          inServiceDate: ratesObject.inServiceDate,
          odometer: ratesObject.odometer.toString(),
          brandName: this.brandInfo.brand,
          customerState: ratesObject.customerState,
          product: productCode,
          language: this.brandInfo.brand === 'NCESI' ? sessionStorage.getItem('preferredLanguage') ? sessionStorage.getItem('preferredLanguage') : 'en-ca' : null

        })

        .pipe(
          retry(1),
          catchError((httpResponse) => {
            return this.loggingService.handleErrorWithD2CCapture(httpResponse, "medium", "get_rates");
          })
        );
    }
  }
}
