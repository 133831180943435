import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { verifyAccount } from 'src/app/_actions/verify-account.actions';
import { resetInvalidCodeState } from 'src/app/_actions/verify-code-state.actions';
import { BrandingInfo } from 'src/app/_models/brandingInfo';
import { VerifyAccountRequest, VerifyCodeRequest } from 'src/app/_models/verify-account';
import { BrandInfoService } from '../../../_services/brand-info.service';

@Component({
  selector: 'app-my-account-verify-phone',
  templateUrl: './my-account-verify-phone.component.html',
  styleUrls: ['./my-account-verify-phone.component.scss']
})
export class MyAccountVerifyPhoneComponent implements OnInit, OnChanges {
  @Output() verifyCodeRequest: EventEmitter<VerifyCodeRequest> = new EventEmitter<VerifyCodeRequest>();
  @Output() viewEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() verifyType: EventEmitter<string> = new EventEmitter<string>();
  @Input() phoneReset: boolean
  verifyAccount$: Observable<any>;
  verifyAccountSubscription: Subscription;

  session$: Observable<any>;
  accountPhoneValidationForm: UntypedFormGroup;
  brandInfo: BrandingInfo;

  constructor(
    public store: Store<{
      session: any,
      verifyAccount: any
    }>,
    private brandInfoService: BrandInfoService
  ) { }

  ngOnInit(): void {
    this.session$ = this.store.select(store => store.session);
    this.brandInfoService.getBrandInfo().subscribe((data) => {
      this.brandInfo = data
    });

    this.accountPhoneValidationForm = new UntypedFormGroup({
      phoneNumber: new UntypedFormControl(null, [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.minLength(10)]),
      lastName: new UntypedFormControl(null, Validators.required),
    });
      this.verifyAccount$ = this.store.select(store => store.verifyAccount)
  }

  ngOnChanges() {
    this.resetForm(this.phoneReset)
  }
  ngOnDestroy() {
    if(this.verifyAccountSubscription) {
      this.verifyAccountSubscription.unsubscribe()
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  verifyAccountPhone() {
    this.store.dispatch(resetInvalidCodeState())

    let verifyAccountRequest: VerifyAccountRequest = {
      brandName: this.brandInfo.name,
      emailId: null,
      phoneNumber: this.accountPhoneValidationForm.controls["phoneNumber"].value,
      contactType: 'phone',
      lastName: this.accountPhoneValidationForm.controls["lastName"].value
    }

    let verifyCodeRequest = {
      contact: this.accountPhoneValidationForm.controls["phoneNumber"].value,
      contactType: 'phone',
      lastName: this.accountPhoneValidationForm.controls["lastName"].value,
      code: ''
    }

    //this.store.dispatch(resetSession())
    this.store.dispatch(verifyAccount(verifyAccountRequest))

    this.verifyCodeRequest.emit(verifyCodeRequest)

    this.verifyAccountSubscription = this.verifyAccount$.subscribe(data => {
      if (data)
      {
        if (data?.errorMessage === '' || data?.errorMessage === null || data?.errorMessage === 'null') {
          this.viewEmitter.emit('2')
        }
      }
    })

    this.verifyType.emit('phone')
  }

  resetForm(data) {
    if (data === true) {
      this.accountPhoneValidationForm.reset()
    }
  }
}
