<form [formGroup]="searchForm">
  <div class="md-form active-pink active-pink-2 mb-3 mt-0">
    <input formControlName="searchWithAutocomplete" type="text" class="completer-input form-control mdb-autocomplete mb-0" [mdbAutoCompleter]="auto" id="search1" aria-label="Search" autocomplete="new-search" mdbInput (keyup.enter)="onChangeSearch()" />
    <label for="search1"><mdb-icon fas icon="search" class="mr-1"></mdb-icon> Search</label>

    <mdb-auto-completer #auto="mdbAutoCompleter" (selected)="selectedAutocomplete($event)">
      <mdb-option [value]="searchWithAutocomplete.value" *ngIf="searchWithAutocomplete.value">{{ searchWithAutocomplete.value }}</mdb-option>
      <mdb-option *ngFor="let option of results | async" [value]="option">
        {{ option }}
      </mdb-option>
    </mdb-auto-completer>
  </div>
</form>

<!-- <form [formGroup]="searchForm">
      <input type="text" class="completer-input form-control mdb-autocomplete mb-0" placeholder="First name" formControlName="searchWithAutocomplete" [mdbAutoCompleter]="auto" id="search1" aria-label="Search" autocomplete="new-search" mdbInput (keyup.enter)="onChangeSearch()">
      <mdb-auto-completer #auto="mdbAutoCompleter" (selected)="selectedAutocomplete($event)">
        <mdb-option [value]="searchWithAutocomplete.value" *ngIf="searchWithAutocomplete.value">{{ searchWithAutocomplete.value }}</mdb-option>
        <mdb-option *ngFor="let option of results | async" [value]="option">
          {{ option }}
        </mdb-option>
      </mdb-auto-completer>
  </form> -->
