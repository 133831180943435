import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showInlineLoader, hideInlineLoader } from '../_actions/inline-loader.actions';


const _inlineLoaderReducer = createReducer(initialState,
    on(showInlineLoader, (state) => {
      return true;
    }),
    on(hideInlineLoader, (state) => {
      return false;
    }),
);


export function inlineLoaderReducer(state, action) {
    return _inlineLoaderReducer(state, action);
}
