import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  pages = {
    landingPage: 'vehicle-service-protection',
    ratingPage: "my-vsp-pricing",
    cartPage: "cart",
    checkoutPage: "checkout",
  }
  brandInfo: BrandingInfo;
brandInfoSub: Subscription;

  ratingPages = ["/my-vsp-pricing-electric", "/my-vsp-pricing","/my-asp-pricing", "/my-ppm-pricing", "/my-pmp-pricing", "/plans-pricing"]

  constructor(private router: Router, private store: Store<{
    brandInfo: BrandingInfo
  }> ) {

    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;

      if(!this.brandInfo){return;}

      this.pages.landingPage = `${brandInfo && brandInfo.homePage? brandInfo.homePage : 'vehicle-service-protection'}`;
    });

  }

  getRatingPages() {
    return this.ratingPages;
  }

  getLandingPage() {
    // This is a fix for an anomaly that redirects erroneously to 'help' instead of 'home' for nissan - D2C-2513
    if (this.pages.landingPage === 'help') {
      this.pages.landingPage = '';
    }
    return this.pages.landingPage;
  }

  redirectTo(uri: string, queryParams = null) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";

    if (queryParams) {
      this.router.navigate([uri], { queryParams: { oid: queryParams } });
    } else {
      this.router.navigate([uri])
    }

  }

  setLandingPage(landingPage) {
    this.pages.landingPage = landingPage;
    return this.pages.landingPage;
  }

  getRatingPage() {
    return this.pages.ratingPage;
  }

  setRatingPage(ratingPage) {
    console.log("consolidated - setRatingPage ratingPage: ", ratingPage);
    this.pages.ratingPage = ratingPage;

    window.sessionStorage.setItem("pagesFlow", JSON.stringify(this.pages));
    return this.pages.ratingPage;
  }

  isRatingPage(url) {
    return this.ratingPages.includes(url);
  }
}
