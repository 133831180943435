import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, Subscription, throwError } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import * as ActionConfigurationManager from ".././_actions/config.actions";
import { PaymentService } from "../_services/payment-and-tax-service.service";
import { Action, Store } from "@ngrx/store";
import { avsSubmitted } from "../_actions/avs.actions";
import { hideInlineLoader } from "../_actions/inline-loader.actions";
import { avsFailed, avsSuccess, avsReset } from "../_actions/avs-state.actions";
import { Injectable } from "@angular/core";
import { request } from "http";
import { Opportunity } from "../_models/account";
import { AVSTokenRefresh } from "../_models/token-refresh";

import { removeToken } from "../_actions/token.actions";
import { checkoutAVSTokenRefresh } from "../_actions/token-refresh.actions";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable()
export class AVSEffects {
  public saveAVS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.SUBMIT_FOR_AVS),
      switchMap((action: any) =>
        this.avsService.verify_address(action).pipe(
          map((avs) => {
            this.store.dispatch(avsReset());
            console.log("avs", avs);
            let response: any = avs;
            if (response.errorMessage === null || response.errorMessage === "The address was found but the street number in the input address was not between the low and high range of the post office database.") {
              if (action.state === response.data.suggestedAddressList[0].state) {
                this.store.dispatch(avsSuccess());
                this.store.dispatch(avsSubmitted(avs));
              } else {
                this.store.dispatch(avsFailed());
                this.store.dispatch(avsSubmitted(avs));
              }
              return hideInlineLoader();
            } else {
              this.store.dispatch(avsFailed());
              this.store.dispatch(avsSubmitted(avs));
              return hideInlineLoader();
            }
          }),
          catchError((error) => {
            console.error("Error from tax", error);
            if (error.includes("401 Unauthorized")) {
              let opp: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
              let request: AVSTokenRefresh = {
                opportunityId: opp.opportunityId,
                street1: action.street1,
                city: action.city,
                state: action.state,
                country: action.country,
                vin: action.vin,
                email: action.email,
                postalCode: action.zip,
                brandName: this.brandInfo.brand,
              };
              this.store.dispatch(removeToken());
              this.store.dispatch(checkoutAVSTokenRefresh(request));
            } else {
              this.store.dispatch(avsFailed());
              this.store.dispatch(hideInlineLoader());
              return throwError(error);
            }
          })
        )
      )
    )
  );

  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;
  constructor(
    private actions$: Actions,
    private avsService: PaymentService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });
  }
}
