import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { Account } from 'src/app/_models/account';
import { BrandingInfo } from 'src/app/_models/brandingInfo';

@Component({
  selector: 'app-my-account-verify-account-error-messages',
  templateUrl: './my-account-verify-account-error-messages.component.html',
  styleUrls: ['./my-account-verify-account-error-messages.component.scss']
})
export class MyAccountVerifyAccountErrorMessagesComponent implements OnInit {
  account$: Observable<any>;
  session$: Observable<any>;
  verifyAccount$: Observable<any>;
  verifyAccountSubscription: Subscription;
  branding: any;
  showIcon = false;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private store: Store<{
      session: any,
      account: Account,
      verifyAccount: any,
      brandInfo: BrandingInfo;
    }>,
    private appConfig: AppConfigService
  ) { }

  ngOnInit(): void {


    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
      if (brandInfo.brand === 'NCESI') { // Nissan Canada has close icon for error message
        this.showIcon = true;
      }
    });


    this.account$ = this.store.select(store => store.account)
    this.session$ = this.store.select(store => store.session)
    this.verifyAccount$ = this.store.select(store => store.verifyAccount)

    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });
  }

}
