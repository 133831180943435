export class productHomeType {
  page_main_title: string;
  allProductHomes: any;
}

export class prismicLandingPage {
  brand: string;
  product: string;
  pageType: string;
  isElectric: string;
  lang: string;
  pageId: string;
  pageurl?: string;
  parenturl?: string;
}


export class prismicContent {
  content: any;
  pageMeta: any;
  pageClass?: any;
}
