import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Token } from '../_models/token'
import { addToken, removeToken } from '../_actions/token.actions';
import { Injectable } from "@angular/core";


@Injectable()
export class JwtInterceptor implements HttpInterceptor{

  constructor(private store: Store<{}>){}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: Token = JSON.parse(sessionStorage.getItem("token"));
        if(token && token.token){
            request = request.clone({
                setHeaders: {
                    Authorization: token.token
                }
            });
        }


        // if(request.url.includes('/v1/api/content')){
        //   console.log(`Interception ${request.url}`, request.headers.getAll("prismic-baseurl"),  request.headers.getAll("prismic-ref"));
        // }

        return next.handle(request).pipe(
            map(resp => {
               if (resp instanceof HttpResponse) {
                  // console.log(resp)
                  // console.log(resp.headers.get("Authorization"))

                  if(resp.headers.get('Authorization')) {
                    let authorization: Token =  {
                      token: resp.headers.get("Authorization")
                    }
                    
                    if(authorization.token !== null) {
                      this.store.dispatch(addToken(authorization))
                    } else {
                      this.store.dispatch(removeToken()) 
                    }
                  }
                    return resp;
              }
            })
          );
    }
}