import * as ActionConfigurationManager from './config.actions'

export const getPDF = ActionConfigurationManager.GET_PDF
export const getPDFSuccess = ActionConfigurationManager.GET_PDF_SUCCESS
export const getPDFFailed = ActionConfigurationManager.GET_PDF_FAILED
export const downloadPDF = ActionConfigurationManager.DOWNLOAD_PDF
export const downloadPDFSuccess = ActionConfigurationManager.DOWNLOAD_PDF_SUCCESS
export const downloadPDFFailed = ActionConfigurationManager.DOWNLOAD_PDF_FAILED
export const storePrismicPDF = ActionConfigurationManager.STORE_PRISMIC_PDF
export const rehydratePrismicPDF = ActionConfigurationManager.REHYDRATE_PRIMSIC_PDF
export const setDateAndTime = ActionConfigurationManager.SET_DATE_AND_TIME
export const setDateAndTimeFailed = ActionConfigurationManager.SET_DATE_AND_TIME_FAILED
export const postPaymentPlanAgreement = ActionConfigurationManager.POST_PAYMENY_PLAN_AGREEMENT_PDF
export const postPaymentPlanAgreementSuccess = ActionConfigurationManager.POST_PAYMENY_PLAN_AGREEMENT_PDF_SUCCESS
export const postPaymentPlanAgreementFailed = ActionConfigurationManager.POST_PAYMENY_PLAN_AGREEMENT_PDF_FAILED
export const savePaymentPlanAgreementSignedPdf = ActionConfigurationManager.SAVE_PAYMENT_PLAN_AGREEMENT_SIGNED_PDF

export const postServiceAgreement = ActionConfigurationManager.POST_SERVICE_AGREEMENT_PDF
export const postServiceAgreementSuccess = ActionConfigurationManager.POST_SERVICE_AGREEMENT_PDF_SUCCESS
export const postServiceAgreementFailed = ActionConfigurationManager.POST_SERVICE_AGREEMENT_PDF_FAILED


export const getPaymentPlanAgreement = ActionConfigurationManager.GET_PAYMENT_PLAN_AGREEMENT_PDF
export const getPaymentPlanAgreementSuccess = ActionConfigurationManager.GET_PAYMENT_PLAN_AGREEMENT_PDF_SUCCESS
export const getPaymentPlanAgreementFailed = ActionConfigurationManager.GET_PAYMENT_PLAN_AGREEMENT_PDF_FAILED
export const downloadPaymentPlanAgreement = ActionConfigurationManager.DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF
export const downloadPaymentPlanAgreementSuccess = ActionConfigurationManager.DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF_SUCCESS
export const downloadPaymentPlanAgreementFailed = ActionConfigurationManager.DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF_FAILED



