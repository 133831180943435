<div class="nav-margin">
  <span *ngIf="currentBrand === 'Vroom' || currentBrand === 'Schomp' || currentBrand === 'REV'">
    <div *ngIf="isProduction && (leadData$ | async) as leadData" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-deliveryDate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-light" data-p="1"></div>
    <div *ngIf="!isProduction && (leadData$ | async) as leadData" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-deliveryDate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-light-dev" data-p="1"></div>
  </span>
  <span *ngIf="currentBrand === 'GMC' || currentBrand === 'Chevrolet' || currentBrand === 'Buick' || currentBrand === 'Cadillac'">
    <div *ngIf="isProduction && (leadData$ | async) as leadData" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate"
      [attr.data-merge-brand]="currentBrand"
      [attr.data-merge-manufacturingWarrantyEndDate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-gm-prod" data-p="1"></div>
    <div *ngIf="!isProduction && (leadData$ | async) as leadData" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate"
      [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate"
      [attr.data-merge-brand]="currentBrand"
      [attr.data-merge-manufacturingWarrantyEndDate]="leadData.vehicle?.mfrWarrantyDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-gm-dev" data-p="1"></div>
  </span>
  <span *ngIf="currentBrand === 'NCESI'">
    <div *ngIf="isProduction && (vehicleData$ | async)?.mfrWarrantyDate as mfrWarrantyDate" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-manufacturingWarrantyEndDate]="mfrWarrantyDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-nissan-prod" data-p="1"></div>
    <div *ngIf="!isProduction && (vehicleData$ | async)?.mfrWarrantyDate as mfrWarrantyDate" class="tmspslot"
      style="width:100%; min-height: 0;" data-k="crz1t0f"
      [attr.data-merge-manufacturingWarrantyEndDate]="mfrWarrantyDate"
      [attr.data-merge-language]="preferredLanguage"
      data-slid="countdown-timer-nissan-dev" data-p="1"></div>
  </span>
</div>
