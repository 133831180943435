import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FreshRelevanceService {

  constructor() { }

  loadScriptTag(): void {
    const oldFrScript1 = document.getElementById("frScript1");
    const frScript1 = document.createElement('script');
    frScript1.src = '//d81mfvml8p5ml.cloudfront.net/a9jjhi17.js'
    frScript1.id = "frScript1"
    if (oldFrScript1) {
      document.getElementById("frScript1").remove(); //Need to remove and add back the script on each page load to retrigger FR content
    }
    document.documentElement.firstChild.appendChild(frScript1);

    const oldfrScript2 = document.getElementById("frScript2");
    if (oldfrScript2) {
      // document.getElementById("frScript2").remove(); //Need to remove and add back the script on each page load to retrigger FR content
      if (window['cdsb']) {
        setTimeout(() => {
          window.clearInterval(window['update_countdown_smartblock_interval']);
          var countdowns = document.querySelectorAll('.imagecontainer[data-smartblock-type="countdown"][data-smartblock-countdown-type="animated"]');
          if (countdowns.length) {
            window['cdsb'].smartblocks = [];
            for (var index = 0; index < countdowns.length; index++) {
              window['cdsb'].smartblocks.push({
                smartblock: countdowns[index]
              });
            }
            window['cdsb'].preprocess_countdown_smartblocks();
          }
        }, 1000); // Delay is needed to trigger the countdown timer on a page revisit
      }
    } else {
      const frScript2 = document.createElement('script');
      frScript2.src = '//im.dycdn.net/js/smartblocks/countdown/animated.min.js'
      frScript2.id = "frScript2";
      document.documentElement.firstChild.appendChild(frScript2);
    }
  }
}
