import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  [key: string] : any
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})


export class ConfirmationDialogComponent implements OnInit {
  modalRef: MDBModalRef;
  message: string = "Are you sure?";
  confirmButtonText = "Yes";
  cancelButtonText = "Cancel";
  displayCancel = true;
  displayContinue = true;

  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();
  header: any;



  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}


  ngOnInit(): void {
    if (this.data) {
      this.header = this.data.header || ''
      this.message = this.data.message || this.message,
      this.confirmButtonText = this.data.confirmButtonText || this.confirmButtonText,
      this.cancelButtonText = this.data.cancelButtonText ||  this.cancelButtonText
      this.displayCancel = this.data.displayCancel ||  this.displayCancel,
      this.displayContinue = this.data.displayContinue ||  this.displayContinue
    }

  }

  onClickClose(): void {
    this.buttonClick.emit('closed');
 }

 onCancelClick(): void {
  this.buttonClick.emit('cancel');

 }


  onConfirmClick(): void {
    this.buttonClick.emit('confirm');
  }

}

