import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { submitOrderFailed, submitOrderSuccess, hideOrderStateInlineLoader, paymentError } from '../_actions/order.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Action, Store } from '@ngrx/store';
import { PaymentService } from '../_services/payment-and-tax-service.service';
import { Router } from '@angular/router';
import { loadConfirmationNumber } from '../_actions/confirmation.actions';
import { ConfirmationNumber } from '../_models/confirmation';
import { Injectable } from '@angular/core';
import { LoggingService } from '../_services/logging.service';

@Injectable()

export class OrderEffects {

  public saveOrderAccount$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SUBMIT_ORDER_ACCOUNT_FLOW),
            switchMap((action: any) => this.orderService.purchaseContractAccount(action)
                .pipe(
                    map(order => {
                      console.log(order);
                      console.log('hi')

                      let request: ConfirmationNumber = {
                          confirmationNumber: order.data.orderId
                      }
                      if (order.data === null) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return submitOrderFailed()
                      } else if (order.data.payment === false) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return paymentError()
                      } else {
                        this.store.dispatch(loadConfirmationNumber(request))
                        this.store.dispatch(hideOrderStateInlineLoader())
                        this.router.navigate(['/checkout/confirmation']);
                        return submitOrderSuccess()
                      }
                    }),
                    catchError((error) => this.handleError(error)),

                )
            )
        )
    )

    public saveOrderGuest$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SUBMIT_ORDER_GUEST_FLOW),
            switchMap((action: any) => this.orderService.purchaseContractGuest(action)
                .pipe(
                    map(order => {
                      console.log(order);
                      console.log('hi')

                      let request: ConfirmationNumber = {
                          confirmationNumber: order.data.orderId
                      }
                      if (order.data === null) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return submitOrderFailed()
                      } else if (order.data.payment === false) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return paymentError()
                      } else {
                        this.store.dispatch(loadConfirmationNumber(request))
                        this.store.dispatch(hideOrderStateInlineLoader())
                        this.router.navigate(['/checkout/confirmation']);
                        return submitOrderSuccess()
                      }
                    }),
                    catchError((error) => this.handleError(error)),
                )
            )
        )
    )

    public saveOrderChangeVehicle$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SUBMIT_ORDER_CHANGE_VEHICLE_FLOW),
            switchMap((action: any) => this.orderService.purchaseContractChange(action)
                .pipe(
                    map(order => {
                      console.log(order);
                      console.log('hi')

                      let request: ConfirmationNumber = {
                          confirmationNumber: order.data.orderId
                      }
                      if (order.data === null) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return submitOrderFailed()
                      } else if (order.data.payment === false) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return paymentError()
                      } else {
                        this.store.dispatch(loadConfirmationNumber(request))
                        this.store.dispatch(hideOrderStateInlineLoader())
                        this.router.navigate(['/checkout/confirmation']);
                        return submitOrderSuccess()
                      }
                    }),
                    catchError((error) => this.handleError(error)),
                )
            )
        )
    )

    public saveOrderChangeAccount$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SUBMIT_ORDER_CHANGE_ACCOUNT_FLOW),
            switchMap((action: any) => this.orderService.purchaseContractChange(action)
                .pipe(
                    map(order => {
                      console.log(order);
                      console.log('hi')

                      let request: ConfirmationNumber = {
                          confirmationNumber: order.data.orderId
                      }
                      if (order.data === null) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return submitOrderFailed()
                      } else if (order.data.payment === false) {
                        this.store.dispatch(hideOrderStateInlineLoader())
                        return paymentError()
                      } else {
                        this.store.dispatch(loadConfirmationNumber(request))
                        this.store.dispatch(hideOrderStateInlineLoader())
                        this.router.navigate(['/checkout/confirmation']);
                        return submitOrderSuccess()
                      }
                    }),
                    catchError((error) => this.handleError(error)),
                )
            )
        )
    )

    handleError(error): Observable<never>{
      this.loggingService.captureException(error, 'API');
      return EMPTY;
    }

    constructor(
        private actions$: Actions,
        private store: Store<{}>,
        private router: Router,
        private orderService: PaymentService,
        private loggingService: LoggingService
    ) {}
}
