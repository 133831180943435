import { VinDecodeResponse, VinDecodeData, Vehicle } from "../_models/vehicle";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription, throwError } from "rxjs";
import { retry, catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LoggingService } from "./logging.service";
import { VINDecodeRequest } from "../_models/vin-decode";
import { RatesResponse } from "../_models/rates";
import { BrandingInfo } from "../_models/brandingInfo";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class VehicleService {
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });
  }

  // HttpClient API get() method => vin decode and fetch vehicle information
  vin_decode(vinDecodeRequest): Observable<VinDecodeData> {
    return this.httpClient
      .get<VinDecodeResponse>(environment.apiUrl + "dtc-vehicle-srvc/vin/decode", {
        params: {
          vin: vinDecodeRequest.toUpperCase(),
        },
      })
      .pipe(
        map((result) => result.data),
        retry(1),
        catchError(this.loggingService.handleError)
      );
  }

  getVehicleImage(vehicle: Vehicle) {
    return this.httpClient
      .get(`${environment.apiUrl}/v1/api/vehicle`, {
        params: {
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model,
          trim: vehicle.trim,
          color: vehicle.exteriorColor,
        },
      })
      .pipe(catchError(this.loggingService.handleError));
  }

  decodeVinGetRates(dataObject: VINDecodeRequest) {
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let page = JSON.parse(sessionStorage.getItem("pagesFlow"));
    let product = "";

    console.log('consolidated - decodeVinGetRates', page);
    if (page.landingPage === "added-security-plan" || page.landingPage === "vehicle-service-protection") {
      if (_vehicle?.isElectric) {
        product = "EVSC";
      } else {
        product = "VSC";
      }
    } else if (page.landingPage === "prepaid-maintenance") {
      product = "PPM";
    }

    return this.httpClient
      .post<RatesResponse>(`${environment.apiUrl}/v1/api/rates/getRates`, {
        vin: dataObject.vin.toUpperCase(),
        saledate: dataObject.saleDate,
        inServiceDate: dataObject.inServiceDate,
        odometer: dataObject.odometer,
        brandName: this.brandInfo.brand,
        customerState: dataObject.customerState,
        product: product,
      })
      .pipe(catchError(this.loggingService.handleError));
  }
}
