<div class="search container pl-5 pr-5">
  <div class="row">
      <h1 class="pb-4 col-12">Search Results</h1>
      <div class="col-12">
        <app-searchbox (emitSearch)="emitNewSearch($event)" [isNavSearch]="false" [urlSearchParam]="searchParam"></app-searchbox>
      </div>
  </div>
  <app-search-content [searchData]="searchData"[searchParam]="searchParam" ></app-search-content>
</div>

