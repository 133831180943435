import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { paylinkLoaded, updateBillingStateOn, noAccountFound, loadPaymentHistory } from '../_actions/account.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Store } from '@ngrx/store';
import { hideInlineLoader, hideInlineLoaderPayment, hideInlineLoaderContact, hideInlineLoaderBilling } from '../_actions/inline-loader.actions';
import { Router } from '@angular/router';
import { PaymentHistory, PaymentHistoryMonths, PaymentHistoryObject, PaymentHistoryYears } from '../_models/account';
import { Injectable } from '@angular/core';

@Injectable()

export class PaylinkEffects {

    paymentHistoryObject: PaymentHistoryObject = {
        paymentHistory: []
    }
    paymentHistory: PaymentHistory = {
        months: [],
        year: ''
    }
    paymentHistoryMonths: PaymentHistoryMonths = {
        finalPayment: false,
        status: ''
    }
    paymentHistoryYears: PaymentHistoryYears

    public getPaylink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.LOAD_PAYLINK),
            switchMap((action: any) => this.accountService.get_paylink(action)
                .pipe(
                    map(paylink => {
                        console.log("paylink: ", paylink);
                        if (paylink.data !== null) {
                            console.log(paylink.data.getDataForResult.masterRecords.clientDataRecord[0].firstPaymentDate.substr(5, 2))
                            let firstPaymentDate = new Date(paylink.data.getDataForResult.masterRecords.clientDataRecord[0].firstPaymentDate.substr(0, 10).toString())
                            let numberOfPayments: number = Number(paylink.data.getDataForResult.masterRecords.clientDataRecord[0].totalInstallments)
                            let firstPaymentYear = firstPaymentDate.getFullYear()
                            let lastPaymentDate = new Date(firstPaymentDate.setMonth(firstPaymentDate.getMonth() + numberOfPayments - 1));
                            let lastPaymentYear = new Date(firstPaymentDate.setMonth(firstPaymentDate.getMonth() + numberOfPayments - 1)).getFullYear();
                            let firstPaymentMonth: number = Number(paylink.data.getDataForResult.masterRecords.clientDataRecord[0].firstPaymentDate.substr(5, 2)) - 1

                            console.log(firstPaymentDate, lastPaymentDate)
                            console.log(firstPaymentYear, lastPaymentDate.getFullYear())
                            console.log(firstPaymentDate.getMonth(), lastPaymentDate.getMonth())
                            console.log(lastPaymentDate.getFullYear() - firstPaymentYear)

                            let difference = lastPaymentDate.getFullYear() - firstPaymentYear

                            let status = paylink.data.getDataForResult.masterRecords.clientDataRecord[0].accountStatus

                            if (status === 'Current') {
                                console.log('Current is the status')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        console.log('First Loop:', i)
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            console.log('Second loop:', j)
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                let paymentsMade = paylink.data.getDataForResult.masterRecords.clientDataRecord[0].paymentsMade
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    for (let j = 0; j < 12; j++) {
                                        if (Number(paymentsMade) > 0) {
                                            console.log(Number(paymentsMade))
                                            if (this.paymentHistoryObject.paymentHistory[i].months[j].status !== null) {
                                                this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Paid'
                                                paymentsMade = Number(paymentsMade) - 1
                                            }
                                        }
                                    }
                                }
                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                this.router.navigate(['/my-account']);
                                return paylinkLoaded(paylink)
                            } else if (status === 'Paid-In-Full' || status === 'Closed/Archived') {
                                console.log('Paid In Full is the status')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        console.log('First Loop:', i)
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            console.log('Second loop:', j)
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Paid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.router.navigate(['/my-account']);
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                return paylinkLoaded(paylink)
                            } else if (status === 'Past Due' || status === 'Pending Non-Pay Cancellation' || status === 'Cancelled Due to Non-Payment') {
                                console.log('One of the late payment options')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        console.log(i, firstPaymentYear, lastPaymentDate.getFullYear())
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                console.log('End of loops')
                                let paymentsMade = paylink.data.getDataForResult.masterRecords.clientDataRecord[0].paymentsMade
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    for (let j = 0; j < 12; j++) {
                                        if (Number(paymentsMade) > 0) {
                                            console.log(Number(paymentsMade))
                                            if (this.paymentHistoryObject.paymentHistory[i].months[j].status !== null) {
                                                this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Paid'
                                                paymentsMade = Number(paymentsMade) - 1
                                            }
                                        }
                                    }
                                }

                                let unpaidBoolean = false;
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    console.log(this.paymentHistoryObject.paymentHistory.length)
                                    console.log('late 1')
                                    for (let j = 0; j < 12; j++) {
                                        console.log('i', i)
                                        if (this.paymentHistoryObject.paymentHistory[i].months[j].status === 'Late' && unpaidBoolean === false){
                                            unpaidBoolean = true;
                                            break;
                                        }
                                        if (this.paymentHistoryObject.paymentHistory[i].months[j].status === 'Unpaid' && unpaidBoolean === false) {
                                            console.log('late 1-1')
                                            this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Late'
                                            unpaidBoolean = true;
                                        }
                                    }
                                }

                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                this.router.navigate(['/my-account']);
                                return paylinkLoaded(paylink)
                            } else {
                                this.store.dispatch(hideInlineLoader())
                                // this.router.navigate(['/my-account']);
                                return noAccountFound()
                            }
                        }
                    }),
                    catchError((error) => {
                        // this.store.dispatch(noAccountFound())
                        this.store.dispatch(hideInlineLoader())
                        this.router.navigate(['/my-account']);
                        return throwError(error)
                    }),
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private store: Store<{}>,
        private router: Router
    ) {}
}
