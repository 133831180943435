import { prismicContent, prismicLandingPage } from './../_models/prismic';
import { createAction, props } from '@ngrx/store';
import { Lead, UpdateLead } from '../_models/lead';
import { Vehicle, VehicleImage, VerifyCustVehReq, VerifyVehCustResponse, VerifyVehCustResponseData, Vin, VinDecodeData } from '../_models/vehicle';
import { Cart, CartDeleteItem } from '../_models/cart';
import { Registration } from '../_models/registration';
import { EmailInfo } from '../_models/emailInfo';
import { BillingInfo } from '../_models/billing';
import { Plan } from '../_models/plan';
import { OrderAccountFlow, OrderGuestFlow, OrderChangeVehicleFlow, OrderChangeAccountFlow, CartOrderData } from '../_models/order';
import { OrderSummaryID } from '../_models/order';
import { AVSRequest, AVSResponse } from '../_models/avs';
import { State } from '../_models/states';
import { AccountContactBrand } from '../_models/accountContact';
import { TaxRequest, TaxResponse } from '../_models/tax';
import { AccountID, Paylink, AccountResponse, MyAccount, UpdateBilling, UpdatePayment, PaylinkResponse, PaymentHistoryObject, Opportunity, UpdateBillingInformation, AccountEmail } from '../_models/account'
import { VerifyAccountRequest, VerifyAccountResponse, VerifyCodeRequest, VerifyCodeResponse, VerifyAccountOktaFlowRequest, VerifyAccountOktaFlowResponse } from '../_models/verify-account'
import { RatesResponse, RatesRequest, SliceRateMappingArray } from '../_models/rates';
import { URLParameters } from '../_models/urlParams';
import { Token } from '../_models/token';
import { PaymentPlanAgreementPDF, PDFResponse, PrismicPDF, ServiceAgreement } from '../_models/pdf';
import { ConfirmationNumber } from '../_models/confirmation';
import { ReferenceID } from '../_models/reference-id';
import { Product } from '../_models/product';
import { AVSTokenRefresh, PurchaseContractTokenRefresh } from '../_models/token-refresh';
import { PaymentOptionMax } from '../_models/payment';
import { VINDecodeRequest } from '../_models/vin-decode';
import { AbandonCartTrigger, AbandonCartTriggerSuccess } from '../_models/abandon-cart';
import { OktaChangePasswordRequest, OktaRegisterUserRequest, OktaRegistrationToken, OktaSetPasswordRequest, OktaSignOutRequest, OktaUser, OktaVerifyAccountRequest } from '../_models/okta';
import { BrandingInfo } from '../_models/brandingInfo';


/**
 * Lead Actions
 */

 export const LOAD_LEAD = createAction('[Lead Component] Load Lead', props<AccountContactBrand>());
 export const LEAD_LOADED = createAction('[Lead API] Lead Loaded Success',   props<Lead>());
 export const REHYDRATE_LEAD = createAction('[Rehydrate] Lead', props<Lead>());
 export const UPDATE_LEAD = createAction('[My Account Component] Update Lead', props<UpdateLead>())
 export const LEAD_UPDATED = createAction('[My Account Componrnt] Update Lead Success')
 export const LOAD_LEAD_OPPORTUNITY = createAction('[Lead Component] Load Lead Opportunity', props<AccountContactBrand>());
 export const LEAD_LOADED_OPPORTUNITY = createAction('[Lead API] Lead Loaded Success',   props<Lead>());
 export const LOAD_LEAD_CONTACT_INFORMATION = createAction('[Checkout Component] Load Lead Contact Information', props<AccountContactBrand>());
 export const LEAD_LOADED_CONTACT_INFORMATION = createAction('[Checkout Component] Lead Loaded Success Contact Information',   props<Lead>());
 export const LOAD_LEAD_OPPORTUNITY_CONTACT_INFORMATION = createAction('[Checkout Component] Load Lead Opportunity Contact Information', props<AccountContactBrand>());
 export const LEAD_LOADED_OPPORTUNITY_CONTACT_INFORMATION = createAction('[Checkout Component] Lead Opportunity Loaded Success Contact Information',   props<Lead>());

 /**
  * Opportunity Actions
  */

 export const LOAD_OPPORTUNITY = createAction('[Opportunity] Load Opportuity', props<Opportunity>());

 /**
  * Vehicle Actions
  */

  export const SAVE_VEHICLE = createAction('[Vehicle Component] Save Vehicle', props<Vehicle>());
  export const SAVE_VEHICLE_IMAGE = createAction('[Vehicle Component] Save Vehicle Image', props<Vehicle>());
  export const SAVE_VEHICLE_IMAGE_SUCCESS = createAction('[Vehicle Component] Save Vehicle Image Success', props<VehicleImage>());
  export const UPDATE_ODOMETER = createAction('[Vehicle Component] Update Odometer', props<Vehicle>());
  export const UPDATE_VEHICLE_VIN = createAction('[Vehicle Component] Update Vehicle By VIN', props<Vehicle>());
  export const UPDATE_VEHICLE_YMMT = createAction('[Vehicle Component] Update Vehicle By YMMT', props<Vehicle>())
  export const UPDATE_VIN = createAction('[Verify Vehicle Component] Update VIN', props<Vehicle>())
  export const REHYDRATE_VEHICLE = createAction('[Rehydrate] Vehicle', props<Vehicle>());
  export const VERIFY_VIN = createAction('[Modal Component] Verify VIN', props<Vin>())
  export const VERIFY_VIN_RATES = createAction('[Modal Component] Verify VIN Rates Happy Path', props<RatesRequest>())
  export const VERIFY_VIN_RATES_EDIT = createAction('[Modal Component] Verify VIN Rates Edit Vehicle', props<RatesRequest>())
  export const VERIFY_VIN_SUCCESS = createAction('[Modal Component] Verify VIN Success')
  export const VERIFY_VIN_FAILED = createAction('[Modal Component] Verify VIN Failed')
  export const VERIFY_VIN_RESET = createAction('[Modal Component] Verify VIN Reset')

  /**
 * Vehicle And Customer Actions
 */

export const VERIFY_VEHICLE_AND_CUSTOMER = createAction('[Verify] Verify Customer and Vehicle', props<VerifyCustVehReq>())
export const VERIFY_VEHICLE_AND_CUSTOMER_SUCCESS = createAction('[Modal Component] Verify Customer and Vehicle Success', props<VerifyVehCustResponse>())
export const VERIFY_VEHICLE_AND_CUSTOMER_FAILED = createAction('[Modal Component] Verify Customer and Vehicle Failed')
export const VERIFY_VEHICLE_AND_CUSTOMER_RESET = createAction('[Modal Component] Verify Customer and Vehicle Reset')
export const VERIFY_VEHICLE_AND_CUSTOMER_CONTACT_INFORMATION = createAction('[Verify] Verify Customer and Vehicle Contract Information', props<VerifyCustVehReq>())
export const VERIFY_VEHICLE_AND_CUSTOMER_SUCCESS_CONTACT_INFORMATION = createAction('[Modal Component] Verify Customer and Vehicle Success Contract Information', props<VerifyVehCustResponse>())
export const VERIFY_VEHICLE_AND_CUSTOMER_FAILED_CONTACT_INFORMATION = createAction('[Modal Component] Verify Customer and Vehicle Failed Contract Information')
export const VERIFY_VEHICLE_AND_CUSTOMER_RESET_CONTACT_INFORMATION = createAction('[Modal Component] Verify Customer and Vehicle Reset Contract Information')


export const VERIFY_VEHICLE_VIN_INFORMATION = createAction('[Modal Component] Verify Vin Information', props<any>())
export const VERIFY_VEHICLE_VIN_INFORMATION_SUCCESS = createAction('[Modal Component] Verify Vin Information Success', props<any>())
export const VERIFY_VEHICLE_VIN_INFORMATION_FAILED = createAction('[Modal Component] Verify Vin Information Failed')

/**
 * Rate Actions
 */

export const LOAD_RATES = createAction('[Product Plans Component] Load Rates', props<RatesRequest>());
export const LOAD_RATES_HAPPY = createAction('[Happy Path] Load Rates', props<RatesRequest>())
export const RATES_LOADED = createAction('[Rates API] Rates Loaded Success', props<RatesResponse>());
export const RATES_LOADED_FAILED = createAction('[Rates API] Rates Failed', props<RatesResponse>())
export const REHYDRATE_RATES = createAction('[Rehydrate] Rates', props<RatesResponse>())
export const RESET_RATES = createAction('[Rates] Reset Rates')

/**
* Cart Actions
*/

export const SAVE_CART = createAction('[Shopping Cart Component] Save Cart', props<Cart>());
export const CART_DB = createAction('[Cart API] Cart Submitted to DB', props<Cart>());
export const CART_DB_SUCCESS = createAction('[Cart API] Cart Submitted Success', props<Cart>());
export const UPDATE_CART = createAction('[Shopping Cart Component] Update Cart', props<Cart>());
export const UPDATE_CART_SUCCESS = createAction('[Shopping Cart Component] Cart Updated Successfully', props<Cart>())
export const CLEAR_CART = createAction('[Shopping Cart Component] Delete Cart')
export const REHYDRATE_CART = createAction('[Rehydrate] Cart', props<Cart>())
export const UPDATE_CART_NIS = createAction('[Update Cart] Update NIS Cart', props<Cart>())
export const GET_CART = createAction('[Get Cart] Get Cart', props<Lead>())
export const GET_CART_SUCCESS = createAction('[Get Cart] Get Cart Success', props<Cart>())
export const PREP_CART_FOR_ORDER = createAction('[Checkout Component] Prep Cart For Order', props<CartOrderData>())

/**
 * Registration Actions
 */

export const SAVE_REGISTRATION_STATE = createAction('[Registration Component] Save Registration State', props<Registration>());
export const UPDATE_REGISTRATION = createAction('[Registration Component] Update Registration', props<Registration>());
export const REHYDRATE_REGISTRATION = createAction('[Rehydrate] Registration', props<Registration>())
export const CLEAR_REGISTRATION = createAction('[Registration Component] Clear Registration')

/**
* VIN Decode Actions
*/

export const DECODE_VIN_PRODUCT_HOME = createAction('[Vehicle Component] Decode VIN', props<Vin>());
export const LOAD_DECODED_VIN = createAction('[VIN Decode API] VIN Decoded Success', props<VinDecodeData>());
export const DECODE_VIN_VERIFY_VIN = createAction('[Verify Vin Component] Decode VIN', props<Vin>());
export const REHYDRATE_VIN = createAction('[Rehydrate] VIN', props<VinDecodeData>())
export const VIN_DECODE_SUCCESS = createAction('[Vin Decode State] True')
export const VIN_DECODE_FAILED = createAction('[Vin Decode State] False')

/**
 * Email Actions
 */

export const SAVE_EMAIL = createAction('[Lead Component] Save Email', props<EmailInfo>());
export const UPDATE_EMAIL = createAction('[Checkout Component] Update Email', props<EmailInfo>());
export const REHYDRATE_EMAIL = createAction('[Rehydrate] Email', props<EmailInfo>())


/**
* Billing Actions
*/

export const SAVE_BILLING = createAction('[Checkout Component] Save Billing', props<BillingInfo>());

/**
* Branding Actions
*/

export const LOAD_BRAND_INFO = createAction('[Branding] Load Brand Info', props<{url: String}>());
export const LOAD_BRAND_INFO_SUCCESS = createAction('[Branding] Load Brand Info Success', props<BrandingInfo>());
export const LOAD_BRAND_INFO_FAILED = createAction('[Branding] Load Brand Info Failed');

/**
 * Plan Actions
 */

export const SAVE_PLAN = createAction('[Product Plans Component] Save Plan', props<Plan>());
export const REHYDRATE_PLAN = createAction('[Rehydrate] Plan', props<Plan>())

/**
 * Order Actions
 */
export const SUBMIT_ORDER_ACCOUNT_FLOW = createAction('[Checkout Component] Submit Order - Account Flow', props<OrderAccountFlow>());
export const ORDER_SUBMITTED = createAction('[Order API] Order Submitted');
export const SUBMIT_ORDER_GUEST_FLOW = createAction('[Checkout Component] Submit Order - Guest Flow', props<OrderGuestFlow>())
export const SUBMIT_ORDER_SUCCESS = createAction('[Checkout Component] Submit Order Success')
export const SUBMIT_ORDER_FAILED = createAction('[Checkout Component] Submit Order Failed')
export const SHOW_ORDER_STATE_INLINE_LOADER = createAction('[Checkout Component] Show Order State Inline Loader')
export const HIDE_ORDER_STATE_INLINE_LOADER = createAction('[Checkout Component] Hide Order State Inline Lodaer')
export const SUBMIT_ORDER_CHANGE_VEHICLE_FLOW = createAction('[Checkout Component] Submit Order - Change Vehicle Flow', props<OrderChangeVehicleFlow>())
export const SUBMIT_ORDER_CHANGE_ACCOUNT_FLOW = createAction('[Checkout Component] Submit Order - Change Account Flow', props<OrderChangeAccountFlow>())
export const NO_GUEST_ALLOWED = createAction('[Checkout Component] No Guest Allowed')
export const NOT_GUEST = createAction('[Checkout Component] Not Guest')


/**
 * AVS Actions
 */

export const SUBMIT_FOR_AVS = createAction('[Registration Component] AVS', props<AVSRequest>());
export const AVS_SUBMITTED = createAction('[AVS API] AVS Submitted', props<AVSResponse>());
export const REHYDRATE_AVS = createAction('[Rehydrate] AVS', props<AVSResponse>())

/**
 * State/Provinces Actions
 */

export const LOAD_STATES = createAction('[States] Load States/Provinces');
export const STATES_LOADED = createAction('[States API] States/Provinces Loaded', props<State>());

/**
 * Tax Actions
 */

export const SUBMIT_FOR_TAX = createAction('[Registration Component] Tax', props<TaxRequest>());
export const TAX_SUBMITTED = createAction('[Tax API] Tax Submitted', props<TaxResponse>());
export const UPDATE_TAX = createAction('[Shopping Cart Component] Update Tax', props<TaxRequest>());
export const TAX_UPDATED = createAction('[Tax API] Tax Updated', props<TaxResponse>());
export const REHYDRATE_TAX = createAction('[Rehydrate] Tax', props<TaxResponse>())
export const SUBMIT_FOR_TAX_MP = createAction('[Registration Component] Tax Monthly Payments', props<TaxRequest>());
export const SUBMIT_FOR_TAX_DP = createAction('[Registration Component] Tax Down Payment', props<TaxRequest>());
export const TAX_SUBMITTED_MP = createAction('[Tax API] Tax Monthly Payments Submitted', props<TaxResponse>());
export const UPDATE_TAX_MP = createAction('[Shopping Cart Component] Update Tax Monthly Payments', props<TaxRequest>());
export const TAX_UPDATED_MP = createAction('[Tax API] Tax Monthly Payments Updated', props<TaxResponse>());
export const REHYDRATE_TAX_MP = createAction('[Rehydrate] Tax Monthly Payments', props<TaxResponse>())
export const TAX_SUBMITTED_DP = createAction('[Tax API] Tax Down Payment Submitted', props<TaxResponse>());
export const UPDATE_TAX_DP = createAction('[Shopping Cart Component] Update Tax Down Payment', props<TaxRequest>());
export const TAX_UPDATED_DP = createAction('[Tax API] Tax Down Payment Updated', props<TaxResponse>());
export const REHYDRATE_TAX_DP = createAction('[Rehydrate] Tax Down Payment', props<TaxResponse>())



/**
 * Account Actions
 */

export const LOAD_ACCOUNT = createAction('[My Account Component] Load Account', props<AccountID>());
export const ACCOUNT_LOADED = createAction('[Account API] Account Loaded', props<AccountResponse>());
export const LOAD_PAYLINK = createAction('[My Account Component] Load PayLink', props<AccountID>());
export const PAYLINK_LOADED = createAction('[Paylink API] PayLink Loaded', props<PaylinkResponse>())
export const ACCOUNT_UPDATED = createAction('[Account API] Account Updated', props<AccountResponse>())
export const NO_ACOOUNT_FOUND = createAction('[My Account Component] No Account Found')
export const UPDATE_BILLING_ADDRESS = createAction('[My Account Component] Update Billing Address', props<UpdateBilling>())
export const UPDATE_PAYMENT_INFORMATION = createAction('[My Account Component] Update Payment Information', props<UpdateBillingInformation>())
export const BILLING_ADDRESS_UPDATED = createAction('[My Account Component] Billing Address Updated')
export const PAYMENT_INFORMATION_UPDATED = createAction('[My Account Component] Payment Information Updated')
export const LOAD_PAYMENT_HISTORY = createAction('[My Account Component] Load Payment History', props<PaymentHistoryObject>())
export const REHYDRATE_PAYLINK = createAction('[Rehydrate] Paylink', props<PaylinkResponse>())
export const REHYDRATE_PAYMENT_HISTORY = createAction('[Rehydrate] Payment History', props<PaymentHistoryObject>())
export const REHYDRATE_MYACCOUNT = createAction('[Rehydrate] My Accoubt', props<AccountResponse>())
export const RESET_MYACCOUNT = createAction('[My Account] Reset My Account')
export const GET_ACCOUNT_SFID = createAction('[Account] Get Account SFID', props<AccountEmail>())
export const GET_ACCOUNT_SFID_SUCCESS = createAction('[Account] Get Account SFID Success', props<AccountID>())
export const GET_ACCOUNT_SFID_FAILED = createAction('[Account] Get Account SFID Failed')

/**
 * Inline Loader Actions
 */

export const SHOW_INLINE_LOADER = createAction('[Inline Loader] Show');
export const HIDE_INLINE_LOADER = createAction('[Inline Loader] Hide')
export const SHOW_INLINE_LOADER_MY_ACCOUNT_CONTACT = createAction('[Inline Loader - My Account Contact] Show')
export const HIDE_INLINE_LOADER_MY_ACCOUNT_CONTACT = createAction('[Inline Loader - My Account Contact] Hide')
export const SHOW_INLINE_LOADER_MY_ACCOUNT_BILLING = createAction('[Inline Loader - My Account Billing] Show')
export const HIDE_INLINE_LOADER_MY_ACCOUNT_BILLING = createAction('[Inline Loader - My Account Billing] Hide')
export const SHOW_INLINE_LOADER_MY_ACCOUNT_PAYMENT = createAction('[Inline Loader - My Account Payment] Show')
export const HIDE_INLINE_LOADER_MY_ACCOUNT_PAYMENT = createAction('[Inline Loader - My Account Payment] Hide')
export const SHOW_INLINE_LOADER_MY_ACCOUNT_BILLING_INFORMATION = createAction('[Inline Loader - My Account Billing Information] Show')
export const HIDE_INLINE_LOADER_MY_ACCOUNT_BILLING_INFORMATION = createAction('[Inline Loader - My Account Billing Information] Hide')


/**
 * Token Actions
 */

export const ADD_TOKEN = createAction('[Session] Add Token', props<Token>())
export const REMOVE_TOKEN = createAction('[Session] Remove Token')

/**
 * Session Actions
 */
export const RESET_SESSION = createAction('[Landing Page] Reset Session')
export const SESSION_ENDED = createAction('[Modal Timeout] Session Ended')

/**
 * Verify Account/Code Actions
 */


 export const VERIFY_ACCOUNT = createAction('[Verify Component] Verify Account', props<VerifyAccountRequest>())
 export const INVALID_ACCOUNT = createAction('[Verify Component] Invalid Account')
 export const ACCOUNT_VERIFIED = createAction('[Verify API] Account Verified', props<VerifyAccountResponse>())
 export const VERIFY_CODE = createAction('[Verify Component] Verify Code', props<VerifyCodeRequest>())
 export const VERIFY_CODE_SUCCESS = createAction('[Verify Component] Verify Code Success')
 export const VERIFY_CODE_FAILED = createAction('[Verify Component] Verify Code Failed')
 export const SAVE_VERIFICATION_CODE = createAction('[Verify API] Save Code', props<VerifyCodeResponse>())
 export const REMOVE_VERIFICATION_CODE = createAction('[My Account Component] Clear Code')
 export const REHYDRATE_VERIFIED_ACCOUNT = createAction('[Rehydrate] Verified Account', props<VerifyAccountResponse>())
 export const RESET_VERIFIED_ACCOUNT = createAction('[Verify Component] Reset Account')
 export const VERIFY_ACCOUNT_OKTA_FLOW = createAction('[Verify Account Okta Flow]', props<VerifyAccountOktaFlowRequest>())
 export const VERIFY_ACCOUNT_OKTA_FLOW_SUCCESS = createAction('[Verify Account Okta Flow Success]', props<VerifyAccountOktaFlowResponse>())
 export const VERIFY_ACCOUNT_OKTA_FLOW_FAILED = createAction('[Verify Account Okta Flow Failed]')

/**
 * Product Home Modal Actions
 */

export const SUCCESS = createAction('[Modal Component] Success')
export const ERROR = createAction('[Modal Component] Error')
export const CLEAR = createAction('[Modal Componenet] Clear')
export const EDIT_VEHICLE_FLOW_ON = createAction('[Modal Component] Edit Vehicle True')
export const EDIT_VEHICLE_FLOW_OFF = createAction('[Modal Component] Edit Vehicle False')
export const INVALID_STATE = createAction('[Modal Component] Invalid State')
export const PRODUCT_EXIST = createAction('[Modal Component] Product Exist')
export const SHOW_INLINE_LOADER_MODAL = createAction('[Modal Component] Show Inline Loader')
export const HIDE_INLINE_LOADER_MODAL = createAction('[Modal Component] Hide Inline Loader')

/**
 * App Flow State Actions
 */

export const HAPPY_PATH = createAction('[Modal Component] Happy Path')
export const NIS = createAction('[Modal Component] NIS')
export const EDIT = createAction('[Modal Component] Edit Vehicle')
export const EDIT_ACCOUNT = createAction('[Modal Component] Edit Account')

/**
 * URL Paramerter Actions
 */

export const SAVE_URL_PARAMS = createAction('[Product Home] Save URL Params', props<URLParameters>())

/**
 * PDF Actions
 */

export const GET_PDF = createAction('[My Account] Get PDF', props<OrderSummaryID>())
export const GET_PDF_SUCCESS = createAction('[My Account] Get PDF Success', props<PDFResponse>())
export const GET_PDF_FAILED = createAction('[My Account] Get PDF Failed', props<PDFResponse>())
export const DOWNLOAD_PDF = createAction('[My Account] Download PDF', props<OrderSummaryID>())
export const DOWNLOAD_PDF_SUCCESS = createAction('[My Account] Download PDF Success', props<PDFResponse>())
export const DOWNLOAD_PDF_FAILED = createAction('[My Account] Download PDF Failed', props<PDFResponse>())
export const STORE_PRISMIC_PDF = createAction('[Prismic PDF] Store Prismic PDF', props<PrismicPDF>())
export const REHYDRATE_PRIMSIC_PDF = createAction('[Prismic PDF] Rehydrate Prismic PDF', props<PrismicPDF>())
export const SET_DATE_AND_TIME = createAction('[[My Account] set download date and time PDF', props<OrderSummaryID>())
export const SET_DATE_AND_TIME_SUCESS = createAction('[My Account] set download date and time PDF Sucess')
export const SET_DATE_AND_TIME_FAILED = createAction('[My Account] set download date and time PDF Failed', props<PDFResponse>())
export const POST_PAYMENY_PLAN_AGREEMENT_PDF = createAction('[Checkout Component] Payment Plan Agreement', props<PaymentPlanAgreementPDF>() )
export const POST_PAYMENY_PLAN_AGREEMENT_PDF_SUCCESS = createAction('[Checkout Component] Payment Plan Agreement Success',props<PaymentPlanAgreementPDF>())
export const POST_PAYMENY_PLAN_AGREEMENT_PDF_FAILED = createAction('[Checkout Component] Payment Plan Agreement Failed',props<PaymentPlanAgreementPDF>())
export const SAVE_PAYMENT_PLAN_AGREEMENT_SIGNED_PDF = createAction('[Checkout Summary Component] Save  Payment Plan Agreement', props<PaymentPlanAgreementPDF>());
export const POST_SERVICE_AGREEMENT_PDF = createAction('[Checkout Component] Service Agreement', props<ServiceAgreement>() )
export const POST_SERVICE_AGREEMENT_PDF_SUCCESS = createAction('[Checkout Component] Service Agreement Success',props<ServiceAgreement>())
export const POST_SERVICE_AGREEMENT_PDF_FAILED = createAction('[Checkout Component] Service Agreement Failed',props<ServiceAgreement>())

export const GET_PAYMENT_PLAN_AGREEMENT_PDF = createAction('[My Account ] Payment Plan Agreement', props<OrderSummaryID>() )
export const GET_PAYMENT_PLAN_AGREEMENT_PDF_SUCCESS = createAction('[My Account ] Payment Plan Agreement Success',props<PDFResponse>())
export const GET_PAYMENT_PLAN_AGREEMENT_PDF_FAILED = createAction('[CMy Account ] Payment Plan Agreement Failed',props<PDFResponse>())
export const DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF = createAction('[My Account ] Download Payment Plan Agreement', props<OrderSummaryID>() )
export const DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF_SUCCESS = createAction('[My Account ] Download Payment Plan Agreement Success',props<PDFResponse>())
export const DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF_FAILED = createAction('[CMy Account ] Download Payment Plan Agreement Failed',props<PDFResponse>())





/**
 * AVS State Actions
 */

export const AVS_SUCCESS = createAction('[AVS] AVS Successful')
export const AVS_FAILED = createAction('[AVS] AVS Failed')
export const AVS_RESET = createAction('[AVS] AVS Reset')

/**
 * Slice-Rate Mapping Actions
 */

export const SAVE_SLICE_RATE_MAPPING = createAction('[Product Plans] Save Slice Rate Mapping', props<SliceRateMappingArray>())
export const REHYDRATE_SLICE_RATE_MAPPING = createAction('[Product Plans] Rehydrate Slice Rate Mapping', props<SliceRateMappingArray>())

/**
 * Verify Code State Actions
 */

export const CODE_INVALID = createAction('[Verify Code] Invalid Code', props<VerifyCodeResponse>())
export const RESET_INVALID_CODE_STATE = createAction('[Verify Code] Reset Invalid Code State')
export const CODE_VALID = createAction('[Verify Code] Valid Code')


/**
 * My Account Information State Actions
 */

export const CONTACT_UPDATE_STATE_ON = createAction('[My Account] Update Contact State On')
export const BILLING_UPDATE_STATE_ON = createAction('[My Account] Update Billing State On')
export const PAYMENT_UPDATE_STATE_ON = createAction('[My Account] Update Payment State On')
export const BILLING_UPDATE_STATE_OFF = createAction('[My Account] Update Billing State Off')
export const BILLING_INFORMATION_UPDATE_STATE = createAction('[My Account] Update Billing Information State')

/**
 * Preference Center Inline Loader Actions
 */

export const SHOW_PREFERENCE_CENTER_INLINE_LOADER = createAction('[Preference Center Component] Show Inline Loader')
export const HIDE_PREFERENCE_CENTER_INLINE_LOADER = createAction('[Preference Center Component] Hide Inline Loader')

/**
 * Preference Center Actions
 */

export const PREFERENCE_CENTER_NO_EMAIL_FOUND = createAction('[Preference Center Component] No Email Found')
export const PREFERENCE_CENTER_EMAIL_FOUND = createAction('[Preference Center Component] Email Found')

/**
 * Confirmation Actions
 */

export const LOAD_CONFIRMATION_NUMBER = createAction('[Confirmation Component] Load Confirmation Number', props<ConfirmationNumber>())

/**
 * Reference Number Action
 */

export const SAVE_REF_ID = createAction('[Reference ID] Save Reference ID', props<ReferenceID>())
export const RESET_REF_ID = createAction('[Reference ID] Reset Reference ID')

/**
 * Checkout Error State Actions
 */

export const CHECKOUT_ERROR_PAYMENT = createAction('[Checkout Component] Payment Error')
export const CHECKOUT_ERROR_RESET = createAction('[Checkout Component] Reset Checkout Error State')


/**
 * Checkout Error State Actions
 */

export const LOAD_PRISMIC = createAction('[Prismic Component] Load Prismic', props<prismicLandingPage>());
export const LOAD_PRISMIC_SUCCESS = createAction('[Prismic Component] Load Prismic Success', props<prismicContent>());
export const LOAD_PRISMIC_FAILED = createAction('[Prismic Component] Load Prismic Failed');

/**
 * Product Disclaimers/Tooltips Actions
 */

export const LOAD_PRODUCT_DISCLAIMERS = createAction('[Prismic Content] Load Product Disclaimers', props<Product>())
export const LOAD_PRODUCT_DISCLAIMERS_SUCCESS = createAction('[Prismic Content] Load Product Disclaimers Success', props<Product>())
export const LOAD_PRODUCT_DISCLAIMERS_FAILED = createAction('[Prismic Content] Load Product Disclaimers Failed')

/**
 * Token Refresh Actions
 * (Meant for 401 errors we are getting but do not have time to actually handle properly.)
 * (Eventually the API needs to handle token refreshes instead of the UI rigging it.)
 */

export const REFRESH_TOKEN_CART_TAX = createAction('[Cart Component] Refresh Token Cart 401 Unauthorized', props<Opportunity>())
export const REFRESH_TOKEN_CART_TAX_SUCCESS = createAction('[Cart Component] Refresh Token Cart 401 Unauthorized Success')
export const REFRESH_TOKEN_AVS_TAX = createAction('[Checkout Component] Refresh Token AVS 401 Unauthorized Success', props<AVSTokenRefresh>())
export const REFRESH_TOKEN_AVS_TAX_SUCCESS = createAction('[Checkout Component] Refresh Token AVS 401 Unauthorized Success')
export const REFRESH_TOKEN_PURCHASE_CONTRACT = createAction('[Checkout Component] Refresh Token Purchase Contract 401 Unauthorized', props<PurchaseContractTokenRefresh>())
export const REFRESH_TOKEN_PURCHASE_CONTRACT_SUCCESS = createAction('[Checkout Component] Refresh Token Purchase Contract 401 Unauthorized Success')

/**
 * Cookie Agreement Actions
 */

export const ACCEPT_COOKIES = createAction('[Cookies Acceptance Component] Cookies Accepted')
export const DECLINE_COOKIES = createAction('[Cookies Acceptance Component] Cookies Declined')

/**
 * Cart Delete Item Actions
 */

export const SAVE_CART_DELETE_ITEM_SKU_ID = createAction('[Cart Delete Modal Component] Save SKU ID', props<CartDeleteItem>())
export const REMOVE_CART_DELETE_ITEM_SKU_ID = createAction('[Cart Delete Modal Component] Remove SKU ID')

/**
 * Payment Option Actions
 */
export const SAVE_PAYMENT_OPTIONS_MAX = createAction('[Cart Component] Save Payment Options Max', props<PaymentOptionMax>())

/**
 * Single Product Flow, Chnage Product Actions
 */

export const SINGLE_FLOW_PRODUCT_CHANGED = createAction('[Product Tabset Component] Product Changed')
export const RESET_SINGLE_FLOW_PRODUCT_CHANGED = createAction('[Product Tabset Component] Reset Product Changed')

/**
 * Multi Product Flow, Change Payment Terms
 */

export const MULTI_PRODUCT_FLOW_PAYMENT_TERMS_CHANGED = createAction('[Product Tabset Component] Payment Terms Changed')
export const RESET_MULTI_PRODUCT_FLOW_PAYMENT_TERMS_CHANGED = createAction('[Product Tabset Component] Reset Payment Terms Changed')

/**
 * VIN Decode Actions
 */

export const DECODE_VIN_GET_RATES = createAction('[Product Home] Decode VIN and Get Rates', props<VINDecodeRequest>())

/**
 * Abandon Cart Actions
 */

export const ABANDON_CART_TRIGGER = createAction('[Abandon Cart Trigger]', props<AbandonCartTrigger>());
export const ABANDON_CART_TRIGGER_SUCCES = createAction('[Abandon Cart Trigger Success]')
export const GET_ABANDONED_CART = createAction('[Shopping Cart Component] Get Abandoned Cart', props<Opportunity>())
export const GET_ABANDONED_CART_SUCCESS = createAction('[Shopping Cart Component] Get Abandoned Cart Success')
export const GET_ABANDONED_CART_FAILED = createAction('[Shopping Cart Component] Get Abandoned Cart Failed')
export const SAVE_ABANDONED_CART = createAction('[Shopping Cart Component] Save Abandoned Cart')
export const SAVE_ABANDONED_CART_SUCCESS = createAction('[Shopping Cart Component] Save Abandoned Cart Success')
export const SAVE_ABANDONED_CART_FAILED = createAction('[Shopping Cart Component] Save Abandoned Cart Failed')


/**
 * Okta Actions
 */
export const CHECK_REGISTRATION_TOKEN = createAction('[Okta Registration Token]', props<OktaRegistrationToken>())
export const REGISTRATION_TOKEN_VALID = createAction('[Okta Registration Token Valid]')
export const REGISTRATION_TOKEN_INVALID = createAction('[Okta Registration Token Invalid]')
export const REGISTRATION_TOKEN_USED = createAction('[Okta Registration Token Used]')
export const SET_OKTA_PASSWORD = createAction('[Set Okta Password]', props<OktaSetPasswordRequest>())
export const OKTA_PASSWORD_SET = createAction('[Okta Password Set]')
export const OKTA_PASSWORD_SET_FAILD = createAction('[Okta Password Set Failed]')
export const CHANGE_OKTA_PASSWORD = createAction('[Change Okta Password]', props<OktaChangePasswordRequest>())
export const OKTA_PASSWORD_CHANGED = createAction('[Okta Password Changed]')
export const OKTA_PASSWORD_CHANGED_FAILD = createAction('[Okta Password Changed Failed]')
export const OKTA_PASSWORD_RESET = createAction('[Okta Password Reset]')
export const OKTA_VERIFY_ACCOUNT = createAction('[Okta Verify Account]', props<OktaVerifyAccountRequest>())
export const OKTA_VERIFY_ACCOUNT_SUCCESS = createAction('[Okta Verify Account Success]')
export const OKTA_VERIFY_ACCOUNT_FAILED = createAction('[Okta Verify Account Failed]')
export const OKTA_SIGN_OUT = createAction('[Okta Sign Out]', props<OktaUser>())
export const OKTA_SIGN_IN = createAction('[Okta Sign In]', props<OktaUser>())
export const SET_OKTA_USER = createAction('[Set Okta User]', props<OktaUser>())
export const RESET_OKTA_USER = createAction('[Reset Okta User]')
export const REGISTER_OKTA_USER = createAction('[Register Okta User]', props<OktaRegisterUserRequest>())
export const OKTA_USER_ALREADY_REGISTERED = createAction('[Okta User Already Registered]')
export const REGISTER_OKTA_USER_SUCCESS = createAction('[Register Okta User Success]')
export const REGISTER_OKTA_USER_FAILED = createAction('[Register Okta User Failed]')
