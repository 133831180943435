import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;


  constructor(
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {

        if(brandInfo){
          this.brandInfo = brandInfo;
          console.log("consolidated - GoogleMapsService brandInfo: ", brandInfo);
        }

      });
  }



  loadScriptTag(): void {

    if(this.brandInfo){
      let language = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language;

      if (language) {
        console.log("consolidated - GoogleMapsService language: ", language);

        // callback=initMap
        const googleMaps = document.createElement("script");
        googleMaps.setAttribute('async', 'true');
        googleMaps.setAttribute('defer','');
        googleMaps.setAttribute("type", "text/javascript");
        googleMaps.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=AIzaSyAd05MP2ZzuZjJE4Pw1iO2pJLc16nATFIA&libraries=places&language=" + language.split("-")[0]);
        document.documentElement.firstChild.appendChild(googleMaps);
      }
    }


  }
}
