import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Vehicle } from '../_models/vehicle';

import { environment } from '../../environments/environment';
import { Lead } from '../_models/lead';
import { BrandingInfo } from '../_models/brandingInfo';

@Component({
  selector: 'app-fresh-relevance-header',
  templateUrl: './fresh-relevance-header.component.html',
  styleUrls: ['./fresh-relevance-header.component.scss']
})
export class FreshRelevanceHeaderComponent implements OnInit {

  vehicleData$: Observable<Vehicle>;
  leadData$: Observable<Lead>;
  currentBrand: string;
  production: boolean;
  preferredLanguage: string;

  constructor(
    private store: Store<{
    vehicle: Vehicle;
    lead: Lead;
    brandInfo: BrandingInfo
  }>
  ) { }

  ngOnInit(): void {
    this.vehicleData$ = this.store.select((store) => store.vehicle);
    this.leadData$ = this.store.select((store) => store.lead);
    this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
     if(brandInfo){
      this.currentBrand = brandInfo.brand;
      this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
     }

    });

    this.production = environment.production;
  }
}
