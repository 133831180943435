<!-- Dev/Staging REV Header -->
<div *ngIf="currentBrand == 'REV' && !production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" data-k="crz1t0f" data-slid="hero-graphic-rev-test-dev" [attr.data-merge-state]="leadData.customer?.state" data-p="1"></div>
</div>

<!-- Dev/Staging Vroom Lead Parameters -->
<div *ngIf="currentBrand == 'Vroom' && !production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  data-slid="vroom-lead-parameters-dev" 
  data-p="1"></div>
</div>

<!-- Prod Vroom Lead Parameters -->
<div *ngIf="currentBrand == 'Vroom' && production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType"
  data-slid="vroom-lead-parameters-prod" 
  data-p="1"></div>
</div>

<!-- Dev/Staging NCESI Lead Parameters -->
<div *ngIf="currentBrand == 'NCESI' && !production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-language]="preferredLanguage" 
  data-slid="ncesi-lead-parameters-dev" 
  data-p="1"></div>
</div>

<!-- Prod NCESI Lead Parameters -->
<div *ngIf="currentBrand == 'NCESI' && production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-language]="preferredLanguage" 
  data-slid="ncesi-lead-parameters-prod" 
  data-p="1"></div>
</div>

<!-- Dev/Staging GM Lead Parameters -->
<div *ngIf="(currentBrand == 'Chevrolet' || currentBrand == 'GMC' || currentBrand == 'Buick' || currentBrand == 'Cadillac') && !production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-merge-brand]="currentBrand"
  data-slid="gm-lead-parameters-dev" 
  data-p="1"></div>
</div>

<!-- Prod GM Lead Parameters -->
<div *ngIf="(currentBrand == 'Chevrolet' || currentBrand == 'GMC' || currentBrand == 'Buick' || currentBrand == 'Cadillac') && production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model"
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-merge-brand]="currentBrand"
  data-slid="gm-lead-parameters-prod" 
  data-p="1"></div>
</div>

<!-- Dev/Staging Schomp Lead Parameters -->
<div *ngIf="currentBrand == 'Schomp' && !production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model" 
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-merge-dealername]="leadData.dealerName" 
  data-slid="schomp-lead-parameters-dev" 
  data-p="1"></div>
</div>

<!-- Prod Schomp Lead Parameters -->
<div *ngIf="currentBrand == 'Schomp' && production">
  <div *ngIf="(leadData$ | async) as leadData" class="tmspslot" style="width: 100%; height: 0;" 
  data-k="crz1t0f" 
  [attr.data-merge-mileage]="leadData.vehicle?.mileage" 
  [attr.data-merge-deliverydate]="leadData.vehicle?.deliveryDate" 
  [attr.data-merge-mfrwarrantydate]="leadData.vehicle?.mfrWarrantyDate" 
  [attr.data-merge-inservicedate]="leadData.vehicle?.inserviceDate" 
  [attr.data-merge-state]="leadData.customer?.state" 
  [attr.data-merge-make]="leadData.vehicle?.make" 
  [attr.data-merge-model]="leadData.vehicle?.model"
  [attr.data-merge-vehicletype]="leadData.vehicle?.vehicleType" 
  [attr.data-merge-dealername]="leadData.dealerName" 
  data-slid="schomp-lead-parameters-prod" 
  data-p="1"></div>
</div>