import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalSearchService } from '../_services/global-search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchData = [];
  searchParam: string;

  constructor(private route: ActivatedRoute, private globalSearch: GlobalSearchService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {


      const urlParam = params.get('searchParam');
      // console.log("urlParam change: ", urlParam);

      if (urlParam) {
        this.searchParam = urlParam;
        this.emitNewSearch(this.searchParam);
      } else {
        this.searchParam = '';
      }
    });
  }

  emitNewSearch(searchParam) {
    this.globalSearch.searchDataObservable(searchParam).subscribe(result => {
      this.searchData = result;
      this.searchParam = searchParam;

    });
  }
}
