import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Lead, LeadResponse } from '../_models/lead';
import { ShoppingCartResponse, GetShoppingCart, GetShoppingCartLineItems, ShoppingCart, Cart } from '../_models/cart';
import { environment } from '../../environments/environment'

import { GatewayService } from './gateway.service';
import { LoggingService } from './logging.service';
import { Store } from '@ngrx/store';
import { BrandingInfo } from '../_models/brandingInfo';

declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  /**
   * Prospect Service Methods
   *  1. Get Contact
   *  2. Update Contact
   *  3. Get Shopping Cart
   *  4. Add Shopping Cart
   *  5. Update Shopping Cart
   *  6. Delete Shopping Cart
   */

   brandInfo: BrandingInfo;
   brandInfoSub: Subscription;

  constructor(
    private httpClient : HttpClient,
    private gatewayService: GatewayService,
    private loggingService: LoggingService,
    private store: Store<{
      brandInfo: BrandingInfo
    }>
) {

  this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
    this.brandInfo = brandInfo;
  });

}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }


  get_accountContact(object): Observable<Lead> {
    console.log('get_accountContact: ', object);
    return this.httpClient.get<LeadResponse>(this.gatewayService.baseUrl + 'dtc-prospect-srvc/accountcontact/' + encodeURI(object.payload.prid) + '?brandName=' + encodeURI(this.brandInfo.brand))
      .pipe(
        map((result => result.data)),
        retry(1),
        catchError(this.loggingService.handleError)
      )
  }

  update_accountContact(object): Observable<any> {
    console.log('object', object)
    return this.httpClient.post<any>(this.gatewayService.baseUrl + 'dtc-prospect-srvc/accountcontact/' + object.accountContactSfId, {
      address1: object.address1,
      address2: object.address2,
      city: object.city,
      state: object.state,
      zipCode: object.zipCode,
      country: object.country,
      emailId: object.emailId,
      firstName: object.firstName,
      lastName: object.lastName,
      phoneNumber1: object.phoneNumber1,
      phoneNumber2: object.phoneNumber2
    })
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }

  get_cart(cartId: Lead): Observable<ShoppingCartResponse>{
    return this.httpClient.get<ShoppingCartResponse>(this.gatewayService.baseUrl + 'dtc-prospect-srvc/shoppingCart', {
        params: {
          shoppingCartExternalId: cartId.shoppingCartExternalId
        }
      }
    )
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }

  submit_cart(cartObject: Cart): Observable<ShoppingCart>{
    console.log(cartObject)
    return this.httpClient.post<ShoppingCart>(this.gatewayService.baseUrl + 'dtc-prospect-srvc/shoppingCart', {
      accountExternalId: cartObject.shoppingCartData.accountExternalId,
      accountSfId: cartObject.shoppingCartData.accountSfId,
      brandExternalId: cartObject.shoppingCartData.brandExternalId,
      brandSfId: cartObject.shoppingCartData.brandSfId,
      contactExternalId: cartObject.shoppingCartData.contactExternalId,
      contactSfId: cartObject.shoppingCartData.contactSfId,
      lineItems: cartObject.shoppingCartData.lineItems,
      shoppingCartReferenceNumber1: cartObject.shoppingCartData.shoppingCartReferenceNumber1,
      shoppingCartReferenceNumber2: cartObject.shoppingCartData.shoppingCartReferenceNumber2,
      shoppingCartReferenceNumber3: cartObject.shoppingCartData.shoppingCartReferenceNumber3,
      shoppingCartReferenceNumber4: cartObject.shoppingCartData.shoppingCartReferenceNumber4,
      vehicleExternalId: cartObject.shoppingCartData.vehicleExternalId,
      vehicleSfId: cartObject.shoppingCartData.vehicleSfId
    })
  }

  update_cart(cartObject: Cart): Observable<ShoppingCart>{
    console.log(cartObject)


    return this.httpClient.post<ShoppingCart>(this.gatewayService.baseUrl + 'dtc-prospect-srvc/shoppingCart/' + cartObject.shoppingCartId, {
      accountExternalId: cartObject.shoppingCartData.accountExternalId,
      accountSfId: cartObject.shoppingCartData.accountSfId,
      brandExternalId: cartObject.shoppingCartData.brandExternalId,
      brandSfId: cartObject.shoppingCartData.brandSfId,
      contactExternalId: cartObject.shoppingCartData.contactExternalId,
      contactSfId: cartObject.shoppingCartData.contactSfId,
      lineItems: cartObject.shoppingCartData.lineItems,
      shoppingCartReferenceNumber1: cartObject.shoppingCartData.shoppingCartReferenceNumber1,
      shoppingCartReferenceNumber2: cartObject.shoppingCartData.shoppingCartReferenceNumber2,
      shoppingCartReferenceNumber3: cartObject.shoppingCartData.shoppingCartReferenceNumber3,
      shoppingCartReferenceNumber4: cartObject.shoppingCartData.shoppingCartReferenceNumber4,
      vehicleExternalId: cartObject.shoppingCartData.vehicleExternalId,
      vehicleSfId: cartObject.shoppingCartData.vehicleSfId
    })
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }

  get_opportunity(object): Observable<Lead>{
    console.log("get_opportunity", object, this.gatewayService.baseUrl)
    let gateway = this.gatewayService.getBaseUrl();
    return this.httpClient.get<LeadResponse>( gateway + 'dtc-prospect-srvc/accountcontact/opportunity/' + encodeURI(object.payload.oid) + '?brandName=' + encodeURI(this.brandInfo.brand))
    .pipe(
      map((result => result.data)),
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }

  get_opportunity_token_refresh(object): Observable<Lead>{
    let gateway = this.gatewayService.getBaseUrl();

    console.log("get_opportunity_token_refresh", object, this.gatewayService.baseUrl)
    return this.httpClient.get<LeadResponse>(gateway + 'dtc-prospect-srvc/accountcontact/opportunity/' + encodeURI(object.opportunityId) + '?brandName=' + encodeURI(this.brandInfo.brand))
    .pipe(
      map((result => result.data)),
      retry(1),
      catchError(this.loggingService.handleError)
    )
  }


}
