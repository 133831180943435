import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { account, guest, edit, editAccount } from '../_actions/app-flow-state.actions';


const _appFlowStateReducer = createReducer(initialState,
    on(account, (state, payload) => {
        sessionStorage.setItem('appFlowState', 'account')
        return 'account';
    }),
    on(guest, (state, payload) => {
        sessionStorage.setItem('appFlowState', 'guest')
        return 'guest';
    }),
    on(edit, (state, payload) => {
        sessionStorage.setItem('appFlowState', 'edit')
        return 'edit';
    }),
    on(editAccount, (state, payload) => {
        sessionStorage.setItem('appFlowState', 'editAccount')
        return 'editAccount';
    })
); 


export function appFlowStateReducer(state, action) {
    return _appFlowStateReducer(state, action);
}
