import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalSearchService } from 'src/app/_services/global-search.service';

import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
})
export class SearchboxComponent implements OnInit, OnDestroy, OnChanges {
  results: Observable<string[]>;
  typeAheadData: any = [];
  searchParam: string;
  @Output() emitSearch = new EventEmitter<string>();
  @Input() isNavSearch: boolean;
  @Input() urlSearchParam: string;
  searchForm: UntypedFormGroup;

  constructor(private globalSearch: GlobalSearchService, private router: Router) {
    this.searchForm = new UntypedFormGroup({
      searchWithAutocomplete: new UntypedFormControl(this.urlSearchParam),
    });
  }

  selectEvent(item) {
    console.log('You have selected ' + item.name);
  }

  filter(value: string): string[] {
    // Return if undefined
    if (!value) {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.typeAheadData.filter((item: any) => item.toLowerCase().includes(filterValue));
  }

  onChangeSearch() {
    this.performSearch(this.searchForm.controls.searchWithAutocomplete.value);
  }

  performSearch(searchParam) {
    if (this.isNavSearch) {
      this.router.navigate(['/search', { searchParam: searchParam }]);
      // this.emitSearch.emit(this.searchForm.controls.searchWithAutocomplete.value);
    } else {
      this.emitSearch.emit(searchParam);
    }

  }

  get searchWithAutocomplete() {
    return this.searchForm ? this.searchForm.controls.searchWithAutocomplete : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.urlSearchParam) {
      this.searchWithAutocomplete.setValue(changes.urlSearchParam.currentValue);
    }
  }

  ngOnInit() {
    this.globalSearch.typeAheadObservable().subscribe(data => {
      // console.log('creating typeAheadArray on searchbox: ', data);
      this.typeAheadData = data;
    });

    // this.searchForm = new FormGroup({
    //   searchWithAutocomplete: new FormControl(this.urlSearchParam),
    // });

    this.results = this.searchForm.controls.searchWithAutocomplete.valueChanges.pipe(
      startWith(this.urlSearchParam),
      map((value: string) => this.filter(value))
    );
  }


  selectedAutocomplete($event) {
      this.performSearch($event.text);
  }

  ngOnDestroy() {}
}
