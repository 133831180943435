import { Injectable } from '@angular/core';

import { RatesResponse } from '../_models/rates';


@Injectable({
  providedIn: 'root'
})
export class ProductTabsetService {

  constructor() { }

  getTabsetContent(prismicData) {
    let tabsetContent;

    // Get Tabset Content
    let tabsetContentArray = prismicData.content.filter((contentBlock) => {
      return contentBlock.type === "products_tabset";
    });

    if (tabsetContentArray.length > 0) {
      tabsetContent = tabsetContentArray[0];
    }

    return tabsetContent;
  }

  mapCoveredParts(prismicPlan) {
    let coveredParts = [];

    // Add on coveredParts to newObject
    for (let l = 0; l < prismicPlan.plan.components.length; l++) {
      coveredParts.push(prismicPlan.plan.components[l].component_title[0].text);
    }

    return coveredParts;
  }

  calcPaymentTerms(planTermSKUList, totalPrice, price) {
    let numberOfPayments;
    let payment;

    //Get payment info based on which rate returnd first.
    if (Number(planTermSKUList.planTermSKUName.split(" ")[0]) > 1) {
      numberOfPayments = "12";
      payment = Number((Number(totalPrice) - Number(price)) / 12)
        .toFixed(2)
        .toString();
    } else {
      numberOfPayments = "6";
      payment = Number((Number(totalPrice) - Number(price)) / 6)
        .toFixed(2)
        .toString();
    }

    return { numberOfPayments, payment };
  }

  mapCoverageOverview(prismicPlan) {
    let coverageOverviewArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "coverage_overview");

    console.log("coverageOverviewArray: ", coverageOverviewArray);
    let coverageBoxes = [];
    let coveragetext = "";

    if (coverageOverviewArray.length > 0) {
      coverageBoxes = coverageOverviewArray[0].fields.map((coverageOverviewItem) => {
        return {
          title: coverageOverviewItem?.coverageboxtitle[0].text,
          body: coverageOverviewItem?.coverageboxbody[0].text,
          banner: coverageOverviewItem?.coverageboxbanner ? coverageOverviewItem?.coverageboxbanner[0].text : "",
        };
      });
    } else {
      return {
        coverageText: "",
        coverageBoxes: [],
      };
    }

    coveragetext = coverageOverviewArray[0].primary.coveragetext[0].text;

    let coverageOverview = {
      coverageText: coveragetext,
      coverageBoxes: coverageBoxes,
    };
    console.log("coverageOverview: ", coverageOverview);
    return coverageOverview;
  }

  mapComponentCoverageHeader(prismicPlan) {
    let componentCoverageArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "componet_coverage");
    let coverageText = "";

    if (componentCoverageArray.length > 0) {
      console.log("coverageText: ", componentCoverageArray[0].primary.coverageheader);

      coverageText = componentCoverageArray[0].primary.coverageheader;
    }

    return coverageText;
  }

  getPriceForSurcharge(option) {
    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    for (let i = 0; i < _rates.data.productDetailsList[0].surcharge.surchargeOption.length; i++) {
      if (_rates.data.productDetailsList[0].surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes(option.toLowerCase())) {
        return Number(_rates.data.productDetailsList[0].surcharge.surchargeOption[i].surchargeAmount)
      }
    }
  }

  getSurchargeOptions(rates) {
    let surcharges = {
      greyMarket: false,
      commercialUse: false,
      liftKit: false,
      snowPlow: false

    }
    if (rates.surcharge) {
      for (let i = 0; i < rates.surcharge.surchargeOption.length; i++) {
        if (rates.surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes('Grey Market'.toLowerCase())) {
         surcharges.greyMarket = true
        } else if (rates.surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes('Light Duty'.toLowerCase())) {
         surcharges.commercialUse = true
        } else if (rates.surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes('Lift Kit'.toLowerCase())) {
         surcharges.liftKit = true
        } else if (rates.surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes('Plow'.toLowerCase())) {
         surcharges.snowPlow = true
        }
       }
    } else {
      return null
    }
  }

  generateTabsetPlan(ratingPlan, planIndex, prismicPlan, ratingProduct, samplePolicy) {

    if (ratingPlan.referenceNumber1 === prismicPlan.plan.reference_number) {
      let totalPrice = ratingPlan.planTermSKUList[0].planTermSKURate;
      let price = Number(ratingPlan.planTermSKUList[0].planTermSKURate) / 10;
      let { numberOfPayments, payment } = this.calcPaymentTerms(ratingPlan.planTermSKUList[0], totalPrice, price);
      let deductible = ratingPlan.deductible;
      // let surchargeOptions = ratingProduct.surcharge ? ratingProduct.surcharge.surchargeOption[0].surchargeAmount : null;
      let surchargeOptions = this.getSurchargeOptions(ratingProduct)

      let plan = {
        name: prismicPlan.plan.plan_title[0].text,
        active: false,
        header: "",
        pricing: {
          price: price,
          payment: payment, //handled
          totalPrice: totalPrice,
          priceHeader: "As low as",
          numberOfPayments: numberOfPayments, //handled
          deductible: deductible,
          commercialUse: surchargeOptions ? surchargeOptions.commercialUse : null,
          greyMarket: surchargeOptions ? surchargeOptions.greyMarket : null,
          snowPlow: surchargeOptions ? surchargeOptions.snowPlow : null,
          liftKit: surchargeOptions ? surchargeOptions.liftKit : null
        },
        product: {
          productName: ratingProduct.productName,
          productCode: ratingProduct.productCode,
        },
        planDetails: {
          planName: ratingPlan.planName,
          planNumber: ratingPlan.planNumber,
          referenceNumber1: ratingPlan.referenceNumber1,
          planCode: ratingPlan.planCode,
          deductible: ratingPlan.deductible,
          planIndex: planIndex,
        },
        planTerms: ratingPlan.planTermSKUList,
        componentCoverage: {
          coverageHeader: this.mapComponentCoverageHeader(prismicPlan),
          coveredParts: this.mapCoveredParts(prismicPlan),
        },
        coverageOverview: this.mapCoverageOverview(prismicPlan),
        samplePDF: samplePolicy,
        plan: {
          planName: ratingPlan.planName,
          referenceNumber: ratingPlan.referenceNumber1,
          productName: ratingProduct.productName,
          productCode: ratingProduct.productCode,
          planIndex: planIndex,
        },
        term: {
          termIndex: 0,
          price: price,
          payment: payment,
          totalPrice: totalPrice,
          priceHeader: "As low as",
          numberOfPayments: numberOfPayments,
        },
      };

      return plan;
    }else {
      // Reference Numbers does not match
      return false;
    }

  }


}
