import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, Observable, Subscription } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import * as ActionConfigurationManager from ".././_actions/config.actions";
import { PaymentService } from "../_services/payment-and-tax-service.service";
import { Action, Store } from "@ngrx/store";
import { taxSubmitted, taxUpdated, submitTaxMP, submitTaxDP, taxSubmittedDP, taxSubmittedMP } from "../_actions/tax.actions";
import { Cart, LineItems } from "../_models/cart";
import { hideInlineLoader } from "../_actions/inline-loader.actions";
import { cartSaved, updateCart, updateCartSuccess, updateNISCart } from "../_actions/cart.actions";
import { Injectable } from "@angular/core";
import { AVSResponse } from "../_models/avs";
import { TaxRequest, TaxResponse, TaxRequestItems } from "../_models/tax";
import { Registration } from "../_models/registration";
import { RatesResponse } from "../_models/rates";
import { Lead, LeadResponse } from "../_models/lead";
import { Vehicle } from "../_models/vehicle";

import { Plan } from "../_models/plan";
import { cartTaxTokenRefresh } from "../_actions/token-refresh.actions";
import { removeToken } from "../_actions/token.actions";
import { Opportunity } from "../_models/account";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable()
export class TaxEffects {
  customerCart: Cart = {
    estimatedTax: 0,
    commercialUse: false,
    liftKit: false,
    snowPlow: false,
    greyMarket: false,
    isDisappearing: false,
    deductible: 0,
    grandTotal: 0,
    monthlyPayment: 0,
    downPayment: 0,
    downPaymentWithTax: 0,
    numberOfMonthlyPayments: 0,
    plans: [
      {
        referenceNumber: "",
        productCode: "",
        productName: "",
        ratesProductName: "",
        addons: [],
        planName: "",
        planIndex: 0,
        termIndex: 0,
        planPrice: 0,
        skuId: "",
        planTerms: {
          yearsMileage: "",
          deductible: 0,
        },
        productSkuId: "",
        planCode: "",
        contractDealerCost: "",
        productFormNumber: "",
      },
    ],
    shoppingCartId: "",
    subtotal: 0,
    shoppingCartData: {
      accountExternalId: "",
      accountSfId: "",
      brandSfId: "",
      brandExternalId: "",
      contactExternalId: "",
      contactSfId: "",
      lineItems: [],
      shoppingCartReferenceNumber1: "",
      shoppingCartReferenceNumber2: "",
      shoppingCartReferenceNumber3: "",
      shoppingCartReferenceNumber4: "",
      status: "",
      vehicleExternalId: "",
      vehicleSfId: "",
    },
  };

  lineItem;

  taxRequest: TaxRequest = {
    opportunityId: "",
    city: "",
    country: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    postalCode: "",
    state: "",
    street1: "",
    vin: "",
    brandName: "",
    downPayment: 0,
    installments: 0,
    items: [],
  };

  taxRequestItems: TaxRequestItems = {
    unitPrice: 0,
    quantity: 0,
    productCode: "",
    productName: "",
    productSKU: "",
    productSfId: "",
    contractNumber: "",
  };

  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private actions$: Actions,
    private taxService: PaymentService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {

        if(!brandInfo){
          return;
        }

        console.log("consolidated - TaxEffects brandInfo:", brandInfo);

        this.brandInfo = brandInfo;

        this.lineItem = {
          currency: this.brandInfo && this.brandInfo.isoCode ? this.brandInfo.isoCode : "USD",
          itemDescription: "",
          itemInCartFrom: null,
          itemName: "",
          lineItemCostExcludingTax: 0,
          lineItemCostIncludingTax: 0,
          product2Sfid: "",
          product2ExternalId: "",
          productSkuExternalId: "",
          productSkuSfId: "",
          salesTax: 0,
          shoppingCartLineItemId: null,
          shoppingCartLineItemReferenceNumber1: "",
          shoppingCartLineItemReferenceNumber2: "",
          shoppingCartLineItemReferenceNumber3: "",
          shoppingCartLineItemReferenceNumber4: "",
          status: "NEW",
        };
      });
  }

  avsRequest: any;
  registration: Registration;

  public saveTax$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.SUBMIT_FOR_TAX),
      switchMap((action: any) =>
        this.taxService.tax(action).pipe(
          map((tax) => {
            console.log("Tax From Save Tax Total", tax);
            let cart: Cart = JSON.parse(sessionStorage.getItem("cart"));

            for (let i = 0; i < cart.plans.length; i++) {
              for (let j = 0; j < tax.data.contracts.length; j++) {
                if (cart.plans[i].ratesProductName === tax.data.contracts[j].contractNumber) {
                  cart.shoppingCartData.lineItems[i].lineItemCostIncludingTax = Number(tax.data.contracts[j].salesTaxDetails[0].taxableAmount) + Number(tax.data.contracts[j].salesTaxDetails[0].totalTaxAmount);
                  cart.shoppingCartData.lineItems[i].salesTax = tax.data.contracts[j].salesTaxDetails[0].totalTaxAmount;
                }
              }
            }

            if (cart.numberOfMonthlyPayments === 1) {
              cart.estimatedTax = Number(tax.data.totalTaxAmount);
              cart.grandTotal = Number(tax.data.grandTotalAmount);
              cart.monthlyPayment = tax.data.monthlyPayment;
              cart.subtotal = tax.data.totalTaxableAmount;

              this.store.dispatch(taxSubmitted(tax));
              this.store.dispatch(updateCartSuccess(cart));

              console.log("Cart After tax Call:", cart);
              return hideInlineLoader();
            } else {
              cart.estimatedTax = tax.data.totalTaxAmount;
              cart.grandTotal = tax.data.grandTotalAmount;
              cart.monthlyPayment = tax.data.monthlyPayment;
              cart.downPaymentWithTax = tax.data.totalToday;
              cart.subtotal = tax.data.totalTaxableAmount;

              if (this.brandInfo.frontLoadTaxes) {
                cart.downPaymentWithTax = Number(cart.monthlyPayment) + Number(cart.estimatedTax);
              }

              this.store.dispatch(taxSubmitted(tax));
              this.store.dispatch(updateCartSuccess(cart));
              console.log("Cart After tax Call:", cart);
              return hideInlineLoader();
            }
          }),
          catchError((error: string) => {
            console.error('Error from tax', error)

            const cart: Cart = JSON.parse(sessionStorage.getItem('cart'))

            if (error.includes('401 Unauthorized')) {
                let opp: Opportunity = JSON.parse(sessionStorage.getItem('opp'))
                this.store.dispatch(removeToken())
                this.store.dispatch(cartTaxTokenRefresh(opp))
            }
            if (error.includes('400')) {
                if (this.brandInfo.isoCode === 'USD') {
                    if (this.brandInfo.frontLoadTaxes) {
                      if (cart.numberOfMonthlyPayments === 1) {
                        cart.estimatedTax = cart.subtotal * .10
                        cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                        cart.monthlyPayment = 0
                        // cart.subtotal = cart.subtotal

                        this.store.dispatch(updateCartSuccess(cart))
                        this.store.dispatch(hideInlineLoader())
                      } else {
                        cart.estimatedTax = cart.subtotal * .10

                        cart.grandTotal =  Number(cart.subtotal) +  Number(cart.estimatedTax)
                        cart.monthlyPayment = Number(((cart.subtotal) / cart.numberOfMonthlyPayments).toFixed(2))
                        cart.downPaymentWithTax = cart.estimatedTax + cart.monthlyPayment
                        // cart.subtotal = tax.data.totalTaxableAmount


                        this.store.dispatch(updateCartSuccess(cart))
                        this.store.dispatch(hideInlineLoader())
                      }
                    } else {
                      if (cart.numberOfMonthlyPayments === 1) {
                        cart.estimatedTax = cart.subtotal * .10
                        cart.grandTotal =  Number(cart.subtotal) +  Number(cart.estimatedTax)
                        cart.monthlyPayment = 0
                        // cart.subtotal = cart.subtotal

                        this.store.dispatch(updateCartSuccess(cart))
                        this.store.dispatch(hideInlineLoader())
                      } else {
                        cart.estimatedTax = cart.subtotal * .10
                        cart.grandTotal =  Number(cart.subtotal) +  Number(cart.estimatedTax)
                        cart.monthlyPayment = Number(((cart.grandTotal - cart.downPayment) / cart.numberOfMonthlyPayments).toFixed(2))
                        cart.downPaymentWithTax = cart.downPayment
                        // cart.subtotal = tax.data.totalTaxableAmount


                        this.store.dispatch(updateCartSuccess(cart))
                        this.store.dispatch(hideInlineLoader())
                      }
                    }
                  } else {
                    if (cart.numberOfMonthlyPayments === 1) {
                      cart.estimatedTax = cart.subtotal * .15
                      cart.grandTotal =  Number(cart.subtotal) +  Number(cart.estimatedTax)
                      cart.monthlyPayment = 0
                      // cart.subtotal = cart.subtotal

                      this.store.dispatch(updateCartSuccess(cart))
                      this.store.dispatch(hideInlineLoader())
                    } else {
                      cart.estimatedTax = cart.subtotal * .15
                      cart.grandTotal =  Number(cart.subtotal) +  Number(cart.estimatedTax)
                      cart.monthlyPayment = Number(((cart.grandTotal - cart.downPayment) / cart.numberOfMonthlyPayments).toFixed(2))
                      cart.downPaymentWithTax = cart.downPayment
                      // this.customerCart.subtotal = tax.data.totalTaxableAmount


                      this.store.dispatch(updateCartSuccess(cart))
                      this.store.dispatch(hideInlineLoader())
                    }
                  }
            }
          return EMPTY;

          }),
        )
      )
    )
  );
}
