import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable({
  providedIn: "root",
})
export class FaviconLoaderService {
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;
          console.log("consolidated - FaviconLoaderService brandInfo: ", brandInfo);
        }
      });
  }

  loadScriptTag(brandInfo): void {
    // <link rel="icon" href="/favicon.ico">
    // <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
    // <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
    // <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">

    if (brandInfo) {
      const head = document.getElementsByTagName("head")[0];

      // Favicon Tag
      const iconFavicon = document.createElement("link");
      iconFavicon.rel = "icon";
      iconFavicon.href = `${this.brandInfo.icons.favicon}`;
      head.appendChild(iconFavicon);

      // iconAppleTouch Tag
      const iconAppleTouch = document.createElement("link");
      iconAppleTouch.rel = "apple-touch-icon";
      iconAppleTouch.href = `${this.brandInfo.icons.appleTouchIcon}`;
      head.appendChild(iconAppleTouch);

      const iconFav32 = document.createElement("link");
      iconFav32.rel = "icon";
      iconFav32.rel = "image/png";
      iconFav32.href = `${this.brandInfo.icons.favicon32x32}`;
      head.appendChild(iconFav32);

      const iconFav16 = document.createElement("link");
      iconFav16.rel = "icon";
      iconFav16.rel = "image/png";
      iconFav16.href = `${this.brandInfo.icons.favicon16x16}`;
      head.appendChild(iconFav16);

      console.log("loadScriptTag head: ", head);
    }
  }
}
