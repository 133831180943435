<div class="verify-vehicle" *ngIf="showVerify">
  <div *ngIf="!((appFlowState$ | async) === 'guest' || (appFlowState$ | async) === 'edit')">
    <div class="vehicle_mobile_section mb-3 mobile" *ngIf="vehicle$ | async as vehicle">
      <div class="view image-cropper image-border mr-3 d-flex align-items-center" [ngClass]="{'hide-slug': vehicle.image}">
        <img *ngIf="vehicle.image" [src]="vehicle.image" alt="{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}" />
      </div>
      <div class="vehicle_edit_btn d-flex justify-content-center align-items-center" (click)="editVehicle()">
        <mdb-icon fas icon="pencil-alt"></mdb-icon>
      </div>
    </div>
  </div>
  <div *ngIf="(appFlowState$ | async) === 'account' || (appFlowState$ | async) === 'editAccount'">
    <span role="alert" tabindex="0" *ngIf="currentBrand == 'NCESI'">
      <div class="text-center verify-vehicle-header" role="heading" aria-level="2" *ngIf="!(vehicle$ | async) as vehicle"><strong>{{ 'ASP_LANDING.VERIFY_VEHICLE' | translate }}</strong></div>
      <!-- <p class="text-center">Select your state and enter your mileage for pricing</p> -->
    </span>
    <span role="alert" tabindex="0" *ngIf="currentBrand != 'NCESI'">
      <div class="text-center verify-vehicle-header" role="heading" aria-level="2" *ngIf="!(vehicle$ | async) as vehicle"><strong [innerHTML]="titleText | safeHtml"></strong></div>
      <!-- <p class="text-center">Select your state and enter your mileage for pricing</p> -->
    </span>
    <div class="row">

      <!-- Desktop View-->

      <div class="desktop col-xl-12">
        <div class="d-flex justify-content-center align-items-center">
          <div class="vehicle_section mb-3" *ngIf="vehicle$ | async as vehicle">
            <div class="view image-cropper image-border mr-3 d-flex align-items-center" [ngClass]="{'hide-slug': vehicle.image}">
              <img *ngIf="vehicle.image" [src]="vehicle.image" alt="{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}" />
            </div>
            <div class="vehicle_edit_btn d-flex justify-content-center align-items-center" (click)="editVehicle()">
              <mdb-icon fas icon="pencil-alt"></mdb-icon>
            </div>
          </div>
          <div *ngIf="vehicle$ | async as vehicle" class="d-flex flex-column justify-content-center">
            <div tabindex="0" class="bold uppercase">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}</div>
            <div class="mb-3" role="region" tabindex="0">{{ 'ASP_LANDING.VIN' | translate }} {{ vehicleVin }}</div>
          </div>
        </div>
      </div>

      <!-- Mobile View-->

      <div class="col-xl-12 pr-4 pl-4 mobile">
        <div class="d-flex justify-content-center">
          <div *ngIf="vehicle$ | async as vehicle" class="d-flex flex-column justify-content-center">
            <div tabindex="0" class="bold uppercase text-center">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}</div>
            <div class="mb-3 text-center" role="region" tabindex="0">{{ 'ASP_LANDING.VIN' | translate }} {{ vehicleVin }}</div>
          </div>
        </div>
      </div>

      <div class="col-12 p-0" *ngIf="vehicle$ | async as vehicle">
        <div class="mt-2 mb-4">
          <div class="alert alert-warning verify-alert w-100" role="alert" *ngIf="(modalComponentState$ | async) === 'error'">
            <span *ngIf="currentBrand != 'NCESI'">
              {{ 'ERRORS.NO_ELIGIBLE_PRODUCTS' | translate }} {{ branding.cs_phone_number }}
            </span>
            <span *ngIf="currentBrand == 'NCESI'">
              {{ 'ERRORS.NEED_MORE_INFO' | translate }} {{ branding.cs_phone_number }}
            </span>
          </div>
          <div class="alert alert-warning verify-alert w-100" id="invalidStateMobile" role="alert" *ngIf="(modalComponentState$ | async) === 'invalidState'">
            {{ 'ERRORS.NO_PRODUCTS_STATE' | translate }} {{ branding.cs_phone_number }}
          </div>
          <div class="alert alert-warning verify-alert w-100" role="alert" *ngIf="(modalComponentState$ | async) === 'productExist'">{{ 'ERRORS.CONTRACT_EXISTS' | translate }} {{ branding.cs_phone_number }}</div>
        </div>
        <form class="text-center p-3" class="stateMileageForm OID-form" [formGroup]="modelFormValidation" role="form">
          <div class="md-form mt-3">
            <label class="no-touch-label" id="stateLabel" for="registeredState" [ngClass]="{ active: modelFormValidation.controls.registeredState.value != null }"> {{ 'ASP_LANDING.STATE' | translate }}</label>
            <!-- <mdb-select  role="listbox" aria-label="State of vehicle registration" (opened)="fixSelectAria($event)" tabindex="0" [options]="stateData" formControlName="registeredState" id="registeredState" (selected)="stateSelected()" mdbInput mdbValidate placeholder="Registered in... *" ></mdb-select> -->
            <select role="listbox" aria-labelledby="stateLabel" id="registeredState" class="form-control" formControlName="registeredState" (change)="stateSelected()" placeholder="Registered in... *">
              <option *ngFor="let state of stateData" value="{{ state.value }}">{{ state.label }}</option>
            </select>
            <!-- <ng-autocomplete
                  mdbInput
                  mdbValidate
                  [data]="stateData"
                  (selected)="stateSelected($event)"
                  [searchKeyword]="stateKeyword"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [(ngModel)]='leadState'
                  [ngModelOptions]="{standalone: true}"
                  class="form-control"
                  placeHolder="Registered in... *"
                  id="vehicleState">
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template> -->
          </div>
          <mdb-error role="alert" *ngIf="modelFormValidation.controls.registeredState.invalid && (modelFormValidation.controls.registeredState.dirty || modelFormValidation.controls.registeredState.touched)">*{{ 'ASP_LANDING.STATE_REQUIRED' | translate }}</mdb-error>
          <div class="md-form mt-3">
            <label id="odometerIdlabel" for="odometerId" class="no-touch-label">{{ 'ASP_LANDING.CURRENT_MILEAGE' | translate }} <app-tooltip *ngIf="odometerTooltip" message="{{odometerTooltip}}"></app-tooltip></label>
            <input data-public aria-labelledby="odometerIdlabel" mdbInput mdbValidate type="text" id="odometerId" formControlName="odometer" class="form-control" (keypress)="onlyNumberKey($event)" (focus)="removeCommas()" (change)="removeCommas()" (blur)="addCommas()" max="100000" />
            <div class="invalid-feedback" for="odometerId">{{ 'ASP_LANDING.ENTER_MILEAGE' | translate }}</div>
          </div>
          <mdb-error role="alert" *ngIf="modelFormValidation.controls.odometer.invalid && (modelFormValidation.controls.odometer.dirty || modelFormValidation.controls.odometer.touched)">*{{ 'ASP_LANDING.MILEAGE_REQUIRED' | translate }}</mdb-error>
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-center">
              <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn flat waves-light" mdbWavesEffect type="submit" [disabled]="inlineLoader$ | async" (click)="checkIfCriteriaChange()">
                <span *ngIf="currentBrand == 'NCESI'">
                  <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false" class="">{{ 'ASP_LANDING.VERIFY_VEHICLE_BUTTON' | translate }}</span>
                </span>
                <span *ngIf="currentBrand != 'NCESI'">
                  <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false" class="">{{buttonText}}</span>
                </span>
                <span *ngIf="inlineLoader$ | async">
                  <span class="spinner-border spinner-border-sm"></span>
                  {{ 'ASP_LANDING.LOADING' | translate }}
                </span>
              </button>
            </div>
            <div class="col-12 change-vehicle text-center">
              {{ 'ASP_LANDING.NOT_VEHICLE' | translate }}
              <a role="link" href="javascript:void(0)" class="blue-link uppercase" (click)="editVehicle()" (click)="view = '2'"> {{ 'ASP_LANDING.CHANGE_VEHICLE' | translate }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="(appFlowState$ | async) === 'guest' || (appFlowState$ | async) === 'edit'" class="vehicle-edit">
    <div class="col-12 text-center pb-3">
      <span role="alert" tabindex="0" *ngIf="currentBrand == 'NCESI'">
        <div class="text-center verify-vehicle-header" role="heading" aria-level="2" *ngIf="!(vehicle$ | async) as vehicle"><strong>{{ 'ASP_LANDING.VERIFY_VEHICLE' | translate }}</strong></div>
        <!-- <p class="text-center">Select your state and enter your mileage for pricing</p> -->
      </span>
      <span role="alert" tabindex="0" *ngIf="currentBrand != 'NCESI'">
        <div class="text-center verify-vehicle-header" role="heading" aria-level="2" *ngIf="!(vehicle$ | async) as vehicle"><strong [innerHTML]="titleText | safeHtml"></strong></div>
        <!-- <p class="text-center">Select your state and enter your mileage for pricing</p> -->
      </span>
      <label *ngIf="currentBrand == 'Vroom'" class="text-center mt-3 no-touch-label">
        {{warrantyEligibility}}</label>
    </div>
      <div>
        <form class="row" [formGroup]="editVehicleVINForm" class="no-OID-form">
          <div class="col-sm-12">
            <div class="alert alert-warning verify-alert w-100" role="alert" *ngIf="(modalComponentState$ | async) === 'error'">
              <span *ngIf="currentBrand != 'NCESI'">
                {{ 'ERRORS.NO_ELIGIBLE_PRODUCTS' | translate }} {{ branding.cs_phone_number }}
              </span>
              <span *ngIf="currentBrand == 'NCESI'">
                {{ 'ERRORS.NEED_MORE_INFO' | translate }} {{ branding.cs_phone_number }}.
              </span>
            </div>
            <div class="alert alert-warning verify-alert w-100" id="invalidStateDesktop" role="alert" *ngIf="(modalComponentState$ | async) === 'invalidState'">
              {{ 'ERRORS.NO_PRODUCTS_STATE' | translate }} {{ branding.cs_phone_number }}
            </div>
            <div class="alert alert-warning verify-alert w-100" role="alert" *ngIf="(modalComponentState$ | async) === 'productExist'">{{ 'ERRORS.CONTRACT_EXISTS' | translate }} {{ branding.cs_phone_number }}</div>
            <div class="alert alert-warning verify-alert w-100" role="alert" *ngIf="(verifyVin$ | async) === 'failed'">{{ 'ERRORS.NO_OFFER' | translate }}</div>
          </div>
          <div class="col-sm-12">
            <div class="md-form mt-3">
              <a role="link" tabindex="0" class="where-vin blue-link uppercase" (click)="launchVin()">{{ 'ASP_LANDING.WHERE_FIND_VIN' | translate }}</a>
              <label id="vinlabel" for="vin">{{ 'ASP_LANDING.VIN' | translate }}</label>
              <input aria-labelledby="vinlabel" mdbInput mdbValidate type="text" id="vin" formControlName="editVehicleVIN" class="form-control" minlength="17" maxlength="17" />
            </div>
            <mdb-error role="alert" *ngIf="editVehicleVINForm.controls.editVehicleVIN.invalid && (editVehicleVINForm.controls.editVehicleVIN.dirty || editVehicleVINForm.controls.editVehicleVIN.touched)">
              <span *ngIf="editVehicleVINForm.controls.editVehicleVIN.errors.required">*{{ 'ASP_LANDING.VIN_REQUIRED' | translate }}</span>
              <span *ngIf="editVehicleVINForm.controls.editVehicleVIN.errors.minlength">*{{ 'ASP_LANDING.VALID_VIN' | translate }}</span>
            </mdb-error>
          </div>
          <div class="col-sm-12">
            <div class="md-form mt-3">
              <label id="odometerlabel" for="editVehicleOdometerVIN">{{ 'ASP_LANDING.CURRENT_MILEAGE' | translate }} <app-tooltip *ngIf="odometerTooltip" message="{{odometerTooltip}}"></app-tooltip></label>
              <input data-public aria-labelledby="odometerlabel" mdbInput mdbValidate type="text" id="editVehicleOdometerVIN" formControlName="editVehicleOdometerVIN" class="form-control" (keypress)="onlyNumberKey($event)" (change)="removeCommasVIN()" (focus)="removeCommasVIN()" (blur)="addCommasVIN()" />
            </div>
            <mdb-error role="alert" *ngIf="editVehicleVINForm.controls.editVehicleOdometerVIN.invalid && (editVehicleVINForm.controls.editVehicleOdometerVIN.dirty || editVehicleVINForm.controls.editVehicleOdometerVIN.touched)">*{{ 'ASP_LANDING.MILEAGE_REQUIRED' | translate }}</mdb-error>
          </div>
          <div class="col-sm-12">
            <div class="md-form mt-3">
              <label class="no-touch-label" id="statelabel2" for="state" [ngClass]="{ active: editVehicleVINForm.controls.editVehicleRegisteredStateVIN.value != null }"> {{ 'ASP_LANDING.STATE' | translate }}</label>
              <!-- <mdb-select aria-labelledby="statelabel2" [options]="stateData" id="state" formControlName="editVehicleRegisteredStateVIN" (opened)="fixSelectAria($event)" (selected)="stateSelectedVIN($event)"></mdb-select> -->
              <select role="listbox" aria-labelledby="statelabel2" id="state" class="form-control" formControlName="editVehicleRegisteredStateVIN" (change)="stateSelectedVIN($event)">
                <option *ngFor="let state of stateData" value="{{ state.value }}">{{ state.label }}</option>
              </select>
            </div>
            <mdb-error role="alert" *ngIf="editVehicleVINForm.controls.editVehicleRegisteredStateVIN.invalid && (editVehicleVINForm.controls.editVehicleRegisteredStateVIN.dirty || editVehicleVINForm.controls.editVehicleRegisteredStateVIN.touched)">*{{ 'ASP_LANDING.STATE_REQUIRED' | translate }}</mdb-error>
          </div>
          <div class="row w-100 d-flex justify-content-center">
            <!-- <div class="col-md-6 col-12">
            <a class="btn secondary-btn waves-light shadow-sm float-left" mdbBtn mdbWavesEffect (click)="refreshState()" (click)="modalRef.hide()"><mdb-icon far icon="edit"></mdb-icon> Close</a>
          </div> -->
            <div class="col-12 text-center">
              <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn waves-light" mdbWavesEffect type="submit" (click)="checkIfCriteriaChangeEditedVIN()" [disabled]="(inlineLoader$ | async) || (verifyVin$ | async) === 'failed'">
                <span *ngIf="currentBrand == 'NCESI'">
                  <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false" class="">{{ 'ASP_LANDING.VERIFY_VEHICLE_BUTTON' | translate }}</span>
                </span>
                <span *ngIf="currentBrand != 'NCESI'">
                  <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false" class="">{{buttonText}}</span>
                </span>
                <span *ngIf="inlineLoader$ | async">
                  <span class="spinner-border spinner-border-sm"></span>
                  {{ 'ASP_LANDING.LOADING' | translate }}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>

  </div>
</div>
