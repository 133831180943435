<div class="content-block faq" [ngStyle]="getStyles(slice)">
  <div role="heading" tabindex="0" class="faq-title text-center" [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>
  <div class="faq" *ngFor="let field of slice.fields">
    <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-secondary'" *ngIf="field.faq_group?.show_tabs == 'show'" [justified]="true">
      <mdb-tab heading="{{ faq_group.primary.category_title[0].text }}" *ngFor="let faq_group of field.faq_group.body">
        <mdb-accordion [multiple]="false">
          <mdb-accordion-item *ngFor="let qAndA of faq_group.fields">
            <mdb-accordion-item-head><div [innerHTML]="getHtml(qAndA.question)"></div></mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div tabindex="0" [innerHTML]="getHtml(qAndA.answer)"></div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
      </mdb-tab>
    </mdb-tabset>

    <div class="faq-overview" *ngIf="field.faq_group?.show_tabs == 'hide'">
      <div class="row">
        <div class="col-sm-12 col-md-8 offset-md-2">
          <div class="container faq" *ngFor="let faq_group of field.faq_group.body">
            <mdb-accordion [multiple]="false">
              <mdb-accordion-item *ngFor="let qAndA of faq_group.fields">
                <mdb-accordion-item-head><div [innerHTML]="getHtml(qAndA.question)"></div></mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div tabindex="0" [innerHTML]="getHtml(qAndA.answer)"></div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container content-block faq mobile pb-5 pl-3 pr-3" [ngStyle]="{ 'margin-top': slice.primary.margin_top, 'margin-bottom': slice.primary.margin_bottom }">
  <div class="faq-title text-center pb-4" [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>
  <mdb-accordion [multiple]="false" *ngFor="let field of slice.fields">
    <mdb-accordion-item [collapsed]="true" *ngFor="let faq_group of field?.faq_group.body">
      <mdb-accordion-item-head>
        <span tabindex="0" style="width: 90%; display: inline-block; font-size: 0.9em">{{ faq_group?.primary.category_title[0].text }}</span>
      </mdb-accordion-item-head>
      <mdb-accordion-item-body>
        <div class="row">
          <div class="col-12 pb-3 pt-3" *ngFor="let qAndA of faq_group?.fields" tabindex="0">
            <p>
              <strong>{{ qAndA?.question[0].text }}</strong>
            </p>
            <span>{{ qAndA?.answer ? qAndA?.answer[0].text: '' }}</span>
          </div>
        </div>
      </mdb-accordion-item-body>
    </mdb-accordion-item>
  </mdb-accordion>
</div> -->
