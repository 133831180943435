import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { Surcharge } from './rates';

export class ShoppingCartResponse {
    status: string;
    errorMessage: string;
    data: GetShoppingCart[];
}

export class GetShoppingCart {
    shoppingCartId: number;
    createdBy: string;
    createdDate: string;
    price: number;
    referenceNumber1: string;
    referenceNumber2: string;
    referenceNumber3: string;
    referenceNumber4: string;
    status: string;
    shoppingCartLineItems: GetShoppingCartLineItems[];
}

export class GetShoppingCartLineItems {
    shoppingCartLineItemId: number;
    currency: string;
    itemName: string;
    itemDescription: string;
    lineItemCostExcludingTax: number;
    salesTax: number;
    lineItemCostIncludingTax: number;
    itemInCartFrom: string;
    referenceNumber1: string;
    referenceNumber2: string;
    referenceNumber3: string;
    referenceNumber4: string;
    status: string;
}

export class CreateShoppingCartResponse {
    status: string;
    data: string;
    errorMessage: string;
}

export class ShoppingCart {
    accountExternalId: string;
    accountSfId: string;
    brandExternalId: string;
    brandSfId: string;
    contactExternalId: string;
    contactSfId: string;
    lineItems: LineItems[];
    shoppingCartReferenceNumber1: string;
    shoppingCartReferenceNumber2: string;
    shoppingCartReferenceNumber3: string;
    shoppingCartReferenceNumber4: string;
    status: string;
    vehicleExternalId: string;
    vehicleSfId: string;
}

export class LineItems {
    currency: string;
    itemDescription: string;
    itemInCartFrom: string;
    itemName: string;
    lineItemCostExcludingTax: number;
    lineItemCostIncludingTax: number;
    product2ExternalId: string;
    product2Sfid: string;
    productSkuExternalId: string;
    productSkuSfId: string;
    salesTax: number;
    shoppingCartLineItemId: number;
    shoppingCartLineItemReferenceNumber1: string;
    shoppingCartLineItemReferenceNumber2: string;
    shoppingCartLineItemReferenceNumber3: string;
    shoppingCartLineItemReferenceNumber4: string;
    status: string;
    productName?: string;
    yearTerm?: string;
    mileageTerm?: string;
    deductible?: string;
    lightDuty?: boolean;
    liftKit?: boolean;
}

export class Cart {
    shoppingCartId: string;
    commercialUse: boolean;
    liftKit: boolean;
    greyMarket: boolean;
    snowPlow: boolean;
    plans: CartPlan[];
    deductible: number;
    isDisappearing: boolean;
    subtotal: number;
    estimatedTax: number;
    grandTotal: number;
    numberOfMonthlyPayments: number;
    monthlyPayment: number;
    downPayment: number;
    downPaymentWithTax: number
    shoppingCartData: ShoppingCart;
}

export class CartPlan {
    referenceNumber: string;
    productCode: string;
    productName: string;
    ratesProductName: string;
    planName: string;
    planIndex: number;
    termIndex: number;
    skuId: string;
    planPrice: number;
    planTerms: PlanTerms;
    addons: Addon[];
    prismicPDF?: string;
    productSkuId: string;
    planCode: string;
    contractDealerCost: string;
    productFormNumber: string;
    surcharge?: Surcharge
}

export class Addon {
    addonName: string;
    skuId: string;
    addonPrice: number;
}

export class PlanTerms {
    yearsMileage: string;
    deductible: number;
}

export class CartDeleteItem {
    skuID: string;
}


