import { ContractTax } from './contract';

export class TaxResponse {
	status: string;
	data: Tax;
	errorMessage: string;
}

export class Tax {
	taxBreakdownId: number;
	salesTaxRequestID: string;
	grandTotalAmount: number;
	totalTaxableAmount: number;
	totalTaxAmount: number;
	contracts: ContractTax[];
	monthlyPayment: number;
	totalToday: number;
}

export class ContractTaxDetails {
	taxableAmount: number;
	exemptAmount: number;
	specialTaxAmount: number;
	cityTaxAmount: number;
	countyTaxAmount: number;
	stateTaxAmount: number;
	countryTaxAmount: number;
	totalTaxAmount: number;
}

export class TaxRequest {
	opportunityId: string;
	city: string;
	country: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	postalCode: string;
	state: string;
	street1: string;
	vin: string;
	brandName: string;
	downPayment: number;
	installments: number;
	items: TaxRequestItems[];
}

export class TaxRequestItems {
	quantity: number;
	unitPrice: number;
	productCode: string;
	productName: string;
	productSKU: string;
	productSfId: string;
	contractNumber: string;
}