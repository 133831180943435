import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadBrandInfo, loadBrandInfoFailed, loadBrandInfoSuccess } from '../_actions/branding-info.actions';


const _brandInfoReducer = createReducer(initialState,
    on(loadBrandInfo, (state, payload) => {
        console.log('consolidated - Brand Info Loading. The state on first load is:', state, payload)
        return state;
    }),
    on(loadBrandInfoSuccess, (state, payload) => {
      console.log("consolidated - Load Brand Info Success Action called in _brandInfoReducer: ", payload);
      return payload;
    }),
    on(loadBrandInfoFailed, (state) => {
      console.log("consolidated - Load Brand Info Failed Action called in _brandInfoReducer: ", state);
      return false;
    })
);

export function brandInfoReducer(state, action) {
    return _brandInfoReducer(state, action);
}
