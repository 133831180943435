import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { BrandingInfo } from "src/app/_models/brandingInfo";
import { HomeService } from "src/app/_services/home.service";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-footer-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"],
})
export class DisclaimerComponent implements OnInit {
  disclaimers: any;
  footer: any;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;
  constructor(
    private home: HomeService,
    private prismicService: PrismicService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit(): void {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;

        if (brandInfo) {
          this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
            next: (result) => {
              this.footer = result;
              if (this.footer.body) {
                for (const footerContent of this.footer.body) {
                  switch (footerContent.type) {
                    case "disclaimers":
                      this.disclaimers = this.prismicService.getHtml(footerContent.primary.disclaimer_body);
                      break;
                    default:
                      break;
                  }
                }
              }
            },
            error: (error) => {},
          });
        }
      });
  }
}
