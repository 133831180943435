import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map } from "rxjs/operators";
import { Observable, Subscription, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { Store } from "@ngrx/store";
import { BrandingInfo } from "../_models/brandingInfo";

declare var gtag;

@Injectable({
  providedIn: "root",
})
export class GatewayService {
  public baseUrl;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;
  constructor(
    private httpClient: HttpClient,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          console.log("consolidated - GatewayService brandInfo: ", brandInfo);
          this.brandInfo = brandInfo;
          this.baseUrl = this.getBaseUrl();
        }
      });
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  public getBaseUrl() {
    let baseUrl = environment.baseUrl;

    if (this.brandInfo && this.brandInfo.useFakeAPI == true) {
      baseUrl = environment.fakeApiUrl;
    }

    return baseUrl;
  }

  verify_code(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + "session").pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
