<form [formGroup]="accountPhoneValidationForm">
    <div class="alert alert-warning w-100 text-center" role="alert" *ngIf="(session$ | async) === 'invalid'">
        {{ 'MY_ACCOUNT_LOGIN.SESSION_LOGGED_OUT' | translate }}
    </div>
    <div class="row">
        <div class="col-12">
            <div>
                <div class="md-form mt-3 mb-3">
                    <label for="phone">{{ 'MY_ACCOUNT_LOGIN.PHONE_NUMBER' | translate }}</label>
                    <input type="text" id="phone" class="form-control" mdbInput required formControlName="phoneNumber"
                        (keypress)="onlyNumberKey($event)">
                    <mdb-error
                        *ngIf="accountPhoneValidationForm.controls.phoneNumber.invalid && (accountPhoneValidationForm.controls.phoneNumber.dirty || accountPhoneValidationForm.controls.phoneNumber.touched)">
                        <span role="alert"
                            *ngIf="accountPhoneValidationForm.controls.phoneNumber.errors.required">{{ 'MY_ACCOUNT_LOGIN.ERROR_PHONE_REQ' | translate }}</span>
                        <span role="alert"
                            *ngIf="accountPhoneValidationForm.controls.phoneNumber.errors.pattern">{{ 'MY_ACCOUNT_LOGIN.ERROR_PHONE_INVAILD' | translate }}</span>
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div>
                <div class="md-form mt-3 mb-3">
                    <label for="lnametext">{{ 'MY_ACCOUNT_LOGIN.LAST_NAME' | translate }}</label>
                    <input type="text" id="lnametext" class="form-control" mdbInput required formControlName="lastName">
                    <mdb-error role="alert"
                        *ngIf="accountPhoneValidationForm.controls.lastName.invalid && (accountPhoneValidationForm.controls.lastName.dirty || accountPhoneValidationForm.controls.lastName.touched)">
                        {{ 'MY_ACCOUNT_LOGIN.ERROR_NAME_REQ' | translate }}
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="pb-4 pt-4 w-100 text-center">
            <button mdbBtn class="btn primary-btn waves-light" mdbWavesEffect type="button"
                [disabled]="!accountPhoneValidationForm.valid" (click)="verifyAccountPhone()">{{ 'MY_ACCOUNT_LOGIN.SEND_CODE' | translate }}</button>
        </div>
    </div>
</form>