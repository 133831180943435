import { environment } from './environments/environment';
// import { PrismicService } from "./_services/prismic.service";


export interface PrismicConfiguration {
  // baseurl: string;
  // cookieUrl: string;
  // apiEndpoint: string;
  // gqlEndpoint: string;
  // accessToken?: string;
  linkResolver: Function;
}

function getPageUrl (doc){
  switch (doc.uid) {
    case "rev-vsp":
      // return `/${brandInfo.homePage}`;
      break;
    // case "rev-ppm":
    //   return `/prepaid-maintenance`;
    //   break;
    case "how-it-works":
      return `/how-vsp-works`;
      break;
    case "home":
      return `/home`;
      break;
    default:
      console.log(`/page/${doc.uid}`);

      return `/page/${doc.uid}`;
      break;
  }
}

export const PrismicConfig: PrismicConfiguration = {
  // baseurl: environment.prismic.baseUrl,
  // cookieUrl: environment.prismic.cookieUrl,
  // apiEndpoint: environment.prismic.apiEndpoint,
  // gqlEndpoint: environment.prismic.gqlEndpoint,
  // accessToken: environment.prismic.accessToken,
  linkResolver(doc) {
    console.log("doc: ", doc);
    console.log("doc type: ", doc.type);

    switch (doc.type){
      case "page":
        return getPageUrl(doc);
        break;
      case "support_page":
        return `/help-center/${doc.uid}`;
        break;
      case "content_block":
          return `/content-block/${doc.uid}`;
          break;

    }

  }
};

export default PrismicConfig;
