import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { VerifyAccountResponse } from "src/app/_models/verify-account";
import { PreferenceCenterURLParams } from "../_models/urlParams";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { showPreferenceCenterInlineLoader, preferenceCenterEmailFound, hidePreferenceCenterInlineLoader, preferenceCenterNoEmailFound } from "../_actions/preference-center.actions";
import { Observable } from "rxjs";
import { showInlineLoader, hideInlineLoader } from "../_actions/inline-loader.actions";
import { AppConfigService } from "../appconfig/appconfig.service";
import { BrandingInfo } from "../_models/brandingInfo";

@Component({
  selector: "app-preference",
  templateUrl: "./preference.component.html",
  styleUrls: ["./preference.component.scss"],
})
@Input()
export class PreferenceComponent implements OnInit {
  urlParameters: PreferenceCenterURLParams = {
    emailId: null,
  };
  preferenceCenterInlineLoader$: Observable<any>;
  preferenceCenter$: Observable<any>;
  urlSafe: SafeResourceUrl;
  branding: any;
  brandInfo: BrandingInfo;

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private router: Router,
    private store: Store<{
      inlineLoaderPreferenceCenter: any;
      preferenceCenter: any;
      brandInfo: BrandingInfo;
    }>
  ) {
    this.route.queryParams.subscribe((params) => {
      this.urlParameters.emailId = params["email"];
      this.urlParameters.SubKey = params["SubKey"];
      this.urlParameters.JobID = params["JobID"];
      this.urlParameters.BatchID = params["BatchID"];
    });
  }

  ngOnInit() {
    this.appConfig.getSettings().subscribe(
      (result) => {
        this.branding = result;
      },
      (error) => {}
    );

    this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;

        if (paramsPresent) {
          let baseSalesforce = `${this.brandInfo.preferenceCenterUrl}`;
          console.log("New URL Being Used", baseSalesforce);
          let url: string = `${baseSalesforce}?email=${this.urlParameters.emailId}
                            &SubKey=${this.urlParameters.SubKey}
                            &JobId=${this.urlParameters.JobID}
                            &BatchId=${this.urlParameters.BatchID}`;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          console.log(this.urlSafe);
          this.store.dispatch(preferenceCenterEmailFound());
          this.store.dispatch(hideInlineLoader());
        } else if (paramsPresentOld) {
          let url = `${this.brandInfo.preferenceCenterUrl}?subscriber=` + this.urlParameters.emailId;
          console.log("Old URL Being Used");
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          console.log(this.urlSafe);
          this.store.dispatch(preferenceCenterEmailFound());
          this.store.dispatch(hideInlineLoader());
        } else {
          console.log("3");
          this.store.dispatch(preferenceCenterNoEmailFound());
          this.store.dispatch(hideInlineLoader());
        }
      });

    this.preferenceCenterInlineLoader$ = this.store.select((store) => store.inlineLoaderPreferenceCenter);
    this.preferenceCenter$ = this.store.select((store) => store.preferenceCenter);

    this.store.dispatch(showInlineLoader());

    console.log(this.urlParameters);

    let paramsPresent = false;
    let paramsPresentOld = false;
    if (this.urlParameters.emailId && this.urlParameters.SubKey && this.urlParameters.JobID && this.urlParameters.BatchID) {
      paramsPresent = true;
    } else if (this.urlParameters.emailId) {
      paramsPresentOld = true;
    }
    let _verifiedAccount: VerifyAccountResponse = JSON.parse(sessionStorage.getItem("accountVerification"));
    // let baseSalesforce = 'https://fe9813737664007e76.pub.s7.sfmc-content.com/uzfcrypnnra?subscriber=';
  }

  retry() {
    console.log("Retry Hit");
    this.ngOnInit();
  }
}
