import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Component({
  selector: "app-modal-vin",
  templateUrl: "./modal-vin.component.html",
  styleUrls: ["./modal-vin.component.scss"],
})
export class ModalVinComponent implements OnInit {
  title: string;
  subheader: string;
  imageURL: string;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    public modalRef: MDBModalRef,
    private translate: TranslateService,
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit(): void {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;

        this.translate.get("ASP_LANDING").subscribe((asp) => {
          this.title = asp.WHERE_IS_VIN;
          this.subheader = asp.VIN_LOCATION;

          // this.title = 'Where\'s My VIN?';
          // this.subheader = 'The VIN is usually on the driver\'s side.';
          this.imageURL = "https://images.prismic.io/sgidigital/c04b2690-3a3e-44c9-9662-ef5704ce4a55_VROOM-VIN-Image.jpg?auto=compress,format";
          if (brandInfo.brand === "NCESI") {
            this.imageURL = "https://images.prismic.io/sgidigital/b5aac4bb-e1ca-4519-908e-ee25a42d15b6_NCESI-VIN-Image.jpg?auto=compress,format";
          } else if (brandInfo.brand === "Buick") {
            this.subheader = "The VIN can be found in your myBuick app, the driver's side interior dash, door, or title and registration.";
            this.imageURL = "https://images.prismic.io/sgidigital/d1da7779-6136-4c3e-97ae-5bbcfdd8f4ea_buickVIN.png?auto=compress,format";
          } else if (brandInfo.brand === "Chevrolet") {
            this.subheader = "The VIN can be found in your myChevrolet app, the driver's side interior dash, door, or title and registration.";
            this.imageURL = "https://images.prismic.io/sgidigital/1a6f9da3-cbb9-4d45-8a98-84e1b90cbe54_Chevrolet_VIN_Location.png?auto=compress,format";
          } else if (brandInfo.brand === "GMC") {
            this.subheader = "The VIN can be found in your myGMC app, the driver's side interior dash, door, or title and registration.";
            this.imageURL = "https://images.prismic.io/sgidigital/22b311b7-e580-4a3e-a342-c9a73e05952f_GMC_Vin_Location.jpg?auto=compress,format";
          } else if (brandInfo.brand === "Cadillac") {
            this.subheader = "The VIN can be found in your myCadillac app, the driver's side interior dash, door, or title and registration.";
            this.imageURL = "https://images.prismic.io/sgidigital/bbf4f54f-d48a-4120-acbb-55b9ad765750_Cadillac_VIN_Location.jpg?auto=compress,format";
          } else if (brandInfo.brand === "Schomp") {
            this.subheader = "The VIN can be on the driver's side interior dash, door, or title and registration.";
            this.imageURL = "https://images.prismic.io/sgidigital/314123ea-c7f2-4abb-8710-2e6942774aaf_schomp-vin-Image.jpg?auto=compress,format";
          }
        });
      });
  }
}
