import { Component, OnInit, Input, AfterViewInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrandingInfo } from 'src/app/_models/brandingInfo';


@Component({
    selector: 'app-search-content',
    templateUrl: './search-content.component.html',
    styleUrls: ['./search-content.component.scss'],
})
export class SearchContentComponent implements OnInit, AfterViewInit {
    @Input() searchData;
    @Input() searchParam;
    brandInfo: BrandingInfo;

    constructor(private store: Store<{
      brandInfo: BrandingInfo
    }>) {

      this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
      });
    }

    ngOnInit() {

    }

    resolveUid(uid) {
        switch (uid) {
            case 'rev-vsp':
                return `/${this.brandInfo.homePage}`;
                break;
            case 'how-it-works':
                return `/how-vsp-works`;
                break;
            case 'support-page':
                return `/help-center`;
                break;
            case 'support-page-claims':
                return `/help-center/claims`;
                break;
            case 'support-page-cancellations':
                return `/help-center/how-to-cancel`;
                break;
            case 'home':
                return `/home`;
                break;
            default:
                return `/${this.brandInfo.homePage}`;
                break;
        }
    }

    ngAfterViewInit() {
        // console.log(this.searchData);
    }
}
