import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Vehicle } from "../_models/vehicle";
import { environment } from "src/environments/environment";
import { BrandingInfo } from "../_models/brandingInfo";
import { Lead } from "../_models/lead";

@Component({
  selector: "app-countdown",
  templateUrl: "./countdown.component.html",
  styleUrls: ["./countdown.component.scss"],
})
export class CountdownComponent implements OnInit {
  vehicleData$: Observable<Vehicle>;
  leadData$: Observable<Lead>;
  isProduction = environment.production;
  currentBrand: string;
  preferredLanguage: string;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private store: Store<{
      vehicle: Vehicle;
      lead: Lead;
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit(): void {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (this.brandInfo) {
          this.brandInfo = brandInfo;

          setTimeout(() => {
            console.log("Countdown Init for brand: ", brandInfo.brand);
            this.currentBrand = brandInfo.brand;
            this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
            // console.log('Countdown timer called')
            this.vehicleData$ = this.store.select((store) => store.vehicle);
            // console.log('this.vehicleData$',this.vehicleData$)
            this.leadData$ = this.store.select((store) => store.lead);
          });
        }
      });
  }
}
