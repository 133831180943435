import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, Subscription, throwError } from 'rxjs';
import { Order } from '../_models/order'
import { AccountResponse } from '../_models/account';
import { environment } from '../../environments/environment'
;

import { GatewayService } from './gateway.service';
import { BrandingInfo } from '../_models/brandingInfo';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
  })
export class OktaService {

  brandInfo: BrandingInfo;
brandInfoSub: Subscription;

    constructor(private httpClient : HttpClient, private gatewayService: GatewayService,
      private store: Store<{
        brandInfo: BrandingInfo
      }>
      ) {

        this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
          this.brandInfo = brandInfo;
        });


      }

  // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    check_okta_registration_token(dataObject): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/v1/api/okta/checkpassword/${dataObject.token}/${this.brandInfo.id}`)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    set_okta_password(dataObject): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/v1/api/okta/setpassword`, {
            password: dataObject.password,
            id: dataObject.id,
            token: dataObject.token,
            email: dataObject.email
        })
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    change_okta_password(dataObject): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/v1/api/okta/changepassword`, {
            old: dataObject.current,
            new: dataObject.new,
            id: dataObject.id,
        }, {
            headers: {
                Token_Auth: dataObject.access_token
            }
        })
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    sign_out(dataObject): Observable<any> {
        console.log('dataObject', dataObject)
        return this.httpClient.delete(`${environment.apiUrl}/v1/api/okta/logout/${dataObject.id}`, {
            headers: {
                Token_Auth: dataObject.access_token
            }
        })
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    no_username_in_password(password, username) {
        if (username.includes('+')) {
            const _username = username.substring(0, username.indexOf('+'))
            return password.includes(_username) ? false : true
        } else {
            const _username = username.substring(0, username.indexOf('@'))
            return password.includes(_username) ? false : true
        }
    }

    register(dataObject): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/v1/api/okta/registeruser`, {
            email: dataObject.email,
            firstName: dataObject.firstName,
            lastName: dataObject.lastName,
            brandKey: this.brandInfo.id,
            orderNumber: dataObject.orderNumber
        })
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    verify_account_okta_flow(dataObject): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/v1/api/okta/verifyAccount/${dataObject.orderNumber}/${dataObject.lastName}/${this.brandInfo.id}`)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
    }

    handleError(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
     }
}
