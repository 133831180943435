<div class="container verify-account" *ngIf="!useOkta">
    <div class="row justify-content-md-center">

        <mdb-card class="col-md-8">
            <mdb-card-body>
                <app-my-account-verify-account-error-messages></app-my-account-verify-account-error-messages>
                <div [ngSwitch]="view">
                    <div *ngSwitchDefault>
                        <mdb-card-title class="text-center">
                            <div aria-level="1" tabindex="0" class="pt-3 pb-3 account-header" aria-live="assertive">
                                {{ 'MY_ACCOUNT_LOGIN.LOGIN_METHOD' | translate }}</div>
                        </mdb-card-title>
                    </div>
                    <div *ngSwitchCase="2">
                        <mdb-card-title class="text-center">
                            <div aria-level="1" tabindex="0" class="pt-3 pb-3 account-header" aria-live="assertive">
                                {{ 'MY_ACCOUNT_LOGIN.THANKS' | translate }}</div>
                        </mdb-card-title>
                    </div>
                    <mdb-card-text *ngSwitchDefault>
                        <div class="text-center">
                            <div *ngIf="(verifyAccount$ | async) === 'invalid'"
                                class="alert alert-warning w-100 text-center" role="alert">
                                {{ 'MY_ACCOUNT_LOGIN.ERROR_INFO' | translate }}
                            </div>
                            <p tabindex="0"> {{ 'MY_ACCOUNT_LOGIN.VERIFY_ACCOUNT' | translate }}</p>

                            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills mt-4'" [justified]="true"
                                [contentClass]="'card'">
                                <mdb-tab heading="{{ 'MY_ACCOUNT_LOGIN.EMAIL' | translate }}">
                                    <app-my-account-verify-email (viewEmitter)="viewEmitterFunction($event)"
                                        (verifyType)="verifyTypeEmitterFunction($event)"
                                        (verifyCodeRequest)="verifyCodeReuqestEmitterFunction($event)"
                                        [reset]="emailReset"></app-my-account-verify-email>
                                </mdb-tab>

                                <mdb-tab heading="{{ 'MY_ACCOUNT_LOGIN.TEXT' | translate }}">
                                    <app-my-account-verify-phone (viewEmitter)="viewEmitterFunction($event)"
                                        (verifyType)="verifyTypeEmitterFunction($event)"
                                        (verifyCodeRequest)="verifyCodeReuqestEmitterFunction($event)"
                                        [reset]="phoneReset"></app-my-account-verify-phone>
                                </mdb-tab>
                            </mdb-tabset>
                            <div class="w-100 text-center pt-4">
                                <div class="w-100 text-center pt-4">
                                    <span tabindex="0">{{ 'MY_ACCOUNT_LOGIN.HELP' | translate }}<br />{{ 'MY_ACCOUNT_LOGIN.CALL' | translate:{ phone:branding.cs_phone_number } }}</span>
                                </div>
                            </div>
                        </div>
                    </mdb-card-text>
                    <mdb-card-text *ngSwitchCase="2">
                        <app-my-account-verify-code (resetEmitter)="resetEmitterFunction($event)"
                            (viewEmitter)="viewEmitterFunction($event)" [verifyType]="verifyType"
                            [verifyCodeRequest]="verifyCodeRequest"></app-my-account-verify-code>
                    </mdb-card-text>
                </div>
            </mdb-card-body>
        </mdb-card>

    </div>
</div>

<div class="container" *ngIf="useOkta" [ngClass]="{hide:showOktaPasswordFlow}">
    <app-inline-loader></app-inline-loader>
    <div class="row justify-content-center">
        <div class="pl-4 pr-4">
            <div id="widget-container" #widgetContainer>
                <div class="okta-spinner" *ngIf="showSpinner">
                    <div class="preloader-wrapper active indicator">
                        <div class="spinner-layer spinner-client-color">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="useOkta" [ngClass]="{hide:!showOktaPasswordFlow}">
    <app-inline-loader></app-inline-loader>
    <div class="row justify-content-center" *ngIf="passwordFlow == 1">
        <div class="pl-4 pr-4">
            <div id="password-reset-container">
                <div>
                    <h2 class="okta-form-title">Password Reset</h2>
                </div>
                <div *ngIf="orderNumberError" class="okta-form-infobox-error infobox infobox-error" role="alert">
                    <span class="icon error-16"></span>
                    <p>There was an error retrieving your account.</p>
                </div>
                <div class="disclaimer o-form-fieldset-container">
                    Your order number is needed to verify account.
                </div>
                <form [formGroup]="orderNumberForm">
                    <div class="o-form-fieldset-container">
                        <div class="o-form-fieldset o-form-label-top">
                            <div class="okta-form-label o-form-label">
                                <label>Order Number</label>
                            </div>
                            <div class="o-form-input">
                                <span class="o-form-control okta-form-input-field input-fix">
                                    <input type="text" formControlName="orderNumber" autocomplete="off">
                                </span>
                            </div>
                            <mdb-error class="okta-form-input-error o-form-input-error o-form-explain" role="alert"
                                *ngIf="orderNumberForm.controls.orderNumber.invalid && (orderNumberForm.controls.orderNumber.dirty || orderNumberForm.controls.orderNumber.touched)">
                                <span *ngIf="orderNumberForm.controls.orderNumber.errors.required">This field cannot be left blank</span>
                            </mdb-error>
                        </div>
                        <div class="o-form-fieldset o-form-label-top">
                            <div class="okta-form-label o-form-label">
                                <label>Last Name</label>
                            </div>
                            <div class="o-form-input">
                                <span class="o-form-control okta-form-input-field input-fix">
                                    <input formControlName="lastName" autocomplete="off">
                                </span>
                            </div>
                            <mdb-error class="okta-form-input-error o-form-input-error o-form-explain" role="alert"
                                *ngIf="orderNumberForm.controls.lastName.invalid && (orderNumberForm.controls.lastName.dirty || orderNumberForm.controls.lastName.touched)">
                                <span *ngIf="orderNumberForm.controls.lastName.errors.required">This field cannot be left blank</span>
                            </mdb-error>
                        </div>
                        <div class="o-form-button-bar">
                            <input class="button button-primary" type="submit" value="Submit" (click)="checkOrderNummber(orderNumberForm)">
                        </div>
                    </div>
                </form>
                <a class="link" href="javascript:void(0)" (click)="showWidget()">Back to Sign In</a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="passwordFlow == 2 || passwordFlow === 3">
        <app-inline-loader></app-inline-loader>
        <div class="pl-4 pr-4">
            <div id="password-reset-container">
                <div>
                    <h2 class="okta-form-title" *ngIf="passwordFlow == 2">Update Password</h2>
                    <h2 class="okta-form-title" *ngIf="passwordFlow == 3">Create Password</h2>
                </div>
                <div class="disclaimer o-form-fieldset-container">
                    Your password must be at least eight (8) characters long and connot contain any part of the
                    username.
                    <br>
                    <br>
                    It should include one of each below:<br>
                    Uppercase: A-Z; Lowercase: a-z; Number: 0-9; Symbol: !@#$%^&*()?
                </div>
                <form [formGroup]="changePasswordForm">
                    <div class="o-form-fieldset-container">
                        <!-- <div class="o-form-fieldset o-form-label-top">
                            <div class="okta-form-label o-form-label">
                                <label>Current Password</label>
                            </div>
                            <div class="o-form-input">
                                <span class="o-form-control okta-form-input-field input-fix">
                                    <input type="password" placeholder="" name="" value="" aria-label=""
                                        formControlName="oldPassword" autocomplete="off" class="password-with-toggle">
                                </span>
                            </div>
                            <mdb-error class="okta-form-input-error o-form-input-error o-form-explain" role="alert"
                                *ngIf="changePasswordForm.controls.oldPassword.invalid && (changePasswordForm.controls.oldPassword.dirty || changePasswordForm.controls.oldPassword.touched)">
                                <span *ngIf="changePasswordForm.controls.oldPassword.errors.required">This field cannot be left blank</span>
                            </mdb-error>
                        </div> -->
                        <div class="o-form-fieldset o-form-label-top">
                            <div class="okta-form-label o-form-label">
                                <label>New Password</label>
                            </div>
                            <div class="o-form-input">
                                <span class="o-form-control okta-form-input-field input-fix">
                                    <input type="password" placeholder="" name="" value="" aria-label=""
                                        formControlName="newPassword" autocomplete="off" class="password-with-toggle">
                                </span>
                            </div>
                            <mdb-error class="okta-form-input-error o-form-input-error o-form-explain" role="alert"
                                *ngIf="changePasswordForm.controls.newPassword.invalid && (changePasswordForm.controls.newPassword.dirty || changePasswordForm.controls.newPassword.touched)">
                                <span *ngIf="changePasswordForm.controls.newPassword.errors.required">This field cannot be left blank. &nbsp;</span>
                                <span *ngIf="changePasswordForm.controls.newPassword.errors.minlength">Password is too short. &nbsp;</span>
                                <span *ngIf="changePasswordForm.controls.newPassword.errors.pattern">Password is too weak. &nbsp;</span>
                                <span *ngIf="changePasswordForm.controls.newPassword.errors.username">Password contains username.</span>
                            </mdb-error>
                        </div>
                        <div class="o-form-fieldset o-form-label-top">
                            <div class="okta-form-label o-form-label">
                                <label>Confirm New Password</label>
                            </div>
                            <div class="o-form-input">
                                <span class=" o-form-control okta-form-input-field input-fix">
                                    <input type="password" placeholder="" name="" value="" aria-label=""
                                    formControlName="confirmNewPassword" autocomplete="off" class="password-with-toggle">
                                </span>
                            </div>
                            <mdb-error class="okta-form-input-error o-form-input-error o-form-explain" role="alert"
                            *ngIf="changePasswordForm.controls.confirmNewPassword.invalid && (changePasswordForm.controls.confirmNewPassword.dirty || changePasswordForm.controls.confirmNewPassword.touched)">
                            <span *ngIf="changePasswordForm.controls.confirmNewPassword.errors?.mismatch">Please make sure passwords match</span>
                            <span *ngIf="changePasswordForm.controls.confirmNewPassword.errors.required">This field cannot be left blank</span>
                        </mdb-error>
                        </div>
                        <div class="o-form-button-bar">
                            <input *ngIf="passwordFlow == 2" mdbBtn class="button button-primary" [disabled]="!changePasswordForm.valid" type="submit" value="Update" data-type="save" (click)="setPassword()">
                            <input *ngIf="passwordFlow == 3" mdbBtn class="button button-primary" [disabled]="!changePasswordForm.valid" type="submit" value="Submit" data-type="save" (click)="setPassword()">
                        </div>
                    </div>
                </form>
                <a class="link" href="javascript:void(0)" (click)="showWidget()">Back to Sign In</a>
            </div>
        </div>
    </div>
</div>