import { ModalBrowserWarningComponent } from "./modal-browser-warning/modal-browser-warning.component";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Component, OnInit, AfterViewInit, isDevMode, OnDestroy, HostListener, Inject } from "@angular/core";
import { fadeOut, fadeAnimation } from "./animations/animations";
import { filter, last, takeLast } from "rxjs/operators";
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, Router, RouterOutlet, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
declare var gtag;
declare var _etmc;
import { Lead } from "./_models/lead";
import { Store } from "@ngrx/store";
import { rehydrateLead } from "./_actions/lead.actions";
import { Vehicle, VerifyVehCustResponse, VerifyVehCustResponseData, VinDecodeData } from "./_models/vehicle";
import { rehydrateVehicle, verifyVehCustSuccessContactInformation } from "./_actions/vehicle.actions";
import { Cart } from "./_models/cart";
import { Registration } from "./_models/registration";
import { rehydrateRegistration } from "./_actions/registration.actions";
import { rehydrateCart } from "./_actions/cart.actions";
import { rehydrateVin } from "./_actions/vinDecode.actions";
import { rehydratePlan } from "./_actions/plan.actions";
import { AVSResponse } from "./_models/avs";
import { rehydrateAVS } from "./_actions/avs.actions";
import { TaxResponse } from "./_models/tax";
import { DeviceDetectorService } from "ngx-device-detector";

import { rehydrateTax, rehydrateTaxDP, rehydrateTaxMP } from "./_actions/tax.actions";
import { Plan } from "./_models/plan";
import { VerifyAccountResponse } from "./_models/verify-account";
import { rehydrateVerifiedAccount } from "./_actions/verify-account.actions";
import { RatesResponse } from "./_models/rates";
import { rehydrateRates } from "./_actions/rates.actions";
import { EmailInfo } from "./_models/emailInfo";
import { rehydrateEmail } from "./_actions/email.actions";
import { saveUrlParams } from "./_actions/urlParams.actions";
import { URLParameters } from "./_models/urlParams";
import { ConfirmationNumber } from "./_models/confirmation";
import { loadConfirmationNumber } from "./_actions/confirmation.actions";
import { rehydrateMyAccount, rehydratePaymentHistory, rehydratePaylink, resetMyAccount } from "./_actions/account.actions";
import { AccountResponse, PaymentHistory, PaymentHistoryObject, PaylinkResponse, Opportunity, Account } from "./_models/account";
import { account, guest, edit, editAccount } from "./_actions/app-flow-state.actions";
import { ReferenceID } from "./_models/reference-id";
import { saveReferenceID } from "./_actions/reference-id.actions";
import { AppConfigService } from "./appconfig/appconfig.service";
import { environment } from "src/environments/environment";

import { storePrismicPDF, rehydratePrismicPDF } from "./_actions/pdf.actions";
import { PrismicPDF } from "./_models/pdf";
// import LogRocket from 'logrocket';
import { NgwWowService } from "ngx-wow";

import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { ModalTimeoutComponent } from "./modal-timeout/modal-timeout.component";
import { Title } from "@angular/platform-browser";
import { hideInlineLoader, showInlineLoader } from "./_actions/inline-loader.actions";
import { GoogleAnalyticsService } from "./_services/google-analytics";
import { GoogleTagManagerService } from "./_services/google-tag-manager";
import { Product } from "./_models/product";
import { loadProductDisclaimers } from "./_actions/product-disclaimers.actions";
import { PrismicService } from "./_services/prismic.service";
import { loadOpportunity } from "./_actions/opportunity.actions";
import { LoggingService } from "./_services/logging.service";
import { FreshRelevanceService } from "./_services/fresh-relevance.service";
import { sessionEnded } from "./_actions/session.actions";
import { cookiesAccepted, cookiesDeclined } from "./_actions/cookie-acceptance.actions";
import { CookieAcceptanceComponent } from "./cookie-acceptance/cookie-acceptance.component";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { PagesService } from "./_services/pages.service";
import { PaymentOptionMax } from "./_models/payment";
import { setPaymentOptionMax } from "./_actions/payment-options.actions";
import { OktaUser } from "./_models/okta";
import { oktaSignIn } from "./_actions/okta.actions";
import { CookieYesService } from "./_services/cookieyes.service";
import { GoogleMapsService } from "./_services/google-maps.service";
import { LivePersonService } from "./_services/livePerson.service";
import { BrandInfoService } from "./_services/brand-info.service";
import { BrandingInfo } from "./_models/brandingInfo";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeOut, fadeAnimation],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "retailApp";
  isPageLoading: boolean = true;
  leadInformation: Lead;
  customerVehicle: Vehicle;
  customerCart: Cart;
  customerRegistration: Registration;
  custoemrVinDecode: VinDecodeData;
  customerRates: RatesResponse;
  plan: Plan;
  avs: AVSResponse;
  tax: TaxResponse;
  verifiedAccount: VerifyAccountResponse;
  email: EmailInfo;
  urlParams: URLParameters;
  confirmation: ConfirmationNumber;
  account: AccountResponse;
  paymentHistory: PaymentHistoryObject;
  paylink: PaylinkResponse;
  refID: ReferenceID;
  taxMP: TaxResponse;
  taxDP: TaxResponse;
  prismicPDF: PrismicPDF;
  deviceInfo = null;
  modalRef: MDBModalRef;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  isLogin = false;
  cookieAcceptanceSub: Subscription;
  languageDropdown = false;
  currentLangCode: any;
  supportedLangs = [];
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private freshRelevanceService: FreshRelevanceService,
    private router: Router,
    private store: Store<{
      account: Account;
      vehicle: Vehicle;
      cookiesAccepted: boolean;
      brandInfo: BrandingInfo;
    }>,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private deviceService: DeviceDetectorService,
    private modalService: MDBModalService,
    private idle: Idle,
    private keepalive: Keepalive,
    private prismicService: PrismicService,
    private loggingService: LoggingService,
    private translate: TranslateService,
    private pagesService: PagesService,
    private wowService: NgwWowService
  ) {
    // Load Brand from Prismic
    this.appConfig.loadSettings();

    // Initialize Log Rocket
    loggingService.init();

    // this.prismicService.storePrismicRef();

    const navEndEvent$ = router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      setTimeout(() => {
        let superscripts = document.querySelectorAll(".superscript");
        superscripts.forEach((superscript) => {
          superscript.setAttribute("aria-label", "Disclaimer " + superscript.innerHTML); // Add Disclamier aria code to site when this component is used.
        });
        let aTags = document.querySelectorAll("a[href]");
        let anchors = [];
        let blanks = [];
        aTags.forEach((aTag) => {
          let link = aTag.getAttribute("href");
          if (link.includes("#")) {
            let newLink = link.split("#");
            aTag.setAttribute("href", document.location.pathname + document.location.search + "#" + newLink[1]); // Fix anchor tags used in Prismic
            aTag.addEventListener("click", function (e) {
              e.preventDefault();
              window.location.hash = "#" + newLink[1];
            });
            anchors.push(aTag);
          }
        });
        aTags.forEach((aTag) => {
          let link = aTag.getAttribute("target");
          if (link && link === "_blank" && !aTag.hasAttribute("aria-label")) {
            aTag.setAttribute("aria-label", aTag.textContent + " (opens in a new tab)"); // Fix _blank aria label tags for Userway
            blanks.push(aTag);
          }
        });
        console.log("a tags: ", anchors);
        console.log("_blanks: ", blanks);
        this.checkLivePerson();
      }, 1500);
    });

    this.router.events.subscribe((event: Event) => {
      // console.log(event);
      this.loadingInterceptor(event);
    });

    // Load session timer
    this.setIdleTimer();
  }

  checkLivePerson() {
    let chatAvatar = null;
    let checkLP = setInterval(() => {
      chatAvatar = document.querySelector(".LPMimage");
      if (chatAvatar) {
        // Fix alt tags with LivePerson for Userway
        chatAvatar.setAttribute("alt", "Live Person Chat Avatar");
        let helpImage = document.querySelector(".LPMslideOutPin");
        helpImage.setAttribute("alt", "We're here to help");
        clearInterval(checkLP);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.cookieAcceptanceSub) {
      this.cookieAcceptanceSub.unsubscribe();
    }
  }

  setCss() {
    let checkCss = this.appConfig.getCSS();
    if (checkCss) {
      const headEl = this.document.getElementsByTagName("head")[0];
      const css = this.document.createElement("style");
      css.innerHTML = checkCss;
      headEl.appendChild(css);
    }
  }

  setLanguage() {
    let languageList = [];
    if (this.brandInfo.supportedLanguages.length > 0) {
      this.brandInfo.supportedLanguages.forEach((lang) => {
        languageList.push(lang.code);
      });
    } else {
      languageList.push(this.brandInfo.language);
    }

    this.translate.addLangs(languageList);

    // Check to see if language exists in Prismic
    if (this.appConfig.getLanguageJson(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language)) {
      this.translate.setTranslation(
        sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language,
        this.appConfig.getLanguageJson(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language)
      );
    }

    this.translate.setDefaultLang(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language);

    // this.translate.use(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language);

    // console.log("using language: ", this.translate.currentLang,);

    // TODO: Load Browser Language as an enhancement
    // let transLang=[...this.translate.getLangs()];
    // let browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  changeLanguage(langCode) {
    sessionStorage.setItem("preferredLanguage", langCode);
    let path = window.location.href.split("?")[0];
    // location.reload();
    this.document.location.href = path;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
  }

  resetUser() {
    this.isLogin = false;
    this.router.navigate([`${this.brandInfo.homePage}`]);

    setTimeout(() => {
      this.store.dispatch(sessionEnded());
      sessionStorage.clear();
    });
  }

  setIdleTimer() {
    // Check for a session timeout parameter - Need to do it in raw javascript to get the querystring in time for the constructor
    const urlParams = new URLSearchParams(window.location.search);
    const sessiontimeout = parseInt(urlParams.get("sessiontimeout"));

    if (sessiontimeout && sessiontimeout > 0 && sessiontimeout < 71980) {
      console.log("session timeout set to ", sessiontimeout);
      this.idle.setIdle(sessiontimeout); // session timeout set via a parameter to be used for testing
    } else {
      console.log("session timeout set to 19 hours and 40 seconds");
      this.idle.setIdle(71980); // 19 hours and 40 seconds 71980
    }

    // sets a timeout period of 20 seconds. after 71980 seconds (19 hours and 40 seconds) of inactivity, the user will be considered timed out.
    this.idle.setTimeout(20);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer this.idle.";
      console.log(this.idleState);
      this.closeTimeoutModal();
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.showLogoutModal();
      // Run logout functionality
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.showTimeoutModal("countdown");
      this.idle.clearInterrupts();
      console.log(this.idleState);
      //  this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      console.log(this.idleState);
    });

    // sets the ping interval to 10 seconds
    this.keepalive.interval(5 * 2);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  private loadingInterceptor(event: Event) {
    // console.log('Ray')
    // console.log(event);
    if (event instanceof NavigationStart) {
      // console.log('-----');
      // console.log('NavigationStart');
      this.isPageLoading = true;
    }

    if (event instanceof (NavigationEnd || NavigationError || NavigationCancel)) {
      this.isPageLoading = false;
      let link = document.querySelector("#baseFocusLink") as HTMLElement;
      if (link) {
        link.focus();
      }
      // console.log('NavigationEnd||NavigationError||NavigationCancel');
      // console.log('-----');
    }

    if (event instanceof NavigationEnd) {
      this.wowService.init();
    }
  }

  loadTheme(cssFile: string) {
    const headEl = this.document.getElementsByTagName("head")[0];
    const existingLinkEl = this.document.getElementById("client-theme") as HTMLLinkElement;

    if (existingLinkEl) {
      existingLinkEl.href = cssFile;
    } else {
      const newLinkEl = this.document.createElement("link");
      newLinkEl.id = "client-theme";
      newLinkEl.rel = "stylesheet";
      newLinkEl.href = cssFile;
      headEl.appendChild(newLinkEl);
    }
  }

  showBrowserWarning() {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    if (this.deviceInfo.browser === "IE") {
      this.router.navigate(["browser-warning"]);
      this.store.dispatch(hideInlineLoader());
    }
  }

  showTimeoutModal(modalType: String) {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "browser-warning-modal",
      animated: true,
      data: { modalType },
    };

    this.modalRef = this.modalService.show(ModalTimeoutComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
      console.log(result);
      if (result === "keepalive") {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.reset();
        this.closeTimeoutModal();
      } else if (result === "killsession") {
        this.idle.timeout();
      }
    });
  }

  showLogoutModal() {
    if (this.modalRef) {
      this.closeTimeoutModal();
    }
    this.showTimeoutModal("loggedout");
    this.idleState = "Timed out!";
    this.timedOut = true;
    console.log(this.idleState);
    this.resetUser();
  }

  closeTimeoutModal() {
    this.modalRef.hide();
  }

  rehydateStore() {
    let lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    if (lead) {
      this.leadInformation = lead;
      sessionStorage.removeItem("lead");
      this.store.dispatch(rehydrateLead(this.leadInformation));
    }

    let vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    if (vehicle) {
      this.customerVehicle = vehicle;
      sessionStorage.removeItem("vehicle");
      this.store.dispatch(rehydrateVehicle(this.customerVehicle));
    }

    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    if (_rates) {
      this.customerRates = _rates;
      sessionStorage.removeItem("rates");
      this.store.dispatch(rehydrateRates(this.customerRates));
    }

    let cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    if (cart) {
      this.customerCart = cart;
      sessionStorage.removeItem("cart");
      this.store.dispatch(rehydrateCart(this.customerCart));
    }

    let registration: Registration = JSON.parse(sessionStorage.getItem("registration"));
    if (registration) {
      this.customerRegistration = registration;
      sessionStorage.removeItem("registration");
      this.store.dispatch(rehydrateRegistration(this.customerRegistration));
    }

    let vinDecode: VinDecodeData = JSON.parse(sessionStorage.getItem("vinDecode"));
    if (vinDecode) {
      this.custoemrVinDecode = vinDecode;
      sessionStorage.removeItem("vinDecode");
      this.store.dispatch(rehydrateVin(this.custoemrVinDecode));
    }

    let _plan: Plan = JSON.parse(sessionStorage.getItem("plan"));
    if (_plan) {
      this.plan = _plan;
      sessionStorage.removeItem("plan");
      this.store.dispatch(rehydratePlan(this.plan));
    }

    let _email: EmailInfo = JSON.parse(sessionStorage.getItem("email"));
    if (_email) {
      this.email = _email;
      sessionStorage.removeItem("email");
      this.store.dispatch(rehydrateEmail(this.email));
    }

    let _avs: AVSResponse = JSON.parse(sessionStorage.getItem("avs"));
    if (_avs) {
      this.avs = _avs;
      sessionStorage.removeItem("avs");
      this.store.dispatch(rehydrateAVS(this.avs));
    }

    let _tax: TaxResponse = JSON.parse(sessionStorage.getItem("tax"));
    if (_tax) {
      this.tax = _tax;
      sessionStorage.removeItem("tax");
      this.store.dispatch(rehydrateTax(this.tax));
    }

    let _taxDP: TaxResponse = JSON.parse(sessionStorage.getItem("taxDP"));
    if (_taxDP) {
      this.taxDP = _taxDP;
      sessionStorage.removeItem("taxDP");
      this.store.dispatch(rehydrateTaxDP(this.taxDP));
    }

    let _taxMP: TaxResponse = JSON.parse(sessionStorage.getItem("taxMP"));
    if (_taxMP) {
      this.taxMP = _taxMP;
      sessionStorage.removeItem("taxMP");
      this.store.dispatch(rehydrateTaxMP(this.taxMP));
    }

    let _verifiedAccount: VerifyAccountResponse = JSON.parse(sessionStorage.getItem("accountVerification"));
    if (_verifiedAccount) {
      this.verifiedAccount = _verifiedAccount;
      sessionStorage.removeItem("accountVerification");
      this.store.dispatch(rehydrateVerifiedAccount(this.verifiedAccount));
    }

    let _urlParams: URLParameters = JSON.parse(sessionStorage.getItem("urlParams"));
    if (_urlParams) {
      this.urlParams = _urlParams;
      sessionStorage.removeItem("urlParams");
      this.store.dispatch(saveUrlParams(this.urlParams));
    }

    let _confirmation: ConfirmationNumber = JSON.parse(sessionStorage.getItem("confirmationNumber"));
    if (_confirmation) {
      this.confirmation = _confirmation;
      sessionStorage.removeItem("confirmationNumber");
      this.store.dispatch(loadConfirmationNumber(this.confirmation));
    }

    let _account: AccountResponse = JSON.parse(sessionStorage.getItem("account"));
    if (_account) {
      this.account = _account;
      sessionStorage.removeItem("account");
      this.store.dispatch(rehydrateMyAccount(this.account));
    }

    let _paymentHistory: PaymentHistoryObject = JSON.parse(sessionStorage.getItem("paymentHistory"));
    if (_paymentHistory) {
      this.paymentHistory = _paymentHistory;
      sessionStorage.removeItem("paymentHistory");
      this.store.dispatch(rehydratePaymentHistory(this.paymentHistory));
    }

    let _paylink: PaylinkResponse = JSON.parse(sessionStorage.getItem("paylink"));
    if (_paylink) {
      this.paylink = _paylink;
      sessionStorage.removeItem("paylink");
      this.store.dispatch(rehydratePaylink(this.paylink));
    }

    let _refID: ReferenceID = JSON.parse(sessionStorage.getItem("refID"));
    if (_refID) {
      this.refID = _refID;
      sessionStorage.removeItem("refID");
      this.store.dispatch(saveReferenceID(this.refID));
    }

    let _appFlowState = sessionStorage.getItem("appFlowState");
    if (_appFlowState === "account") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(account());
    } else if (_appFlowState === "guest") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(guest());
    } else if (_appFlowState === "edit") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(edit());
    } else if (_appFlowState === "editAccount") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(editAccount());
    }

    let _primsicPDF: PrismicPDF = JSON.parse(sessionStorage.getItem("prismicPDF"));
    if (_primsicPDF) {
      this.prismicPDF = _primsicPDF;
      sessionStorage.removeItem("prismicPDF");
      this.store.dispatch(rehydratePrismicPDF(this.prismicPDF));
    }

    let _productDisclaimers: Product = JSON.parse(sessionStorage.getItem("productDisclaimers"));
    if (_productDisclaimers) {
      sessionStorage.removeItem("productDisclaimers");
      this.store.dispatch(loadProductDisclaimers(_productDisclaimers));
    }

    let _opp: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
    if (_opp) {
      sessionStorage.removeItem("opp");
      this.store.dispatch(loadOpportunity(_opp));
    }

    let _vvc: VerifyVehCustResponse = JSON.parse(sessionStorage.getItem("vvc"));
    if (_vvc) {
      sessionStorage.removeItem("vvc");
      this.store.dispatch(verifyVehCustSuccessContactInformation(_vvc));
    }

    let _session = sessionStorage.getItem("sessionEnded");
    if (_session) {
      sessionStorage.removeItem("sessionEnded");
      this.store.dispatch(sessionEnded());
    }

    let _cookiesAccepted = sessionStorage.getItem("cookiesAccepted");
    if (_cookiesAccepted === "true") {
      sessionStorage.removeItem("cookiesAccepted");
      this.store.dispatch(cookiesAccepted());
    } else if (_cookiesAccepted === "false") {
      sessionStorage.removeItem("cookiesAccepted");
      this.store.dispatch(cookiesDeclined());
    }

    let _paymentOptionsMax: PaymentOptionMax = JSON.parse(sessionStorage.getItem("paymentOptionsMax"));
    if (_paymentOptionsMax) {
      sessionStorage.removeItem("paymentOptionsMax");
      this.store.dispatch(setPaymentOptionMax(_paymentOptionsMax));
    }

    let pages = JSON.parse(sessionStorage.getItem("pagesFlow"));
    if (pages) {
      this.pagesService.setRatingPage(pages.ratingPage);
    }

    let _oktaUser: OktaUser = JSON.parse(sessionStorage.getItem("oktaUser"));
    if (_oktaUser) {
      sessionStorage.removeItem("oktaUser");
      this.store.dispatch(oktaSignIn(_oktaUser));
    }
  }

  initializePage() {
    this.store
      .select((store) => store.account)
      .subscribe((account) => {
        if (account) {
          this.isLogin = true;
        }
      });

    this.store
      .select((store) => store.vehicle)
      .subscribe((vehicle) => {
        // this.freshRelevanceService.loadScriptTag();
      });

    // FollowAnalytics.initialize({
    //     FAID: 'btkyy66SC_LlHw',
    //     bundleId: 'mango',
    //     defaultIcon: 'https://s3-eu-west-1.amazonaws.com/fa-sdks/sdk-web/web-sdk-assets/followanalytics192.png'
    // });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {

        // If the user is on product page, check for query params and store them in session storage
        if (evt.urlAfterRedirects.includes("vehicle-service-protection") || evt.urlAfterRedirects.includes("added-security-plan") || evt.urlAfterRedirects.includes("prepaid-maintenance")) {
          const plan = this.route.snapshot.queryParamMap.get("plan");
          const year = this.route.snapshot.queryParamMap.get("year");
          const mileage = this.route.snapshot.queryParamMap.get("mileage");
          const deductible = this.route.snapshot.queryParamMap.get("deductible");
          if (plan || year || mileage || deductible) {
            window.sessionStorage.setItem("presetProgram", JSON.stringify({ plan: plan, year: year, mileage: mileage, deductible: deductible }));
          }
        }

        // If the user is on the cart page, check for query params and store them in session storage
        if (evt.urlAfterRedirects.includes("pricing") || evt.urlAfterRedirects.includes("cart") || evt.urlAfterRedirects.includes("checkout") || evt.urlAfterRedirects.includes("confirmation") || evt.urlAfterRedirects.includes("my-account")) {
          this.reset();
        }
      }
      setTimeout(() => {
        this.freshRelevanceService.loadScriptTag();
      });
      window.scrollTo(0, 0);
    });
  }

  // Check if url has uri param, if not, redirect to home
  redirectToHome(brandInfo) {
    console.log("redirectToHome: ", this.router.url, brandInfo);

    if(this.router.url === "/") {
      this.router.navigate([`/${brandInfo.homePage}`]);
    }

    // if (this.route.url.value.length === 0) {
    //   this.router.navigate(["/"]);
    // }
  }

  ngOnInit() {
    // Load FR
    this.freshRelevanceService.loadScriptTag(); // Do this first thing to get FR content ASAP

    // Load Brand Info from Store
    this.loadBrandInfo();

    // Show Browser Warning for IE
    this.showBrowserWarning();

    // Rehydrate NgRx Store
    this.rehydateStore();

    // Load FR, Favicon, title, login Boolean, router Nav End
    this.initializePage();
  }

  loadBrandInfo() {
    console.log("loadBrandInfo");

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      // .pipe(last())
      .subscribe((brandInfo) => {
        console.log("Load loadBrandInfo:", brandInfo);


        if (brandInfo) {
          this.brandInfo = brandInfo;

          this.redirectToHome(brandInfo);

          // Load App Settings
          console.log("Load appSettings");
          this.appConfig.getSettings().subscribe((appSettings) => {
            // Set Language and CSS
            this.setLanguageAndCss(appSettings);
          });
        }
      });
  }

  setLanguageAndCss(appSettings) {
    console.log("Checking appSettings:", appSettings);
    if (appSettings.languages && appSettings.languages.length > 0) {
      this.setLanguage();

      setTimeout(() => {
        this.setCss();
      }, 750);
    }
  }

  ngAfterViewInit() {
    // this.cookieAcceptanceSub = this.store
    //   .select((store) => store.cookiesAccepted)
    //   .subscribe((boolean) => {
    //     if (boolean === null) {
    //       const modalOptions = {
    //         class: "modal-bottom",
    //         ignoreBackdropClick: true,
    //         backdrop: false,
    //         animated: true,
    //       };
    //       this.modalRef = this.modalService.show(CookieAcceptanceComponent, modalOptions);
    //     }
    //   });
  }

  //   @HostListener('window:unload', [ '$event' ])
  //     /? "nloadHandler(event) {
  //       localStorage.clear()
  //     }
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    localStorage.clear();

    let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    let _order = JSON.parse(sessionStorage.getItem("order"));

    if (_cart && !_order) {
      // gtag('event', 'User had a cart, but never completed an order', {
      //   event_label: 'Abandoned Cart',
      //   event_category: 'Abandoned Cart',
      // });
    }
  }
}
