import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { BrandingInfo } from 'src/app/_models/brandingInfo';
import { HomeService } from 'src/app/_services/home.service';
import { PrismicService } from 'src/app/_services/prismic.service';


@Component({
  selector: 'app-footer-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutFooterComponent implements OnInit {

  footer: any;
  branding: any;
  isFooterVisible = true;
  currentBrand: string;
  brandInfo: BrandingInfo;
brandInfoSub: Subscription;
  constructor(
    private home: HomeService,
    private appConfig: AppConfigService,
    private prismicService: PrismicService,
    private store: Store<{
      brandInfo: BrandingInfo
    }>
    ) { }

  ngOnInit() {


    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
      this.currentBrand = brandInfo.brand;

      this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
        next: (result) => {
          this.footer = result;
        },
        error: (error) => {
          this.isFooterVisible = false;
        }
      });

    });





    this.appConfig.getSettings().subscribe({
      next: (result) => {
        this.branding = result;
      }, error: (err) => {
        this.isFooterVisible = false;
      }
    });
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
