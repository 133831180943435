import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { oktaSignOut, resetOktaUser } from "src/app/_actions/okta.actions";
import { BrandingInfo } from "src/app/_models/brandingInfo";
import { OktaUser } from "src/app/_models/okta";
import { Vehicle } from "src/app/_models/vehicle";
import { HomeService } from "src/app/_services/home.service";
import { PagesService } from "src/app/_services/pages.service";

@Component({
  selector: "app-mobile-nav",
  templateUrl: "./mobile-nav.component.html",
  styleUrls: ["./mobile-nav.component.scss"],
})
export class MobileNavComponent implements OnInit {
  @Input() logo: any;
  @Input() isLogin: boolean;
  @Output("callbackSignOut") callbackSignOut: EventEmitter<any> = new EventEmitter();
  hasProductsMenu = false;
  oktaUserSub: Subscription;
  brandInfoSub: Subscription;
  oktaId: any;
  access_token: any;
  brandInfo: BrandingInfo;

  constructor(
    private home: HomeService,
    private pageService: PagesService,
    private store: Store<{
      oktaUser: any;
      brandInfo: BrandingInfo;
    }>
  ) {}

  ngOnInit(): void {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;
          if (this.brandInfo.brand === "NCESI") {
            // Nissan Canada has products dropdown menu
            this.hasProductsMenu = true;
          }
        }
      });

    this.oktaUserSub = this.store
      .select((store) => store.oktaUser)
      .subscribe((data) => {
        if (data !== null && data !== false) {
          this.oktaId = data.id;
          this.access_token = data.access_token;
        } else if (data === false) {
          this.callbackSignOut.emit();
          this.store.dispatch(resetOktaUser());
        }
      });
  }

  goToHelp() {
    if (this.brandInfo.brand == "NCESI") {
      this.pageService.redirectTo("/help");
    } else {
      this.pageService.redirectTo("/help-center");
    }
  }

  redirectTo(uri) {
    this.pageService.redirectTo(uri);
  }

  redirectToHome() {
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    if (_vehicle.isElectric) {
      this.pageService.redirectTo(`/${this.brandInfo.homePageElectric}`);
    } else {
      this.pageService.redirectTo(`/${this.brandInfo.homePage}`);
    }
  }

  closeNav() {
    document.getElementById("mySidenav").style.marginRight = "-300px";
    setTimeout(() => {
      document.getElementById("mySidenav").style.display = "none";
    }, 400);
  }

  signOut(): void {
    let object: OktaUser = {
      id: this.oktaId,
      login: "",
      access_token: this.access_token,
    };
    this.store.dispatch(resetOktaUser());
    this.store.dispatch(oktaSignOut(object));
  }
}
