<div class="row">
  <div class="col-12">
    <div *ngFor="let searchItem of searchData" >
      <h4 [routerLink]="[resolveUid(searchItem.page)]" >{{searchItem.title}}</h4>
    </div>
    <div *ngIf="searchData.length === 0 && searchParam !== ''">
      <p>No results were found based on the words you are searching.</p>
    </div>
  </div>
</div>
