import { hideInlineLoader } from "../_actions/inline-loader.actions";
import { loadBrandInfo, loadBrandInfoSuccess } from "../_actions/branding-info.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, Observable, of } from "rxjs";
import { map, switchMap, mergeMap, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { BrandInfoService } from "../_services/brand-info.service";
import { BrandingInfo } from "../_models/brandingInfo";
import { Store } from "@ngrx/store";

@Injectable()
export class BrandInfoEffects {
  constructor(
    private actions$: Actions,
    private brandInfoService: BrandInfoService,
    private store: Store<{}>
  ) {}

  public loadBrandInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadBrandInfo),
      switchMap((action) => {

        console.log('consolidated - action ', action);

        return this.brandInfoService.getBrandFromAPI(action.url).pipe(
          map((brandInfoRespone) => {
            let brandInfo = brandInfoRespone as BrandingInfo;

            // Load BrandInfo Data to Store
            return loadBrandInfoSuccess(brandInfo);
          }),
          catchError((error) => {
            this.store.dispatch(hideInlineLoader());
            return EMPTY;
          })
        );
      })
    );
  });
}
