import { hideInlineLoader } from "./../_actions/inline-loader.actions";
import { loadPrismic } from "./../_actions/prismic.actions";

import { Actions, createEffect, ofType, Effect } from "@ngrx/effects";
import { of, EMPTY, throwError, Subscription } from "rxjs";

import { map, mergeMap, switchMap, mapTo, catchError } from "rxjs/operators";
import { ProspectService } from "../_services/prospect-service.service";
import {
  leadLoaded,
  leadLoadedOpportunity,
  leadUpdated,
  loadLeadOpportunity,
  loadLeadOpportunityContactInformation,
} from "../_actions/lead.actions";
import * as ActionConfigurationManager from "../_actions/config.actions";
import { Vehicle, VerifyVehCustResponse } from "../_models/vehicle";
import { loadVehicle, loadVehicleImage } from "../_actions/vehicle.actions";
import { Store } from "@ngrx/store";
import { RatesRequest, RatesResponse } from "../_models/rates";
import { loadRatesHappy } from "../_actions/rates.actions";
import { getCart, updateCart } from "../_actions/cart.actions";
import {
  hideInlineLoaderPayment,
  hideInlineLoaderContact,
  hideInlineLoaderBilling,
} from "../_actions/inline-loader.actions";
import {
  updateContactStateOn,
  accountUpdated,
} from "../_actions/account.actions";
import { AccountResponse, Opportunity } from "../_models/account";
import { Injectable } from "@angular/core";
import { LoggingService } from "../_services/logging.service";
import { prismicLandingPage } from "../_models/prismic";
import { guest } from "../_actions/app-flow-state.actions";
import { Cart } from "../_models/cart";
import { loadOpportunity } from "../_actions/opportunity.actions";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable()
export class LeadEffects {
  customerVehicle: Vehicle = {
    isElectric: false,
    make: "",
    makeExternalId: "",
    makeSfId: "",
    mileage: 0,
    model: "",
    modelExternalId: "",
    modelSfId: "",
    modelNumber: "",
    purchaseDate: "",
    inserviceDate: "",
    mfrWarrantyDate: "",
    deliveryDate: "",
    exteriorColor: "",
    referenceNumber1: "",
    referenceNumber2: "",
    referenceNumber3: "",
    referenceNumber4: "",
    trim: "",
    registeredState: "",
    trimExternalId: "",
    trimSfId: "",
    vehicleCondition: "",
    vehicleExternalId: "",
    vehicleSfId: "",
    vehicleType: "",
    vin: "",
    year: "",
  };

  public saveLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.LOAD_LEAD),
      switchMap((action: any) =>
        this.leadService.get_accountContact(action).pipe(
          map((lead) => {
            console.log("Lead Effect: Lead", lead);
            this.customerVehicle = this.createLeadObject(lead);
            this.store.dispatch(loadVehicle(this.customerVehicle));
            this.store.dispatch(loadVehicleImage(this.customerVehicle));
            // this.store.dispatch(getCart(lead));
            let opp: Opportunity = {
              opportunityId: lead.shoppingCartExternalId,
            };
            this.store.dispatch(loadOpportunity(opp));
            this.store.dispatch(leadLoaded(lead));
            let newPayload = { ...action.payload, oid: lead.shoppingCartExternalId }
            console.log('action.payload', newPayload);
            this.loggingService.storeLogRocket(lead, newPayload);

            const prismicLandingPage: prismicLandingPage = {
              brand: this.brandInfo.brand,
              pageType: action.payload.pageType,
              product: action.payload.product,
              lang: action.payload.lang,
              isElectric: lead.vehicle.isElectric ? "electric" : "",
              pageId: action.payload.pageId,
              pageurl: action.payload.pageurl,
              parenturl: action.payload.parenturl,
            };

            console.log("prismicLandingPage: ", prismicLandingPage);

            return loadPrismic(prismicLandingPage);
          }),
          catchError((error: Error) => {
            LoggingService.captureLogRocketError(error, "Prismic", "Error loading Prismic.", this.brandInfo);
            console.error("Error loading prismic", error, action);
            this.store.dispatch(guest());

            const prismicLandingPage: prismicLandingPage = {
              brand: this.brandInfo.brand,
              pageType: "home",
              product: "",
              lang: sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language,
              isElectric: "",
              pageId: "",
              pageurl: "",
              parenturl: ""
            };

            this.store.dispatch(loadPrismic(prismicLandingPage));

            return throwError(error);

            // return EMPTY;
          })
        )
      )
    )
  );



  public updateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.UPDATE_LEAD),
      switchMap((action: any) =>
        this.leadService.update_accountContact(action).pipe(
          map((lead) => {
            let _account: AccountResponse = JSON.parse(
              sessionStorage.getItem("account")
            );

            _account.data[0].customer.emailId = action.emailId;
            _account.data[0].customer.phoneNumber1 = action.phoneNumber1;
            _account.data[0].customer.phoneNumber2 = action.phoneNumber2;

            this.store.dispatch(accountUpdated(_account));
            this.store.dispatch(updateContactStateOn());
            return (
              hideInlineLoaderPayment() &&
              hideInlineLoaderContact() &&
              hideInlineLoaderBilling()
            );
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public saveLeadOpp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLeadOpportunity),
      switchMap((action: any) =>
        this.leadService.get_opportunity(action).pipe(
          map((lead) => {
            console.log("Lead Effect Opportunity: Lead", lead);
            this.customerVehicle = this.createLeadObject(lead);

            this.store.dispatch(loadVehicle(this.customerVehicle));
            this.store.dispatch(loadVehicleImage(this.customerVehicle));
            // this.store.dispatch(getCart(lead));
            this.store.dispatch(leadLoadedOpportunity(lead));
            let opp: Opportunity = {
              opportunityId: action.payload.oid,
            };
            this.store.dispatch(loadOpportunity(opp));
            console.log('Payload:', action.payload)
            this.loggingService.storeLogRocket(lead, action.payload);

            const prismicLandingPage: prismicLandingPage = {
              brand: this.brandInfo.brand,
              pageType: action.payload.pageType,
              product: action.payload.product,
              lang: action.payload.lang,
              isElectric: lead.vehicle.isElectric ? "electric" : "",
              pageId: action.payload.pageId,
              pageurl: action.payload.pageurl,
              parenturl: action.payload.parenturl,
            };

            console.log("prismicLandingPage: ", prismicLandingPage);

            return loadPrismic(prismicLandingPage);
          }),
          catchError((error: Error) => {
            LoggingService.captureLogRocketError(error, "Error", action.payload, this.brandInfo);
            console.error("Error loading prismic", error, action);
            this.store.dispatch(guest());

            const prismicLandingPage: prismicLandingPage = {
              brand: this.brandInfo.brand,
              pageType: "home",
              product: "",
              lang: sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : this.brandInfo.language,
              isElectric: "",
              pageId: action.payload.pageId,
              pageurl: action.payload.pageurl,
              parenturl: action.payload.parenturl,
            };

            this.store.dispatch(loadPrismic(prismicLandingPage));

            return throwError(error);

            // return EMPTY;
          })
        )
      )
    )
  );

  public saveLeadOppContactInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLeadOpportunityContactInformation),
      switchMap((action: any) =>
        this.leadService.get_opportunity(action).pipe(
          map((lead) => {
            console.log("Lead Effect Opportunity: Lead", lead);
            let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
            let newCart: Cart = _cart;
            newCart.shoppingCartId = lead.shoppingCartExternalId;
            newCart.shoppingCartData.vehicleSfId = lead.vehicle.vehicleSfId

            this.store.dispatch(updateCart(newCart));

            return leadLoadedOpportunity(lead);
          }),
          catchError((error: Error) => {
            console.error("Error loading prismic", error);
            this.store.dispatch(guest());
            return throwError(error);
          })
        )
      )
    )
  );

  private createLeadObject (lead) {
    console.log('lead', lead)
    let customerVehicle = new Vehicle();
    customerVehicle.isElectric = lead.vehicle.isElectric;
    customerVehicle.vehicleExternalId =
      lead.vehicle.vehicleExternalId;
    customerVehicle.vehicleSfId = lead.vehicle.vehicleSfId;
    customerVehicle.make = lead.vehicle.make;
    customerVehicle.makeExternalId = lead.vehicle.makeExternalId;
    customerVehicle.makeSfId = lead.vehicle.makeSfId;
    customerVehicle.inserviceDate = lead.vehicle.inserviceDate;
    customerVehicle.deliveryDate = lead.vehicle.deliveryDate;
    customerVehicle.exteriorColor = lead.vehicle.exteriorColor;
    customerVehicle.purchaseDate = lead.vehicle.purchaseDate;
    customerVehicle.mfrWarrantyDate = lead.vehicle.mfrWarrantyDate;
    customerVehicle.mileage = lead.vehicle.mileage;
    customerVehicle.model = lead.vehicle.model;
    customerVehicle.modelNumber = lead.vehicle.modelNumber;
    customerVehicle.modelExternalId = lead.vehicle.modelExternalId;
    customerVehicle.modelSfId = lead.vehicle.modelSfId;
    customerVehicle.referenceNumber1 =
      lead.vehicle.referenceNumber1;
    customerVehicle.referenceNumber2 =
      lead.vehicle.referenceNumber2;
    customerVehicle.referenceNumber3 =
      lead.vehicle.referenceNumber3;
    customerVehicle.referenceNumber4 =
      lead.vehicle.referenceNumber4;
    customerVehicle.trim = lead.vehicle.trim;
    customerVehicle.trimExternalId = lead.vehicle.trimExternalId;
    customerVehicle.trimSfId = lead.vehicle.trimSfId;
    customerVehicle.vehicleCondition =
      lead.vehicle.vehicleCondition;
    customerVehicle.vehicleType = lead.vehicle.vehicleType;
    customerVehicle.vin = lead.vehicle.vin;
    customerVehicle.year = lead.vehicle.year;

    return customerVehicle;
}

brandInfo: BrandingInfo;
brandInfoSub: Subscription;
  constructor(
    private actions$: Actions,
    private leadService: ProspectService,
    private loggingService: LoggingService,
    public store: Store<{
      brandInfo: BrandingInfo
    }>
  ) {

    this.brandInfoSub = this.store.select((store) => store.brandInfo).subscribe((brandInfo) => {
      this.brandInfo = brandInfo;
    });
  }
}
