<div class="modal-content modal-timeout">
  <div class="modal-header mb-n1">
    <span aria-hidden="true" *ngIf="modalType == 'countdown'"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="keepSession()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body p-0">
    <div class="row pl-3 pr-3 mt-4">
      <div *ngIf="modalType == 'countdown'" class="col-12 text-center" tabindex="0" role="alert" aria-live="assertive">
        <h2 class="mb-4">Are you still there?</h2>
        <p class="mb-5">Your session is about to expire</p>
        <div class="d-flex justify-content-center mb-5">
          <button role="button" mdbBtn class="btn cancel-btn" (click)="killSession()">No, I don't need more time</button>&nbsp;&nbsp;
          <button role="button" mdbBtn class="btn primary-btn" (click)="keepSession()">Yes, I'm here</button>
        </div>
      </div>
      <div *ngIf="modalType != 'countdown'" class="col-12 text-center" tabindex="0" role="alert" aria-live="assertive">
        <h2 class="mb-4">session expired</h2>
        <p class="mb-5">For your security, we have logged you out.</p>
        <div class="d-flex justify-content-center mb-5">
          <button role="button" mdbBtn class="btn primary-btn" (click)="renew()">Renew Session</button>
        </div>
      </div>
    </div>
  </div>
</div>
