import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { BrandingInfo } from "../_models/brandingInfo";

@Injectable({
  providedIn: "root",
})
export class CssLoaderService {
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private store: Store<{
      brandInfo: BrandingInfo;
    }>
  ) {
    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.brandInfo = brandInfo;
          console.log("consolidated - CssLoaderService brandInfo: ", brandInfo);
        }
      });
  }

  loadScriptTag(brandInfo): void {
    if (brandInfo) {
      const head = document.getElementsByTagName("head")[0];
      const cssTag = document.createElement("link");
      cssTag.id = "client-theme";
      cssTag.rel = "stylesheet";
      cssTag.href = `${this.brandInfo.css}`;

      head.appendChild(cssTag);

      console.log("loadScriptTag head: ", head);
    }
  }
}
