import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-modal-technical-difficulty-error',
  templateUrl: './modal-technical-difficulty-error.component.html',
  styleUrls: ['./modal-technical-difficulty-error.component.scss']
})
export class ModalTechnicalDifficultyComponent implements OnInit {

  title: string;
  subheader: string;

  constructor(    public modalRef: MDBModalRef,
    ) { }

  ngOnInit(): void {
    this.title = 'We have a little problem';
    this.subheader = 'Looks like something went completely wrong! Please wait a few minutes before you try again.';
  }
}
