import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Cart } from "../../_models/cart";
import { Vehicle } from "../../_models/vehicle";
import { Store } from "@ngrx/store";

import { Plan } from "src/app/_models/plan";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { TranslateService } from "@ngx-translate/core";
import { BrandingInfo } from "src/app/_models/brandingInfo";

@Component({
  selector: "app-navbar-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
  exportAs: "cartModal",
})
export class CartComponent implements OnInit {
  public isCart: boolean = false;
  public cart$: Observable<Cart>;
  public vehicle$: Observable<Vehicle>;
  plan$: Observable<Plan>;
  vehicleVin: any;
  currentDate: Date;
  cartSubscription: Subscription;
  vehicleSubscription: Subscription;
  @ViewChild("cart") cart;
  isBrand: string;
  branding;
  term_length_tooltip = "";
  mileage: any;
  brandInfo: BrandingInfo;
  brandInfoSub: Subscription;

  constructor(
    private store: Store<{
      vehicle: Vehicle;
      cart: Cart;
      plan: Plan;
      brandInfo: BrandingInfo;
    }>,
    private appConfig: AppConfigService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.vehicle$ = this.store.select((store) => store.vehicle);
    this.cart$ = this.store.select((store) => store.cart);
    this.plan$ = this.store.select((store) => store.plan);
    this.currentDate = new Date();
    this.vehicleSubscription = this.vehicle$.subscribe((vehicle) => {
      if (vehicle) {
        this.maskVin(vehicle.vin.toUpperCase());
      }
    });

    this.cartSubscription = this.cart$.subscribe((cart) => {
      // console.log('cart: ', cart)
      if (cart) {
        if (cart.plans.length === 0) {
          this.isCart = false;
        } else {
          this.isCart = true;
        }
      }
    });

    this.appConfig.getSettings().subscribe((result) => {
      this.term_length_tooltip = this.appConfig.getTermLengthTooltip();
    });

    this.brandInfoSub = this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        this.brandInfo = brandInfo;
        this.isBrand = brandInfo.brand;
      });

    this.translate.get("GENERIC.MILEAGE_PLURAL").subscribe((mileage) => {
      this.mileage = mileage.toLowerCase();
    });
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
    this.vehicleSubscription.unsubscribe();
  }

  maskVin(vin) {
    let endVin = vin.substring(11, 17);
    console.log(endVin);
    this.vehicleVin = "***********" + endVin;
  }

  show() {
    this.cart.show();
    setTimeout(() => {
      document.getElementById("shopping-cart-panel").focus();
    }, 300);
  }
}
