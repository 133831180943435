import { Injectable } from "@angular/core";



@Injectable({
  providedIn: "root",
})
export class LocationService {
  getAllUSStates() {
    return [
      { value: "", label: "" }, // Needed for an issue with MacOS Safari browser
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "DC", label: "District of Columbia" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
    ];
  }

  getCountryData(brandInfo) {
    if ((sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).includes('fr')) {
      return [
        { value: "", label: "" }, // Needed for an issue with MacOS Safari browser
        { label: "Canada", value: "CAN" },
        { label: "les États-Unis d'Amérique", value: "USA" },
      ];
    } else {
      return [
        { value: "", label: "" }, // Needed for an issue with MacOS Safari browser
        { label: "Canada", value: "CAN" },
        { label: "United States of America", value: "USA" },
      ];
    }
  }

  getStateDataFromToggle(countryCode, brandInfo) {
    let stateData;
    if (countryCode == "CAN") {
      stateData = this.getAllCanadaProvinces(brandInfo);
    } else if (countryCode == "USA") {
      stateData = this.getAllUSStates();
    }

    return stateData;
  }

  getStateData(brandInfo){

    let stateData;
    if (brandInfo.country == "CAN") {
      stateData = this.getAllCanadaProvinces(brandInfo);
    } else if (brandInfo.country == "USA") {
      stateData = this.getAllUSStates();
    }

    return stateData;
  }

  getCountry(brandInfo){
    return brandInfo.country;
  }

  getUSStatesForTesting() {
    let stateData;
    stateData = this.getAllUSStates();
    return stateData;
  }

  getAllCanadaProvinces(brandInfo) {
    if ((sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).includes('fr')) {
      return [
        { value: "", label: "" }, // Needed for an issue with MacOS Safari browser
        { label: "Alberta", value: "AB" },
        { label: "Colombie-Britannique", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "Nouveau-Brunswick", value: "NB" },
        { label: "Terre-Neuve-et-Labrador", value: "NL" },
        { label: "Territoires du Nord-Ouest", value: "NT" },
        { label: "Nouvelle-Écosse", value: "NS" },
        { label: "Nunavut", value: "NU" },
        { label: "Ontario", value: "ON" },
        { label: "Île-du-Prince-Édouard", value: "PE" },
        { label: "Québec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon Territoire", value: "YT" },
      ];
    } else {
      return [
        { value: "", label: "" }, // Needed for an issue with MacOS Safari browser
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Nunavut", value: "NU" },
        { label: "Ontario", value: "ON" },
        { label: "Prince Edward Island", value: "PE" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon Territory", value: "YT" },
      ];
    }

  }
}
