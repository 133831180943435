<!-- MEET MY VEHICLE MODAL -->
<div class="modal-content modal-verify-vehicle">
  <div class="modal-header mb-n1">
    <app-close-button (callbackFunction)="refreshState()"></app-close-button>
  </div>
  <div class="modal-body">
    <div>
      <div *ngIf="(appFlowState$ | async) === 'account' || (appFlowState$ | async) === 'editAccount'">
        <span role="alert" tabindex="0" id="verifyYourVehicle">
          <h2 class="text-center" role="heading" [innerHTML]="titleText | safeHtml"></h2>
          <p class="text-center">{{ 'ASP_LANDING.SELECT_STATE_INFO' | translate }}</p>
        </span>
        <div class="col-12">
          <div class="alert alert-warning w-100 text-center" role="alert"
            *ngIf="(modalComponentState$ | async) === 'error'">
            <span *ngIf="currentBrand != 'NCESI'">
              {{ 'ERRORS.NO_ELIGIBLE_PRODUCTS' | translate }} {{ branding.cs_phone_number }}
            </span>
            <span *ngIf="currentBrand == 'NCESI'">
              {{ 'ERRORS.NEED_MORE_INFO' | translate }} {{ branding.cs_phone_number }}
            </span>
          </div>
          <div class="alert alert-warning w-100 text-center" role="alert"
            *ngIf="(modalComponentState$ | async) === 'invalidState'">
            {{ 'ERRORS.NO_PRODUCTS_STATE' | translate }} {{branding.cs_phone_number}}
          </div>
          <div class="alert alert-warning w-100 text-center" role="alert"
            *ngIf="(modalComponentState$ | async) === 'productExist'">
            {{ 'ERRORS.CONTRACT_EXISTS' | translate }} {{branding.cs_phone_number}}
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 pr-4 pl-4">
            <div class="loading-modal" *ngIf="(inlineLoaderModal$ | async)">
              <!--Big blue-->
              <div class="preloader-wrapper active indicator">
                <div class="spinner-layer spinner-client-color">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="gap-patch">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="view mb-lg-0" *ngIf="(vehicle$ | async) as vehicle" [ngClass]="{'hide': vehicle.image}">
              <img *ngIf="vehicle.image" class="img-fluid"
                [src]="vehicle.image"
                alt="{{ vehicle.year }}  {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}" />
            </div>
          </div>
          <div *ngIf="(vehicle$ | async) as vehicle" style="width:100%">
            <div class="col-12 text-center">
              <p>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}</p>
            </div>
          </div>
          <div class="col-12" *ngIf="(vehicle$ | async) as vehicle">
            <p class="pl-3 pr-3 text-center" role="region" tabindex="0">
              <!-- <strong>Registered in {{ lead.city }}, {{ lead.state }}</strong> -->
              <strong>{{ 'ASP_LANDING.VIN' | translate }}: {{ vehicleVin }}</strong>
            </p>
            <form class="text-center p-3" [formGroup]="modelFormValidation" role="form">
              <div class="row">
                <div class="col-12">
                  <div class="md-form w-100">
                    <label class="no-touch-label" id="stateLabelModal" for="registeredStateid"
                      [ngClass]="{'active':modelFormValidation.controls.registeredState.value != null}">{{ 'ASP_LANDING.STATE' | translate }}</label>
                    <!-- <mdb-select  role="listbox" aria-label="State (where your vehicle is registered)" (opened)="fixSelectAria($event)" tabindex="0" [options]="stateData" formControlName="registeredState" id="registeredState" (selected)="stateSelected()" mdbInput mdbValidate placeholder="Registered in... *" ></mdb-select> -->
                    <select role="listbox" aria-labelledby="stateLabelModal" id="registeredStateid" class="form-control"
                      formControlName="registeredState" (change)="stateSelected()" placeholder="Registered in... *">
                      <option *ngFor="let state of stateData" value="{{state.value}}">{{state.label}}</option>
                    </select>
                    <!-- <ng-autocomplete
                        mdbInput
                        mdbValidate
                        [data]="stateData"
                        (selected)="stateSelected($event)"
                        [searchKeyword]="stateKeyword"
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        [(ngModel)]='leadState'
                        [ngModelOptions]="{standalone: true}"
                        class="form-control"
                        placeHolder="Registered in... *"
                        id="vehicleState">
                      </ng-autocomplete>

                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template> -->
                    <mdb-error role="alert"
                      *ngIf="modelFormValidation.controls.registeredState.invalid &&
                       (modelFormValidation.controls.registeredState.dirty || modelFormValidation.controls.registeredState.touched)">*{{ 'ASP_LANDING.STATE_REQUIRED' | translate }}</mdb-error>
                  </div>
                </div>
                <div class="col-12">
                  <div class="md-form w-100">
                    <label for="odometer">{{ 'ASP_LANDING.CURRENT_MILEAGE' | translate }} <app-tooltip *ngIf="odometerTooltip" message="{{odometerTooltip}}"></app-tooltip></label>
                    <input data-public mdbInput mdbValidate type="text" id="odometer" formControlName="odometer"
                      class="form-control" (keypress)="onlyNumberKey($event)" (focus)="removeCommas()"
                      (change)="removeCommas()" (blur)="addCommas()" max="100000" />
                    <mdb-error role="alert" *ngIf="modelFormValidation.controls.odometer.invalid && (modelFormValidation.controls.odometer.dirty
                   || modelFormValidation.controls.odometer.touched)">*{{ 'ASP_LANDING.MILEAGE_REQUIRED' | translate }}</mdb-error>
                    <div class="invalid-feedback" for="odometer">
                      Please enter your odometer.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row w-100 pb-2">
                <div class="col-12">
                  <button id="modal-component-see-pricing-btn" mdbBtn class="btn primary-btn waves-light" mdbWavesEffect
                    type="submit" [disabled]="!modelFormValidation.valid || (inlineLoader$ | async)"
                    (click)="vehicleNotEdited()">
                    <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false">{{buttonText}}
                      <!-- <mdb-icon fas icon="arrow-right"></mdb-icon> -->
                    </span>
                    <span *ngIf="(inlineLoader$ | async)">
                      <span class="spinner-border spinner-border-sm"></span>
                      {{ 'ASP_LANDING.LOADING' | translate }}
                    </span>
                  </button>
                </div>
                <div class="col-12" class="change-vehicle"> {{ 'ASP_LANDING.NOT_VEHICLE' | translate }}
                  <a role="link" href="javascript:void(0)" class="" (click)="editVehicle()" (click)="view ='2'"> {{ 'ASP_LANDING.CHANGE_VEHICLE' | translate }}</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="(appFlowState$ | async) === 'guest' || (appFlowState$ | async) === 'edit'" class="vehicle-edit ">
        <div class="col-12 text-center pb-3">
          <span role="alert" tabindex="0" id="verifyYourVehicle2">
            <h2 class="text-center" role="heading" [innerHTML]="titleText | safeHtml"></h2>
            <p class="text-center">{{ 'ASP_LANDING.ENTER_YOUR_INFO' | translate }}</p>
          </span>
        </div>
        <div class="row">
          <div class="col-12">
            <form class="row" [formGroup]="editVehicleVINForm">
              <div class="col-12">
                <div class="alert alert-warning w-100 text-center" role="alert"
                  *ngIf="(modalComponentState$ | async) === 'error'">
                  <span *ngIf="currentBrand != 'NCESI'">
                    {{ 'ERRORS.NO_ELIGIBLE_PRODUCTS' | translate }} {{ branding.cs_phone_number }}
                  </span>
                  <span *ngIf="currentBrand == 'NCESI'">
                    {{ 'ERRORS.NEED_MORE_INFO' | translate }} {{ branding.cs_phone_number }}
                  </span>
                </div>
                <div class="alert alert-warning w-100 text-center" role="alert"
                  *ngIf="(modalComponentState$ | async) === 'invalidState'">
                  {{ 'ERRORS.NO_PRODUCTS_STATE' | translate }} {{branding.cs_phone_number}}
                </div>
                <div class="alert alert-warning w-100 text-center" role="alert"
                  *ngIf="(modalComponentState$ | async) === 'productExist'">
                  {{ 'ERRORS.CONTRACT_EXISTS' | translate }} {{branding.cs_phone_number}}
                </div>
                <div class="alert alert-warning w-100 text-center" role="alert"
                  *ngIf="(verifyVin$ | async) === 'failed'">
                  {{ 'ERRORS.NO_OFFER' | translate }}
                </div>
              </div>
              <div class="col-12">
                <div class="md-form w-100">
                  <label id="vinlabel" for="vin">{{ 'ASP_LANDING.VIN' | translate }}</label>
                  <input aria-labelledby="vinlabel" mdbInput mdbValidate type="text" id="vin_modal"
                    formControlName="editVehicleVIN" class="form-control"
                    minlength="17" maxlength="17" />

                  <mdb-error role="alert"
                    *ngIf="editVehicleVINForm.controls.editVehicleVIN.invalid && (editVehicleVINForm.controls.editVehicleVIN.dirty || editVehicleVINForm.controls.editVehicleVIN.touched)">
                    <span *ngIf="editVehicleVINForm.controls.editVehicleVIN.errors.required">*{{ 'ASP_LANDING.VIN_REQUIRED' | translate }}</span>
                    <span *ngIf="editVehicleVINForm.controls.editVehicleVIN.errors.minlength">*{{ 'ASP_LANDING.VALID_VIN' | translate }}</span>
                  </mdb-error>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="md-form w-100">
                  <label id="odometerlabel" for="editVehicleOdometerVINModal">{{ 'ASP_LANDING.CURRENT_MILEAGE' | translate }} <app-tooltip *ngIf="odometerTooltip" message="{{odometerTooltip}}"></app-tooltip></label>
                  <input data-public aria-labelledby="odometerlabel" mdbInput mdbValidate type="text" id="editVehicleOdometerVINModal"
                    formControlName="editVehicleOdometerVIN" class="form-control" (keypress)="onlyNumberKey($event)"
                    (change)="removeCommasVIN()" (focus)="removeCommasVIN()" (blur)="addCommasVIN()" />
                  <mdb-error role="alert" *ngIf="editVehicleVINForm.controls.editVehicleOdometerVIN.invalid && (editVehicleVINForm.controls.editVehicleOdometerVIN.dirty
                    || editVehicleVINForm.controls.editVehicleOdometerVIN.touched)">*{{ 'ASP_LANDING.MILEAGE_REQUIRED' | translate }}</mdb-error>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="md-form w-100">
                  <label class="no-touch-label" id="statelabelModel2" for="state"
                    [ngClass]="{'active':editVehicleVINForm.controls.editVehicleRegisteredStateVIN.value != null}"> {{ 'ASP_LANDING.STATE' | translate }}</label>
                  <!-- <mdb-select aria-labelledby="statelabelModal2" [options]="stateData" id="state" formControlName="editVehicleRegisteredStateVIN" (opened)="fixSelectAria($event)" (selected)="stateSelectedVIN($event)"></mdb-select> -->
                  <select role="listbox" aria-labelledby="statelabelModel2" id="state" class="form-control"
                    formControlName="editVehicleRegisteredStateVIN" (change)="stateSelectedVIN($event)">
                    <option *ngFor="let state of stateData" value="{{state.value}}">{{state.label}}</option>
                  </select>
                  <mdb-error role="alert" *ngIf="editVehicleVINForm.controls.editVehicleRegisteredStateVIN.invalid && (editVehicleVINForm.controls.editVehicleRegisteredStateVIN.dirty
                    || editVehicleVINForm.controls.editVehicleRegisteredStateVIN.touched)">*{{ 'ASP_LANDING.STATE_REQUIRED' | translate }}</mdb-error>
                </div>
              </div>
              <div class="row w-100 pb-2 pt-4">
                <!-- <div class="col-md-6 col-12">
                  <a class="btn secondary-btn waves-light shadow-sm float-left" mdbBtn mdbWavesEffect (click)="refreshState()" (click)="modalRef.hide()"><mdb-icon far icon="edit"></mdb-icon> Close</a>
                </div> -->
                <div class="col-12 text-center">
                  <!-- <button id="testButton" mdbBtn class="btn primary-btn waves-light w-100" mdbWavesEffect type="submit" [disabled]="!modelFormValidation.valid" onclick="dataLayer.push({'event': 'testing'})" (click)="vehicleNotEdited()">See Pricing <mdb-icon fas icon="arrow-right"></mdb-icon></button> -->
                  <button mdbBtn class="btn primary-btn waves-light" mdbWavesEffect type="submit"
                    (click)="vehicleEditedVIN()"
                    [disabled]="!editVehicleVINForm.valid || (inlineLoader$ | async) ">
                    <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false">{{buttonText}}
                      <!-- <mdb-icon fas icon="arrow-right"></mdb-icon> -->
                    </span>
                    <span *ngIf="(inlineLoader$ | async)">
                      <span class="spinner-border spinner-border-sm"></span>
                      {{ 'ASP_LANDING.LOADING' | translate }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
