import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { verifyAccount, accountVerified, rehydrateVerifiedAccount, resetVerifiedAccount, invalidAccount, verifyAccountOktaFlow, verifyAccountOktaFlowSuccess, verifyAccountOktaFlowFailed } from '../_actions/verify-account.actions';


const _verifyAccountReducer = createReducer(initialState,
    on(verifyAccount, (state, payload) => {
        return state;
    }),
    on(accountVerified, (state, payload) => {
        sessionStorage.setItem('accountVerification', JSON.stringify(payload))
        return payload;
    }),
    on(rehydrateVerifiedAccount, (state, payload) => {
        console.log('Rehydrate Verified Account', state, payload);
        sessionStorage.setItem('accountVerification', JSON.stringify(payload))
        return payload;
    }),
    on(resetVerifiedAccount, (state, payload) => {
        sessionStorage.removeItem('accountVerification')
        return null;
    }),
    on(invalidAccount, (state, payload) => {
        sessionStorage.setItem('accountVerification', JSON.stringify({status:'invalid'}))
        // sessionStorage.setItem('accountVerification', JSON.stringify(payload))
        return 'invalid';
    })
    ,
    on(verifyAccountOktaFlow, (state, payload) => {
        return state;
    }),
    on(verifyAccountOktaFlowSuccess, (state, payload) => {
        return payload;
    }),
    on(verifyAccountOktaFlowFailed, (state, payload) => {
        // sessionStorage.setItem('accountVerification', JSON.stringify(payload))
        return 'No Okta Account Found';
    })
);

export function verifyAccountReducer(state, action) {
    return _verifyAccountReducer(state, action);
}