import { Component, OnInit, AfterViewInit, OnDestroy, Input } from "@angular/core";
import { Subject, Subscription, Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DataLookupService } from "../../_services/data-lookup.service";

import { Store, select } from "@ngrx/store";
import { Lead } from "../../_models/lead";
import { Vehicle, VerifyCustVehReq, Vin, VinDecodeData } from "../../_models/vehicle";
import { loadVehicle, updateOdometer, updateVehicleByYmmt, updateVehicleByVin, verifyVin, verifyVinReset, verifyVinRates, verifyVinRatesEdit, verifyVehCust } from "../../_actions/vehicle.actions";
import { VehicleService } from "../../_services/vehicle.service";
import { ModelMakeName } from "../../_models/trim";

import { UntypedFormControl, UntypedFormGroup, Validators, Form } from "@angular/forms";
import { Model } from "../../_models/model";
import { RatesRequest } from "../../_models/rates";
import { showInlineLoader } from "../../_actions/inline-loader.actions";
import { guest, account, edit } from "../../_actions/app-flow-state.actions";
import { loadRatesHappy } from "../../_actions/rates.actions";
import { ratesError, clear } from "../../_actions/modal-component.actions";

import { AppConfigService } from "../../appconfig/appconfig.service";
import { LocationService } from "../../_services/location.service";
import { PagesService } from "src/app/_services/pages.service";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ModalVinComponent } from "src/app/modal-vin/modal-vin.component";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { Cart } from "src/app/_models/cart";
import { ProductHomeModalComponent } from "src/app/product-home/product-home-modal/product-home-modal.component";
import { decodeVinGetRates } from "src/app/_actions/vinDecode.actions";
import { VINDecodeRequest } from "src/app/_models/vin-decode";
import { BrandingInfo } from "src/app/_models/brandingInfo";

declare var $: any;
declare var gtag;

@Component({
  selector: "app-verify-vehicle",
  templateUrl: "./verify-vehicle.component.html",
  styleUrls: ["./verify-vehicle.component.scss"],
})
export class VerifyVehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  showVerify = false;
  heading: string;
  content: any;
  vehicle: any;
  leadData: string;
  action: Subject<any> = new Subject();
  mileOptions = [];
  states = [];
  leadVehicleOdometer: number;
  odometerTooltip;
  editVehicleVin: Vin = {
    vin: "",
  };
  leadState: any;
  brandInfo: BrandingInfo;
  private vehicleSubscription: Subscription;
  private leadSubscription: Subscription;
  private vehicleVinSubscription: Subscription;

  private vehicleSub = new Subscription();
  private vinDecodeSub = new Subscription();
  @Input() public verifyVehicleData: any;

  view: any;
  defaultPlan: string;

  buttonText = "Get Your Price";
  titleText = "Verify Your Vehicle";

  private lead$: Observable<Lead>;
  vehicle$: Observable<Vehicle>;
  registeredState: Array<any>;
  modelFormValidation: UntypedFormGroup;
  optionsSelect: Array<any>;
  warrantyEligibility = "Active manufacturer warranty required for eligibility. Your vin will be confirmed at checkout.";

  customerVehicle: Vehicle = {
    isElectric: false,
    make: "",
    makeExternalId: "",
    makeSfId: "",
    mileage: 0,
    model: "",
    registeredState: "",
    modelExternalId: "",
    modelSfId: "",
    modelNumber: "",
    purchaseDate: "",
    inserviceDate: "",
    mfrWarrantyDate: "",
    exteriorColor: "",
    deliveryDate: "",
    referenceNumber1: "",
    referenceNumber2: "",
    referenceNumber3: "",
    referenceNumber4: "",
    trim: "",
    trimExternalId: "",
    trimSfId: "",
    vehicleCondition: "",
    vehicleExternalId: "",
    vehicleSfId: "",
    vehicleType: "",
    vin: "",
    year: "",
  };

  makeModel: ModelMakeName = {
    model: "",
    make: "",
  };

  customerVinDecodedData: VinDecodeData = {
    bodyType: "",
    driveLine: "",
    engineCode: "",
    engineType: "",
    fuelType: "",
    make: "",
    manufacturingCountry: "",
    manufacturingPlant: "",
    model: "",
    series: "",
    trimLevel: "",
    vehicleClass: "",
    vehicleSubtype: "",
    vehicleType: "",
    vehicleYear: "",
    vin: "",
  };

  editVehicleYear: any;
  editVehicleMake: any;
  editVehicleModel: any;
  editVehicleTrim: any;
  editVehicleRegisteredStateVIN: any;
  editVehicleRegisteredStateYMMT: any;
  editVehicleOdometerVIN: any;
  editVehicleOdometerYMMT: any;
  vehicleVin: any;

  modelKeyword = "name";
  modelData = [];
  modelFormat = {
    id: 0,
    name: "",
  };

  trimKeyword = "name";
  trimData = [];
  trimFormat = {
    id: 0,
    name: "",
  };

  stateKeyword = "name";
  stateData = [];
  stateFormat = {
    id: 0,
    name: "",
  };
  trimSubscription: Subscription;
  vinDecodeSubscription: Subscription;
  modelsSubscription: Subscription;
  trimBoolean: boolean;
  modelsBoolean: boolean;
  vinDecodeBoolean: boolean;
  modalComponentState$: Observable<any>;
  appFlowState$: Observable<any>;
  inlineLoader$: Observable<any>;
  editVehicleOdometer: any;
  editVehicleVINForm: UntypedFormGroup;
  editVehicleYMMTForm: UntypedFormGroup;
  editVehicleState$: Observable<any>;
  vehicleBoolean: boolean;
  environment: boolean;
  vehicleRegisteredState: any;
  stateFromCustomize: any;
  verifyVin$: Observable<any>;
  ratesStateSubscription: Subscription;
  branding: any;
  getRatesPage: string;
  modalRef: MDBModalRef;
  modalRefCart: MDBModalRef;
  currentBrand: string;

  constructor(
    private router: Router,
    private modelTrim: DataLookupService,
    private translate: TranslateService,
    private store: Store<{
      lead: Lead;
      vehicle: Vehicle;
      modalComponentState: any;
      appFlowState: any;
      inlineLoader: any;
      editVehicleState: any;
      verifyVin: any;
      vinDecode: any;
      brandInfo: BrandingInfo;
    }>,
    private locationService: LocationService,
    private pageService: PagesService,
    private appConfig: AppConfigService,
    private route: ActivatedRoute,
    private modalService: MDBModalService
  ) {
    console.log("using language 2: ", this.translate.currentLang);
  }

  generateGetRatesPageLink() {
    if (this.verifyVehicleData.getratespage) {
      console.log("verifyVehicleData", this.verifyVehicleData);
      if (this.verifyVehicleData.getratespage) {
        let splitRates = this.verifyVehicleData.getratespage.split("?");
        let baseUri = splitRates[0];

        if (splitRates.length > 1) {
          let queries = splitRates[1].split("&");

          for (const queryParam of queries) {
            if (queryParam.split("=")[0] == "plan") {
              this.defaultPlan = queryParam.split("=")[1];
            }
          }
          console.log("splitRates: ", splitRates, queries, this.defaultPlan);
        }

        this.getRatesPage = baseUri;
      }

      this.pageService.setRatingPage(this.getRatesPage);
    } else {
      this.getRatesPage = this.pageService.getRatingPage();
    }
  }

  setBranding() {
    this.appConfig.getSettings().subscribe((result) => {
      this.odometerTooltip = this.appConfig.getOdometerTooltip();
      this.branding = result;
    });
  }

  detectModalComponentState() {
    // Go to the getRatesPage Page
    this.ratesStateSubscription = this.store
      .select((store) => store.modalComponentState)
      .subscribe((ratesState) => {
        if (ratesState === "success") {
          this.store.dispatch(clear());
          const program = JSON.parse(sessionStorage.getItem("presetProgram"))
          if (program && program.plan) { // Set Plan based on if URL/query string has the plan code
            setTimeout(() => {
              this.redirectTo(this.getRatesPage, { queryParams: { plan: program.plan } });
            })
          } else if (this.defaultPlan) { // If URL doesn't have plan code then check for the plan in Prismic link
            setTimeout(() => {
              this.redirectTo(this.getRatesPage, { queryParams: { plan: this.defaultPlan } });
            })

          } else { // Plan is not set so default to first plan
            this.redirectTo(this.getRatesPage);
          }
        }
      });
  }

  redirectTo(uri: string, queryParams = {}) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([uri], queryParams);
  }

  setDatastoreObservables() {
    this.modalComponentState$ = this.store.select((store) => store.modalComponentState);
    this.appFlowState$ = this.store.select((store) => store.appFlowState);
    this.inlineLoader$ = this.store.select((store) => store.inlineLoader);
    this.editVehicleState$ = this.store.select((store) => store.editVehicleState);
    this.verifyVin$ = this.store.select((store) => store.verifyVin);
    this.lead$ = this.store.select((store) => store.lead);
    this.vehicle$ = this.store.select((store) => store.vehicle);
  }

  maskVinOnVehicleData() {
    this.vehicleSubscription = this.vehicle$.subscribe((vehicle) => {
      if (vehicle) {
        this.maskVin(vehicle.vin.toUpperCase());
      }
    });
  }

  setReactiveFormGroups() {
    this.editVehicleVINForm = new UntypedFormGroup({
      editVehicleVIN: new UntypedFormControl(null, Validators.required),
      editVehicleOdometerVIN: new UntypedFormControl(null, Validators.required),
      editVehicleRegisteredStateVIN: new UntypedFormControl(null, Validators.required),
    });

    this.editVehicleYMMTForm = new UntypedFormGroup({
      editVehicleYear: new UntypedFormControl(null, Validators.required),
      editVehicleModel: new UntypedFormControl(null, Validators.required),
      editVehicleTrim: new UntypedFormControl(null, Validators.required),
      editVehicleOdometerYMMT: new UntypedFormControl(null, Validators.required),
      editVehicleRegisteredStateYMMT: new UntypedFormControl(null, Validators.required),
    });

    this.modelFormValidation = new UntypedFormGroup({
      registeredState: new UntypedFormControl(null, Validators.required),
      odometer: new UntypedFormControl(null, Validators.required),
    });
  }

  setRegisteredStateOnForm() {
    let _lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

    if (_vehicle) {
      if (_vehicle.registeredState !== null && _vehicle.registeredState !== "" && _vehicle.registeredState !== undefined && Number(_vehicle.mileage)) {
        this.modelFormValidation.controls["odometer"].markAsDirty();
        this.modelFormValidation.controls["registeredState"].markAsDirty();
        this.modelFormValidation.controls["odometer"].markAsTouched();
        this.modelFormValidation.controls["registeredState"].markAsTouched();
        this.modelFormValidation.controls["odometer"].setValue(_vehicle.mileage);
        this.modelFormValidation.controls["registeredState"].setValue(_vehicle.registeredState);
      } else if (_lead) {
        this.leadState = _lead.customer.state;
        this.modelFormValidation.controls["registeredState"].markAsDirty();
        this.modelFormValidation.controls["registeredState"].markAsTouched();
        this.modelFormValidation.controls["registeredState"].setValue(this.leadState);
        this.vehicleRegisteredState = this.leadState;
      }
    }
  }

  ngOnInit() {
    // Generate Get Rates Page Link
    this.generateGetRatesPageLink();

    // Set DatastoreObservable
    this.setDatastoreObservables();

    // Set Branding Subscription
    this.setBranding();

    this.maskVinOnVehicleData();

    // Set Modal Component State to NULL. The states are:
    // success, error, invalidState, productExist, and null.

    // Set editVehicleVINForm - Edit Verify Vin, editVehicleYMMTForm - Edit Year Make Modal Trim Form, modelFormValidation
    this.setReactiveFormGroups();

    // Detect if rates changes to success, if so go to getRates page
    this.detectModalComponentState();

    // this.mileOptions = this.getMileOptions();
    this.setRegisteredStateOnForm();

    this.setupButtonId();

    this.store
      .select((store) => store.brandInfo)
      .subscribe((brandInfo) => {
        if (brandInfo) {
          this.setStateData(brandInfo);

          this.brandInfo = brandInfo;
          this.currentBrand = this.brandInfo.brand;
          if (this.brandInfo.brand === "Buick") {
            this.buttonText = "Find My Price";
            this.titleText = "Your Buick, Your Plans";
          } else if (this.brandInfo.brand === "Chevrolet") {
            this.buttonText = "Find My Price";
            this.titleText = "Keep Your Chevrolet Covered";
          } else if (this.brandInfo.brand === "GMC") {
            this.buttonText = "Find My Price";
            this.titleText = "Put the Pro in Protection";
          } else if (this.brandInfo.brand === "Cadillac") {
            this.buttonText = "Find My Price";
            this.titleText = "Boldy Built.<br>Confidently Covered";
          }
        }
      });

    setTimeout(() => {
      this.showVerify = true;
    })

  }

  setupButtonId() {
    setTimeout(() => {
      let mcspts = document.querySelectorAll(".modal-component-see-pricing-btn");
      mcspts.forEach((mcspt, index) => {
        if (!mcspt.hasAttribute("id")) {
          mcspt.setAttribute("id", "modal-component-see-pricing-btn-" + index);
        }
      });
    });
  }

  setStateData(brandInfo) {
    this.stateData = this.locationService.getStateData(brandInfo);
  }

  fixSelectAria(event) {
    setTimeout(() => {
      document.querySelectorAll(".dropdown-content li").forEach((li, index) => {
        var span = li.querySelector("span");
        if (span) {
          span.setAttribute("tabindex", "0");
        }
        if (li.className.indexOf("custom-select-content") > -1) {
          li.setAttribute("aria-hidden", "true"); // fix screen reader count of items
        }
        if (index === 0) {
          li.querySelector("span").focus();
        }
      });
    }, 100);
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    // this.modalRef.hide()
    console.log("ngOnDestroy");
    if (this.trimBoolean) {
      this.trimSubscription.unsubscribe();
    }
    if (this.modelsBoolean) {
      this.modelsSubscription.unsubscribe();
    }
    if (this.vinDecodeBoolean) {
      this.vinDecodeSubscription.unsubscribe();
    }
    if (this.vehicleSubscription) {
      this.vehicleSubscription.unsubscribe();
    }
    if (this.ratesStateSubscription) {
      this.ratesStateSubscription.unsubscribe();
    }
  }

  onYesClick() {
    this.action.next("yes");
  }

  vehicleNotEdited() {
    // stop here if form is invalid
    if (this.modelFormValidation.invalid) {
      console.log("modelFormValidation is invalid");
      this.modelFormValidation.controls.odometer.markAsTouched();
      this.modelFormValidation.controls.registeredState.markAsTouched();
      return;
    }

    this.store.dispatch(clear());
    this.store.dispatch(showInlineLoader());
    this.loadNotEditedVehicle();
  }

  vehicleEditedVIN() {
    // stop here if form is invalid
    if (this.editVehicleVINForm.invalid) {
      console.log("editVehicleVINForm is invalid");
      this.editVehicleVINForm.controls.editVehicleOdometerVIN.markAsTouched();
      this.editVehicleVINForm.controls.editVehicleRegisteredStateVIN.markAsTouched();
      this.editVehicleVINForm.controls.editVehicleVIN.markAsTouched();
      return;
    }

    this.customerVehicle.mileage = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value;
    this.customerVehicle.registeredState = this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value;
    this.customerVehicle.vin = this.editVehicleVINForm.controls["editVehicleVIN"].value;

    console.log("vehicleEditedVIN", this.store);
    this.saveRates();
  }

  vehicleEditedYMMT() {
    this.customerVehicle.make = this.editVehicleMake;
    this.customerVehicle.model = this.editVehicleYMMTForm.controls["editVehicleModel"].value;
    this.customerVehicle.trim = this.editVehicleYMMTForm.controls["editVehicleTrim"].value;
    this.customerVehicle.mileage = this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value;
    this.customerVehicle.registeredState = this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value;
    this.customerVehicle.year = this.editVehicleYMMTForm.controls["editVehicleYear"].value;

    this.store.dispatch(updateVehicleByYmmt(this.customerVehicle));

    console.log("vehicleEditedYMMT", this.store);
  }

  removeCommas() {
    if (this.modelFormValidation.controls["odometer"].value && this.modelFormValidation.controls["odometer"].value.includes(",")) {
      this.modelFormValidation.controls["odometer"].setValue(this.modelFormValidation.controls["odometer"].value.replace(",", ""));
    }
  }

  addCommas() {
    if (this.modelFormValidation.controls["odometer"].value) {
      if (this.modelFormValidation.controls["odometer"].value.length === 4) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 1);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(1, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length === 5) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 2);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(2, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length === 6) {
        let beforeComma = this.modelFormValidation.controls["odometer"].value.substr(0, 3);
        let afterComma = this.modelFormValidation.controls["odometer"].value.substr(3, this.modelFormValidation.controls["odometer"].value.length - 1);
        this.modelFormValidation.controls["odometer"].setValue(beforeComma + "," + afterComma);
      } else if (this.modelFormValidation.controls["odometer"].value.length > 6) {
        this.modelFormValidation.controls["odometer"].setValue("999,999");
      }
    }
    let odometer = this.modelFormValidation.controls["odometer"].value;
    if (odometer) {
      odometer = odometer.replace(",,", ",");
    }
    this.modelFormValidation.controls["odometer"].setValue(odometer);
  }

  removeCommasVIN() {
    if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.replace(",", ""));
    }
  }

  addCommasVIN() {
    if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 4) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 1);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(1, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 5) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 2);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(2, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length === 6) {
        let beforeComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(0, 3);
        let afterComma = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.substr(3, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length - 1);
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(beforeComma + "," + afterComma);
      } else if (this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value.length > 6) {
        this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue("999,999");
      }
    }
    let odometer = this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value;
    if (odometer) {
      odometer = odometer.replace(",,", ",");
    }
    this.editVehicleVINForm.controls["editVehicleOdometerVIN"].setValue(odometer);
  }

  convertToCompatibleDate() {
    let d = new Date();
    let year = d.getFullYear().toString();
    let day = d.getDate().toString();
    let month = d.getMonth().toString();

    if (month === "0") {
      month = "01";
    } else if (month === "1") {
      month = "02";
    } else if (month === "2") {
      month = "03";
    } else if (month === "3") {
      month = "04";
    } else if (month === "4") {
      month = "05";
    } else if (month === "5") {
      month = "06";
    } else if (month === "6") {
      month = "07";
    } else if (month === "7") {
      month = "08";
    } else if (month === "8") {
      month = "09";
    } else if (month === "9") {
      month = "10";
    } else if (month === "10") {
      month = "11";
    } else if (month === "11") {
      month = "12";
    }

    if (day === "1") {
      day = "01";
    } else if (day === "2") {
      day = "02";
    } else if (day === "3") {
      day = "03";
    } else if (day === "4") {
      day = "04";
    } else if (day === "5") {
      day = "05";
    } else if (day === "6") {
      day = "06";
    } else if (day === "7") {
      day = "07";
    } else if (day === "8") {
      day = "08";
    } else if (day === "9") {
      day = "09";
    }

    return year + "-" + month + "-" + day;
  }

  checkIfCriteriaChange() {
    let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let currentCompatibleDate = this.convertToCompatibleDate();

    this.customerVehicle.mileage = this.modelFormValidation.controls["odometer"].value;
    this.customerVehicle.registeredState = this.modelFormValidation.controls["registeredState"].value;

    let product = "";
    let page = JSON.parse(sessionStorage.getItem("pagesFlow"));
    if (page.landingPage === "added-security-plan" || page.landingPage === "vehicle-service-protection") {
      if (_vehicle.isElectric) {
        product = "EVSC";
      } else {
        product = "VSC";
      }
    } else if (page.landingPage === "prepaid-maintenance") {
      product = "PPM";
    }

    let request: RatesRequest = {
      brandName: this.brandInfo.brand,
      vin: _accountContact.vehicle.vin,
      saleDate: currentCompatibleDate,
      odometer: Number(this.customerVehicle.mileage).toString(),
      inServiceDate: currentCompatibleDate,
      financeType: "finance",
      isAfterSale: "false",
      vehiclePurchaseDate: currentCompatibleDate,
      vehicleCondition: _accountContact.vehicle.vehicleCondition,
      customerState: this.modelFormValidation.controls["registeredState"].value,
      financeAmount: "",
      vehicleCost: "",
      vehicleMSRP: "",
      lienholderName: "",
      make: _accountContact.vehicle.make,
      model: _accountContact.vehicle.model,
      year: _accountContact.vehicle.year,
      vehicleSfId: _accountContact.vehicle.vehicleSfId,
      getRatesPage: this.getRatesPage,
      product: product,
    };

    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      scroll: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered",
      containerClass: "",
      animated: true,
      data: request,
    };

    if (_cart) {
      if (_cart.plans.length > 0) {
        if (Number(_vehicle.mileage) !== Number(this.modelFormValidation.controls["odometer"].value) || _vehicle.registeredState !== this.modelFormValidation.controls["registeredState"].value) {
          this.modalRefCart = this.modalService.show(ProductHomeModalComponent, modalOptions);
        } else {
          this.vehicleNotEdited();
        }
      } else {
        this.vehicleNotEdited();
      }
    } else {
      this.vehicleNotEdited();
    }
  }

  checkIfCriteriaChangeEditedVIN() {
    let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let currentCompatibleDate = this.convertToCompatibleDate();

    this.customerVehicle.mileage = this.modelFormValidation.controls["odometer"].value;
    this.customerVehicle.registeredState = this.modelFormValidation.controls["registeredState"].value;

    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      scroll: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered",
      containerClass: "",
      animated: true,
    };

    if (_cart) {
      if (_cart.plans.length > 0) {
        if (Number(_vehicle.mileage) !== Number(this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) || _vehicle.registeredState !== this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value || _vehicle.vin !== this.editVehicleVINForm.controls["editVehicleVIN"].value) {
          this.modalRefCart = this.modalService.show(ProductHomeModalComponent, modalOptions);
        } else {
          this.vehicleEditedVIN();
        }
      } else {
        this.vehicleEditedVIN();
      }
    } else {
      this.vehicleEditedVIN();
    }
  }

  loadNotEditedVehicle() {
    let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let currentCompatibleDate = this.convertToCompatibleDate();

    this.customerVehicle.mileage = this.modelFormValidation.controls["odometer"].value;
    this.customerVehicle.registeredState = this.modelFormValidation.controls["registeredState"].value;

    console.log(typeof this.modelFormValidation.controls["odometer"].value);
    console.log("loadNotEditedVehicle", this.store);
    let d = _accountContact.vehicle.inserviceDate;
    console.log("Ray find", d.split("-"));

    let year = d.split("-")[2];
    let day = d.split("-")[1];
    let month = d.split("-")[0];

    let product = "";

    let page = JSON.parse(sessionStorage.getItem("pagesFlow"));
    if (page.landingPage === "added-security-plan" || page.landingPage === "vehicle-service-protection") {
      if (_vehicle.isElectric) {
        product = "EVSC";
      } else {
        product = "VSC";
      }
    } else if (page.landingPage === "prepaid-maintenance") {
      product = "PPM";
    }

    let request: RatesRequest = {
      brandName: this.brandInfo.brand,
      vin: _accountContact.vehicle.vin,
      saleDate: currentCompatibleDate,
      odometer: this.modelFormValidation.controls["odometer"].value,
      inServiceDate: year + "-" + month + "-" + day,
      financeType: "finance",
      isAfterSale: "false",
      vehiclePurchaseDate: currentCompatibleDate,
      vehicleCondition: _accountContact.vehicle.vehicleCondition,
      customerState: this.modelFormValidation.controls["registeredState"].value,
      financeAmount: "",
      vehicleCost: "",
      vehicleMSRP: "",
      lienholderName: "",
      make: _accountContact.vehicle.make,
      model: _accountContact.vehicle.model,
      year: _accountContact.vehicle.year,
      vehicleSfId: _accountContact.vehicle.vehicleSfId,
      getRatesPage: this.getRatesPage,
      product: product,
    };

    console.log("loadNotEditedVehicle Rates:", request);

    this.store.dispatch(verifyVinRates(request));
  }

  checkStringLength(event) {
    if (this.editVehicleVINForm.controls["editVehicleVIN"].value) {
      let vin: string = this.editVehicleVINForm.controls["editVehicleVIN"].value.toUpperCase();
      this.editVehicleVINForm.controls["editVehicleVIN"].setValue(vin);
      let length: number = vin.length;
      let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
      console.log(length);
      if (length === 17 && _accountContact) {
        let request: VerifyCustVehReq = {
          vin: vin,
          email: _accountContact.customer.emailId,
        };
        this.store.dispatch(verifyVehCust(request));
      }
    }
  }

  clearYMMT() {
    this.editVehicleYMMTForm.reset();
  }

  clearVIN() {
    this.editVehicleVINForm.reset();
  }

  runRatesForYMMT() {
    if (
      this.editVehicleYMMTForm.controls["editVehicleModel"].value &&
      this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value &&
      this.editVehicleYMMTForm.controls["editVehicleYear"].value &&
      this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value &&
      this.editVehicleYMMTForm.controls["editVehicleTrim"].value
    ) {
      this.store.dispatch(showInlineLoader());

      let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
      let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
      let product = "";

      console.log(_accountContact);

      let page = JSON.parse(sessionStorage.getItem("pagesFlow"));
      if (page.landingPage === "added-security-plan" || page.landingPage === "vehicle-service-protection") {
        if (_vehicle.isElectric) {
          product = "EVSC";
        } else {
          product = "VSC";
        }
      } else if (page.landingPage === "prepaid-maintenance") {
        product = "PPM";
      }

      let request: RatesRequest = {
        brandName: this.brandInfo.brand,
        vin: "5NPE34AB2JH691640",
        saleDate: "2019-10-11",
        odometer: this.editVehicleYMMTForm.controls["editVehicleOdometerYMMT"].value,
        inServiceDate: "2019-05-13",
        financeType: "finance",
        isAfterSale: "false",
        vehiclePurchaseDate: "2019-10-11",
        vehicleCondition: "NEW",
        customerState: this.editVehicleYMMTForm.controls["editVehicleRegisteredStateYMMT"].value,
        financeAmount: "0.0",
        vehicleCost: "0.0",
        vehicleMSRP: "0.0",
        lienholderName: "",
        make: this.editVehicleMake,
        model: this.editVehicleYMMTForm.controls["editVehicleModel"].value,
        year: this.editVehicleYMMTForm.controls["editVehicleYear"].value,
        vehicleSfId: "",
        getRatesPage: this.getRatesPage,
        product: product,
      };
      this.store.dispatch({ type: "[Product Plans Component] Load Rates", payload: request });
      this.clearVIN();
    }
  }

  getYMMT() {
    this.vinDecodeSubscription = this.store
      .select((store) => store.vinDecode)
      .subscribe((vinDecodeData) => {
        this.vinDecodeBoolean = true;
        this.customerVinDecodedData = vinDecodeData;
        this.editVehicleMake = this.customerVinDecodedData.make;
        this.editVehicleModel = this.customerVinDecodedData.model;
        this.editVehicleTrim = this.customerVinDecodedData.trimLevel;
        this.editVehicleYear = this.customerVinDecodedData.vehicleYear;
      });
  }

  async _loadTrims(trims) {
    for (let i = 0; i < trims.trims.length; i++) {
      this.trimFormat.id = i + 1;
      this.trimFormat.name = trims.trims[i].name;
      if (this.trimData.length < trims.trims.length) {
        this.trimData.push({ id: this.trimFormat.id, name: trims.trims[i].name });
      }
    }
    console.log(this.trimData);
  }

  odometerSelectedVIN(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometerVIN = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometerVIN = y[0] + y[1];

      console.log(this.editVehicleVINForm.controls["editVehicleVIN"].value, this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value, this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value);

      // if (this.editVehicleVINForm.controls['editVehicleVIN'].value && this.editVehicleVINForm.controls['editVehicleRegisteredStateVIN'].value && this.editVehicleVINForm.controls['editVehicleOdometerVIN'].value) {
      //   this.clearYMMT()
      //   this.saveRates();
      // }
    }
  }

  odometerSelected(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometer = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometer = y[0] + y[1];
    }

    if (this.modelFormValidation.controls["registeredState"].value && this.modelFormValidation.controls["odometer"].value) {
      this.vehicleNotEdited();
    }
  }

  odometerSelectedYMMT(event) {
    console.log(event);
    //console.log(event.label.split('- ')[1].trim());
    if (event.label.split("- ")[1] === undefined) {
      this.editVehicleOdometerYMMT = "100000";
    } else {
      let x = event.label.split("- ")[1].trim();
      console.log(x);
      let y = x.split(",");
      console.log(y);
      console.log(y[0] + y[1]);
      this.editVehicleOdometerYMMT = y[0] + y[1];

      this.runRatesForYMMT();
    }
  }

  stateSelected() {
    this.vehicleRegisteredState = this.modelFormValidation.controls["registeredState"].value;
  }

  stateSelectedVIN(event) {
    console.log(event);
    this.editVehicleRegisteredStateVIN = event.value;

    console.log(this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value);

    if (this.editVehicleVINForm.controls["editVehicleVIN"].value && this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value && this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value) {
      this.clearYMMT();
    }
  }

  stateSelectedYMMT(event) {
    console.log(event);
    this.editVehicleRegisteredStateYMMT = event.value;
  }

  async loadModelsFromMakes(models: Model[]) {
    for (let i = 0; i < models.length; i++) {
      this.modelFormat.id = i + 1;
      this.modelFormat.name = models[i].name;
      if (this.modelData.length < models.length) {
        this.modelData.push({ id: this.modelFormat.id, name: models[i].name });
      }
    }
    console.log(this.modelData);
  }

  onNoClick() {
    this.action.next("No");
  }

  refreshState() {
    // this.modalRef.hide();
    let state = sessionStorage.getItem("appFlowState");
    if (state === "account" || state === "edit") {
      this.store.dispatch(account());
    } else {
      this.store.dispatch(guest());
    }
    this.store.dispatch(clear());
    this.store.dispatch(verifyVinReset());
  }

  editVehicle() {
    this.store.dispatch(clear());
    this.store.dispatch(edit());
    this.setupButtonId();
  }

  saveRates() {
    this.store.dispatch(showInlineLoader());

    // let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    // let _accountContact: Lead = JSON.parse(sessionStorage.getItem("lead"));
    // let currentCompatibleDate = this.convertToCompatibleDate();

    // let d = _accountContact?.vehicle.inserviceDate || currentCompatibleDate;
    // console.log('Ray find', d.split('-'))

    // let year = d.split('-')[2]
    // let day = d.split('-')[1]
    // let month = d.split('-')[0]

    let request: VINDecodeRequest = {
      brandName: this.brandInfo.brand,
      vin: this.editVehicleVINForm.controls["editVehicleVIN"].value,
      saleDate: "",
      odometer: this.editVehicleVINForm.controls["editVehicleOdometerVIN"].value,
      inServiceDate: "",
      customerState: this.editVehicleVINForm.controls["editVehicleRegisteredStateVIN"].value,
      getRatesPage: this.getRatesPage,
    };

    this.store.dispatch(decodeVinGetRates(request));
  }

  maskVin(vin) {
    console.log(vin);
    let endVin = vin.substring(11, 17);
    console.log(endVin);
    this.vehicleVin = "***********" + endVin;
  }

  moveToRates() {
    this.router.navigate([this.getRatesPage]);
  }

  // getMileOptions() {
  //   return [
  //     { value: "30000", label: "0 - 30,000" },
  //     { value: "60000", label: "30,001 - 60,000" },
  //     { value: "90000", label: "60,001 - 90,000" },
  //   ];
  // }

  onlyNumberKey(event) {
    return event.charCode == 8 || event.charCode == 0 ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  launchVin() {
    console.log("where's my VIN");
    this.modalRef = this.modalService.show(ModalVinComponent, {});
  }
}
